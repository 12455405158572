import React, { useEffect, useState } from "react";
import "./css/login.css";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../../Action/login";
import { useNavigate } from "react-router-dom";
import { RiAccountPinCircleFill, RiLockPasswordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { reciveMessageAction } from "../../Action/SendToken/SendTokenAction";
// import Swal from "sweetalert2";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { loggedIn, loading } = userLogin;
  useEffect(() => {
    if (loggedIn == true) {
      navigate("/");
    }
  }, [loading]);

 
  const handelSubmit = (event) => {
    event.preventDefault();

    dispatch(login(email, password));

    navigate("/");

  };
  return (
    <div>
      <form action="" className="formLogin relative">
        <div className="m-auto text-center flex items-center absolute -top-14 left-[50%] -translate-x-[50%]">
          <p className="text-center text-8xl m-auto text-teal-700">
            <RiAccountPinCircleFill />
          </p>
        </div>
        <h1 className="text-2xl font-bold mt-10  text-center">Sign In</h1>
        <div className="childLogin w-11/12 m-auto ">
          <label htmlFor="pass" className="ml-5 mt-3">
            Email *{" "}
          </label>

          <div className="parentInputEditUser m-auto w-11/12">
            <div className="flex ">
              <span className="iconInput">
                <MdEmail />
              </span>
            </div>
            <input
              type="text"
              className="inputGroup "
              id="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="childLogin w-11/12 m-auto ">
          <label htmlFor="pass" className="ml-5 mt-3">
            Password *{" "}
          </label>

          <div className="parentInputEditUser m-auto w-11/12">
            <div className="flex -mr-px">
              <span className="iconInput">
                <RiLockPasswordFill />
              </span>
            </div>
            <input
              type="password"
              id="pass"
              className="inputGroup "
              placeholder="Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="parentBtn">
          <input
            type="submit"
            onClick={handelSubmit}
            className="btnLogin bg-teal-600 py-2 w-[89%] m-auto rounded-md shadow-lg"
            value={"Sign In"}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
