import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showReportMonthlyAction } from "../../Action/patient";
import ReactPaginate from "react-paginate";

import { getDoctorAction } from "../../Action/Doctor/doctorAction";
import {
  getAllTypeSurgeryAction,
  getOrganLevelAction,
} from "../../Action/SurgeryTypeAction/surgery";
import { CiFilter } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Loading from "../Loading/Loading";
const ReportMonthly = () => {
  const [isOpenFilter, setIsOpentFilter] = useState(false);
  const dispatch = useDispatch();
  const [select, setSelect] = useState(0);
  const showReportMonthly = useSelector((state) => state.showReportMonthly);
  const { report, loading, total } = showReportMonthly;
  const location_id = localStorage.getItem("locationId");
  const [currentPage, setCurrentPage] = useState(0);
  const getAllTypeSurgery = useSelector((state) => state.getAllTypeSurgery);
  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;
  const { typeSurgery } = getAllTypeSurgery;
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    dispatch(getOrganLevelAction(0));
    dispatch(getAllTypeSurgeryAction());
  }, []);

  const [inputValue, setInputValue] = useState({
    from: "",
    to: "",
    code: "",
    fullname: "",
    phone: "",
    gender: "",
    age: "",
    doctor: "",
    organ: "",
    organLevel: "",
    type_of_help: "",
    type: "",
  });
  const [perPage, setPerPage] = useState(20);

  const [searchParams, setSearchParams] = useState({
    fullname: "",
    phone: "",
    code: "",
    age: "",
    gender: "",
    doctor: "",
    organ: "",
    organLevel: "",
    type_of_help: "",
    type: "",
  });
  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  useEffect(() => {
    dispatch(
      showReportMonthlyAction(
        location_id,
        currentPage,
        perPage,
        inputValue.from,
        inputValue.to,
        select,
        searchParams.code,
        searchParams.fullname,
        searchParams.phone,
        searchParams.gender,
        searchParams.age,
        searchParams.doctor,
        searchParams.organ,
        searchParams.organLevel,
        searchParams.type_of_help,
        searchParams.type
      )
    );
  }, [perPage,  inputValue.to, select]);
  const [indexx, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const handleClearSearch = (name) => {
    const updateSearchParams = { ...searchParams };
    updateSearchParams[name] = "";
    setIsOpentFilter(false);
    dispatch(
      showReportMonthlyAction(
        location_id,
        currentPage,
        perPage,
        inputValue.from,
        inputValue.to,
        select,
        updateSearchParams.code,
        updateSearchParams.fullname,
        updateSearchParams.phone,
        updateSearchParams.gender,
        updateSearchParams.age,
        updateSearchParams.doctor,
        updateSearchParams.organ,
        updateSearchParams.organLevel,
        updateSearchParams.type_of_help,
        updateSearchParams.type
      )
    );
    setSearchParams(updateSearchParams);
  };
  const handleSearch = () => {
    setIsOpentFilter(false);
    dispatch(
      showReportMonthlyAction(
        location_id,
        currentPage,
        perPage,
        inputValue.from,
        inputValue.to,
        select,
        searchParams.code,
        searchParams.fullname,
        searchParams.phone,
        searchParams.gender,
        searchParams.age,
        searchParams.doctor,
        searchParams.organ,
        searchParams.organLevel,
        searchParams.type_of_help,
        searchParams.type
      )
    );
  };
  return (
    <>
      {loading || report == undefined ? (
        <Loading />
      ) : (
        <div className=" w-[96%] m-auto   mt-4">
          <div className="bg-gray-100  flex items-center px-4 py-2">
            <div className="">
              <div className="flex gap-2 items-center">
                <div className="flex flex-col">
                  <input
                    type="date"
                    onChange={(e) =>
                      setInputValue({
                        ...inputValue,
                        from: e.target.value,
                      })
                    }
                    value={inputValue.from}
                    id="from"
                    className="inputUser p-1.5"
                  />
                </div>
                <div>To</div>
                <div className="flex flex-col">
                  <input
                    type="date"
                    value={inputValue.to}
                    onChange={(e) =>
                      setInputValue({
                        ...inputValue,
                        to: e.target.value,
                      })
                    }
                    id="to"
                    className="inputUser p-1.5"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2   w-11/12 justify-end ">
              <div className="xl:w-[15%] ">
                <select
                  name=""
                  onChange={(e) => setSelect(e.target.value)}
                  value={select}
                  className="select w-full px-1 bg-white"
                  id=""
                >
                  <option value="">All</option>
                  <option value="2">Approve</option>
                  <option value="1">Reject</option>
                </select>
              </div>
            </div>
          </div>

          <div className="bg-white h-[75vh]">
            <div className="overflow-y-auto  h-[90%] rounded-md shadow-md bg-white">
              <table className="table w-[150%] overflow-x-auto">
                <thead className="sticky top-0">
                  <tr>
                    {[
                      {
                        label: "Patient ID",
                        value: "code",
                      },
                      {
                        label: "Full Name",
                        value: "fullname",
                      },
                      {
                        label: "Phone",
                        value: "phone",
                      },
                      {
                        label: "Gender",
                        value: "gender",
                      },
                      {
                        label: "Birth Date",
                        value: "age",
                      },
                      {
                        label: "Doctor",
                        value: "doctor",
                      },
                      {
                        label: "Organ",
                        value: "organ",
                      },
                      {
                        label: "Organ Level",
                        value: "organLevel",
                      },
                      {
                        label: "Type Of Help",
                        value: "type_of_help",
                      },
                      {
                        label: "Type",
                        value: "type",
                      },
                    ].map((th, index) => {
                      return (
                        <th className="relative " key={index}>
                          <div className="flex justify-between items-center">
                            <p>{th.label}</p>
                            <div
                              className="filterIcon"
                              onClick={() => handleOpenFilter(index)}
                            >
                              <CiFilter />
                            </div>
                          </div>
                          <div
                            className={
                              isOpenFilter && indexx == index
                                ? "parentInputSearch text-gray-800"
                                : "hidden"
                            }
                          >
                            {index == 5 ? (
                              <select
                                name={th.value}
                                onChange={handleInputChange}
                                id=""
                              >
                                <option value="">
                                  تکایە زانیاریەکە هەڵبژێرە
                                </option>
                                {doctors?.map((doctor, index) => {
                                  return (
                                    <option value={doctor?.dr_name} key={index}>
                                      {doctor?.dr_name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : index == 6 ? (
                              <select
                                name={th.value}
                                onChange={handleInputChange}
                                id=""
                              >
                                <option value="">
                                  تکایە زانیاریەکە هەڵبژێرە
                                </option>
                                {typeSurgery?.map((type, index) => {
                                  return (
                                    <option
                                      value={type?.type_name_en}
                                      key={index}
                                    >
                                      {type?.type_name_en}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : index == 7 ? (
                              <select
                                name={th.value}
                                onChange={handleInputChange}
                                id=""
                              >
                                <option value="">
                                  تکایە زانیاریەکە هەڵبژێرە
                                </option>
                                {organLevel?.map((organ, index) => {
                                  return (
                                    <option
                                      value={organ?.organ_level_name}
                                      key={index}
                                    >
                                      {organ?.organ_level_name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <input
                                type="text"
                                className="inputSearch"
                                placeholder={th.label}
                                value={searchParams[th.value] || ""}
                                name={th.value}
                                onChange={handleInputChange}
                              />
                            )}

                            <div className="flex  gap-2 mt-2">
                              <button
                                onClick={handleSearch}
                                className="btn-plus"
                              >
                                <FaCheck />
                              </button>
                              <button
                                onClick={() => handleClearSearch(th.value)}
                                className="btn-danger"
                              >
                                <RxCross2 />
                              </button>
                            </div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {report?.map((data, index) => {
                    return data?.commites.map((commite, indexCommite) => {
                      return (
                        <tr className="tableRow" key={indexCommite}>
                          <td className="py-2">{data.code}</td>
                          <td>{data.Pname}</td>
                          <td>{data.phone}</td>
                          <td>{data.gender}</td>
                          <td>{data.birthDate}</td>
                          <td>{commite.Doctor}</td>
                          <td>{commite.member}</td>

                          <td>{commite.memberLevel}</td>

                          <td>
                            {commite.type_of_help.map((type) => {
                              return <p>{type}</p>;
                            })}
                          </td>

                          <td>{commite.Typemedicalsupplies}</td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>

            <div className="flex justify-between mt-2 px-4 items-center gap-4">
              <div className="flex  items-center gap-3  text-xl">
                <p className=" ">Total:</p>
                <p className=" bg-teal-500 text-gray-100 font-semibold shadow-md text-sm  rounded-md p-1 px-3">
                  {total}
                </p>
              </div>

              <div className="flex gap-2 items-center">
                <div>
                  <select
                    name=""
                    className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                    onChange={(e) => setPerPage(e.target.value)}
                    value={perPage}
                    id=""
                  >
                    <option value="10" selected>
                      10
                    </option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="flex justify-center   mt-1">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel={">"}
                    // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                    breakLabel="..."
                    className="flex  items-center   -space-x-px h-8 text-sm"
                    previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                    nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                    pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                    breakClassName="w-8 py-2 text-center"
                    pageCount={Math.ceil(total / perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={(event) => {
                      let currentPage = event.selected + 1;
                      dispatch(
                        showReportMonthlyAction(
                          location_id,
                          currentPage,
                          perPage,
                          inputValue.from,
                          inputValue.to,
                          select,
                          searchParams.code,
                          searchParams.fullname,
                          searchParams.phone,
                          searchParams.gender,
                          searchParams.age,
                          searchParams.doctor,
                          searchParams.organ,
                          searchParams.organLevel,
                          searchParams.type_of_help,
                          searchParams.type
                        )
                      );

                      setCurrentPage(event.selected);
                    }}
                    activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pb-28"></div>
    </>
  );
};

export default ReportMonthly;
