import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { addfeedback, saveFeebackAction } from "../../../Action/lezhnaAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCommiteWithVisitAction } from "../../../Action/commiteAction";
import Swal from "sweetalert2";
import { FaThList } from "react-icons/fa";
import { RiScreenshot2Fill } from "react-icons/ri";
import { MdOutlineAssignmentTurnedIn, MdOutlineSaveAlt } from "react-icons/md";

import html2canvas from "html2canvas";

const useScreenshot = () => {
  const ref = useRef(null);

  const takeScreenshot = () => {
    Swal.showLoading();
    if (ref.current) {
      html2canvas(ref.current)
        .then((canvas) => {
          const link = document.createElement("a");
          link.download = "screenshot.png";
          link.href = canvas.toDataURL();
          link.click();
          Swal.hideLoading();
        })
        .catch((error) => {
          console.error("Error taking screenshot:", error);
        });
    }
  };

  return { ref, takeScreenshot };
};

const Visit = ({ patient, visit_id, activeTab }) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const { id } = useParams();
  const [commiteId, setCommiteId] = useState(null);
  const [color, setColor] = useState(null);
  const [isOpenList, setIsOpenList] = useState(false);
  const [inputValueText, setInputValueText] = useState({
    commite_id: null,
    lezhna_id: null,
    amountOfRent: null,
    GovOrPrivateEmp: null,
    metters: null,
    NeighborhoodType: null,
    economicStatus: null,
    typeVihicle: null,
    numberOfHouse: null,
    typeOfEstiPrice: null,
    typeOfHouse: "",
    amountOfSalary: null,
    amountOfLoan: null,
    resonOfDebt: null,
    haveOtherDeasise: null,
    typeOfDeasise: null,
    numOfchildren: null,
    numberOfStudent: null,
    monthlyIncomeFamily: null,
    povertyRates: null,
    HMCabilitySurgery: null,
    patientDeserveMediaWork: null,
    relationship: null,
    name: null,
    phone: null,
    note: null,
    numberOfland: null,
    nowDate: currentDate,
    mercy: null,
    Typeofdisease: null,

    // new
    typeOfOccupation: null,
    placeOfOccupation: null,
    totalSalary: null,
    WHBATFUP: null,
    phoneMemeber: null,
    nameMember: null,
    smoker: null,
    workOfSons: null,
    parentOrBortherJob: null,
    supervisor: null,
    typeOfBussnis: null,
    incomeOfBussnisse: null,
    compareHouseHoldExpenses: null,
    secoundWork: null,
  });
  const handelInputTextChange = (e) => {
    const { name, value } = e.target;
    setInputValueText({ ...inputValueText, [name]: value });
  };
  const [inputValue, setInputValue] = useState({
    alive: "no",
    salaryEmploey: "no",
    house: "no",
    tenant: "no",
    car: "no",
    land: "no",
    debt: "no",
    monthlyIncome: "no",
    poverty: "no",
    relativesHelp: "no",
    // new
    child: "no",
    moreThan40: "no",
    smoke: "no",
    masqoud: "no",
    helpingNear: "no",
    businessman: "no",
  });

  const handelInput = (e) => {
    if (e.target.checked) {
      setInputValue({
        ...inputValue,
        [e.target.name]: "yes",
      });
    } else if (!e.target.checked || inputValue.alive == null) {
      setInputValue({
        ...inputValue,
        [e.target.name]: "no",
      });
    }
  };

  const dispatch = useDispatch();
  const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const handleCost = [
    10, 20, 30, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
  ];
  const {
    alive,
    salaryEmploey,
    house,
    tenant,
    car,
    land,
    debt,
    monthlyIncome,
    poverty,
    relativesHelp,
    child,
    moreThan40,
    smoke,
    masqoud,
    helpingNear,
    businessman,
  } = inputValue;
  const {
    lezhna_id,
    commite_id,
    amountOfLoan,
    amountOfRent,
    GovOrPrivateEmp,
    metters,
    NeighborhoodType,
    economicStatus,
    typeVihicle,
    typeOfEstiPrice,
    amountOfSalary,
    resonOfDebt,
    haveOtherDeasise,
    typeOfDeasise,
    numOfchildren,
    numberOfStudent,
    monthlyIncomeFamily,
    povertyRates,
    HMCabilitySurgery,
    patientDeserveMediaWork,
    relationship,
    name,
    phone,
    note,
    numberOfland,
    nowDate,
    numberOfHouse,
    Typeofdisease,

    typeOfOccupation,
    placeOfOccupation,
    totalSalary,
    WHBATFUP,
    phoneMemeber,
    nameMember,
    smoker,
    workOfSons,
    parentOrBortherJob,
    supervisor,
    typeOfBussnis,
    incomeOfBussnisse,
    compareHouseHoldExpenses,
    secoundWork,
    typeOfHouse,
  } = inputValueText;
  const handelSave = (event) => {
    event.preventDefault();
    if (commiteId == null) {
      Swal.fire({
        icon: "warning",
        timer: 2000,
        title: "Not Completed",
        text: "You must first fill out a medical form",
      });
      return;
    }
    
    dispatch(
      saveFeebackAction(
        1,
        visit_id,
        lezhna_id,
        commiteId,
        amountOfLoan,
        amountOfRent,
        GovOrPrivateEmp,
        metters,
        NeighborhoodType,
        economicStatus,
        typeVihicle,
        typeOfEstiPrice,
        amountOfSalary,
        resonOfDebt,
        haveOtherDeasise,
        typeOfDeasise,
        numOfchildren,
        numberOfStudent,
        monthlyIncomeFamily,
        povertyRates,
        HMCabilitySurgery,
        patientDeserveMediaWork,
        relationship,
        name,
        phone,
        note,
        numberOfland,
        nowDate,
        numberOfHouse,
        alive,
        salaryEmploey,
        poverty,
        house,
        tenant,
        car,
        land,
        debt,
        monthlyIncome,
        inputValueText.mercy,
        Typeofdisease,

        // relativesHelp,
        child,
        moreThan40,
        smoke,
        masqoud,
        helpingNear,
        businessman,

        typeOfOccupation,
        placeOfOccupation,
        totalSalary,
        WHBATFUP,
        phoneMemeber,
        nameMember,
        smoker,
        workOfSons,
        parentOrBortherJob,
        supervisor,
        typeOfBussnis,
        incomeOfBussnisse,
        compareHouseHoldExpenses,
        secoundWork,
        typeOfHouse
      )
    );
    dispatch(getCommiteWithVisitAction(id));
    dispatch(getCommiteWithVisitAction(id));

    activeTab(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (commiteId == null) {
      Swal.fire({
        icon: "warning",
        timer: 2000,
        title: "Not Completed",
        text: "You must first fill out a medical form",
      });
      return;
    }

    dispatch(
      addfeedback(
        1,
        visit_id,
        lezhna_id,
        commiteId,
        amountOfLoan,
        amountOfRent,
        GovOrPrivateEmp,
        metters,
        NeighborhoodType,
        economicStatus,
        typeVihicle,
        typeOfEstiPrice,
        amountOfSalary,
        resonOfDebt,
        haveOtherDeasise,
        typeOfDeasise,
        numOfchildren,
        numberOfStudent,
        monthlyIncomeFamily,
        povertyRates,
        HMCabilitySurgery,
        patientDeserveMediaWork,
        relationship,
        name,
        phone,
        note,
        numberOfland,
        nowDate,
        numberOfHouse,
        alive,
        salaryEmploey,
        poverty,
        house,
        tenant,
        car,
        land,
        debt,
        monthlyIncome,
        inputValueText.mercy,
        Typeofdisease,

        // relativesHelp,
        child,
        moreThan40,
        smoke,
        masqoud,
        helpingNear,
        businessman,

        typeOfOccupation,
        placeOfOccupation,
        totalSalary,
        WHBATFUP,
        phoneMemeber,
        nameMember,
        smoker,
        workOfSons,
        parentOrBortherJob,
        supervisor,
        typeOfBussnis,
        incomeOfBussnisse,
        compareHouseHoldExpenses,
        secoundWork,
        typeOfHouse
      )
    );
    dispatch(getCommiteWithVisitAction(id));
    dispatch(getCommiteWithVisitAction(id));

    activeTab(0);
  };

  useEffect(() => {
    dispatch(getCommiteWithVisitAction(id));

    if (patient == undefined || visit_id == null) {
      return;
    }

    
    patient?.map((item) => {
      item?.commites?.map((element) => {
        if (element.visit_id == visit_id) {
          
          setCommiteId(element.id);
          setColor(element.color);
          if (element.lezhnas) {
            
            
            setInputValue({
              ...inputValue,
              alive: element.lezhnas.alive,
              salaryEmploey: element.lezhnas.salaryEmploye,
              house: element.lezhnas.house,
              tenant: element.lezhnas.teanant,
              poverty: element.lezhnas.poverty,
              car: element.lezhnas.Vehicle,
              land: element.lezhnas.land,
              debt: element.lezhnas.debt,
              monthlyIncome: element.lezhnas.monthlyIncome,
              child: element.lezhnas.child,
              moreThan40: element.lezhnas.more_than_40,
              smoke: element.lezhnas.smoke,
              masqoud: element.lezhnas.masqoud,
              helpingNear: element.lezhnas.helping_near,
              businessman: element.lezhnas.businessman,
            });
            setInputValueText({
              ...inputValueText,
              lezhna_id: element.lezhnas.id,
              GovOrPrivateEmp: element.lezhnas.govOrPriveteEmp,
              amountOfRent: element.lezhnas.amountOfRent,
              economicStatus: element.lezhnas.economicStatus,
              NeighborhoodType: element.lezhnas.NeighborhoodTypeselect,
              metters: element.lezhnas.metters,
              typeVihicle: element.lezhnas.typeofVehicle,
              typeOfEstiPrice: element.lezhnas.typeofLandEstimatedPrice,
              amountOfSalary: element.lezhnas.amountofSalary,
              amountOfLoan: element.lezhnas.amountofLoan,
              resonOfDebt: element.lezhnas.resonOfDebt,
              haveOtherDeasise: element.lezhnas.haveOtherDiseases,
              // typeOfDeasise: element.lezhnas.Typeofdisease,
              numOfchildren: element.lezhnas.numberofChildren,
              numberOfStudent: element.lezhnas.numberofStudent,
              monthlyIncomeFamily: element.lezhnas.monthlyIncomeFamily,
              povertyRates: element.lezhnas.PovertyRates,
              HMCabilitySurgery: element.lezhnas.HMCabilitToSurgery,
              patientDeserveMediaWork: element.lezhnas.PMediaWork,
              relationship: element.lezhnas.relationship,
              name: element.lezhnas.Name,
              phone: element.lezhnas.phoneNumber,
              numberOfland: element.lezhnas.numberOfLand,
              nowDate: element.lezhnas.nowDate,
              numberOfHouse: element.lezhnas.numberOfHouse,
              note: element.lezhnas.note,
              Typeofdisease: element.lezhnas.typeOfDeasise,
              mercy: element.lezhnas.mercy,

              typeOfOccupation: element.lezhnas.type_of_occupation,
              placeOfOccupation: element.lezhnas.place_of_occupation,

              totalSalary: element.lezhnas.total_salary,
              WHBATFUP: element.lezhnas.WHBATFUP,
              phoneMemeber: element.lezhnas.phone_memeber,
              nameMember: element.lezhnas.name_member,
              smoker: element.lezhnas.smoker,
              workOfSons: element.lezhnas.work_of_sons,
              parentOrBortherJob: element.lezhnas.parent_or_brother_job,
              supervisor: element.lezhnas.supervisor,
              typeOfBussnis: element.lezhnas.type_of_bussniss,
              incomeOfBussnisse: element.lezhnas.income_of_bussniss,
              compareHouseHoldExpenses:
                element.lezhnas.compare_house_hold_expenses,
              secoundWork: element.lezhnas.secound_work,
              typeOfHouse: element.lezhnas.type_of_house,
            });
          }
        }
      });
    });
    // console.log(inputValue.pNmae)
  }, [visit_id]);
  const { ref, takeScreenshot } = useScreenshot();
  return (
    <div>
      {/* <button onClick={takeScreenshot}>Take Screenshot</button> */}
      <form action="" className="parentFormVisit relative" ref={ref}>
        <div
          className="cursor-pointer absolute right-2"
          onClick={() => setIsOpenList(!isOpenList)}
        >
          <p className="text-xl mb-2 text-indigo-500 hover:text-indigo-600 pb-2">
            <FaThList />
          </p>
        </div>
        {isOpenList && (
          <div className="absolute  right-0 flex  bg-gray-100 p-2 top-2  shadow-2xl rounded-sm  z-50 w-4/12">
            <div className="childFormVisit">
              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-3"
                  name="smoke"
                  checked={inputValue.smoke == "yes" ? true : false}
                  onChange={handelInput}
                  defaultValue={inputValue.smoke}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-3"></label>
                <label className="lbl" htmlFor="cbx-3">
                  جگەرە کێشە
                </label>
              </div>

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-50"
                  name="helpingNear"
                  checked={inputValue.helpingNear == "yes" ? true : false}
                  onChange={handelInput}
                  value={inputValue.helpingNear}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-50"></label>
                <label className="lbl" htmlFor="cbx-50">
                  خزم و کەس هاوکاری دەکەن
                </label>
              </div>

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-1"
                  name="child"
                  checked={inputValue.child == "yes" ? true : false}
                  onChange={handelInput}
                  defaultValue={inputValue.child}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-1"></label>
                <label className="lbl" htmlFor="cbx-1">
                  نەخۆشەکە منداڵە
                </label>
              </div>

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-2"
                  name="moreThan40"
                  checked={inputValue.moreThan40 == "yes" ? true : false}
                  onChange={handelInput}
                  defaultValue={inputValue.moreThan40}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-2"></label>
                <label className="lbl" htmlFor="cbx-2">
                  نەخۆشەکە سەروو ٤٠ ساڵە
                </label>
              </div>
              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-49"
                  name="alive"
                  checked={inputValue.alive == "yes" ? true : false}
                  onChange={handelInput}
                  value={inputValue.alive}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-49"></label>
                <label className="lbl" htmlFor="cbx-49">
                  لە ژیاندا ماوە
                </label>
              </div>
              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-4"
                  name="masqoud"
                  checked={inputValue.masqoud == "yes" ? true : false}
                  onChange={handelInput}
                  defaultValue={inputValue.masqoud}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-4"></label>
                <label className="lbl" htmlFor="cbx-4">
                  ئەهلی مزگەوتە
                </label>
              </div>

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-47"
                  checked={inputValue.tenant == "yes" ? true : false}
                  name="tenant"
                  onChange={handelInput}
                  value={inputValue.tenant != null ? "yes" : "no"}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-47"></label>
                <label className="lbl" htmlFor="cbx-47">
                  کرێچیــە
                </label>
              </div>
              {/*end */}

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-100"
                  checked={inputValue.house == "yes" ? true : false}
                  name="house"
                  onChange={handelInput}
                  value={inputValue.house != null ? "yes" : "no"}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-100"></label>
                <label className="lbl" htmlFor="cbx-100">
                  خانووی خۆیەتی
                </label>
              </div>
              {/*end */}
              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-42"
                  name="car"
                  checked={inputValue.car == "yes" ? true : false}
                  onChange={handelInput}
                  defaultValue={inputValue.Vehicle}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-42"></label>
                <label className="lbl" htmlFor="cbx-42">
                  ئۆتۆمبیلی هەیە
                </label>
              </div>
              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-43"
                  checked={inputValue.land == "yes" ? true : false}
                  name="land"
                  onChange={handelInput}
                  value={inputValue.land != null ? "yes" : "no"}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-43"></label>
                <label className="lbl" htmlFor="cbx-43">
                  موڵک و زەوی هەیە
                </label>
              </div>
              {/*end */}

              {/* <div className="checkbox-wrapper-42">
                <input
                  id="cbx-44"
                  checked={inputValue.monthlyIncome == "yes" ? true : false}
                  name="monthlyIncome"
                  onChange={handelInput}
                  value={inputValue.monthlyIncome != null ? "yes" : "no"}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-44"></label>
                <label className="lbl" htmlFor="cbx-44">
                  داهاتی مانگانەی
                </label>
              </div> */}
              {/*end */}

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-45"
                  checked={inputValue.poverty == "yes" ? true : false}
                  name="poverty"
                  onChange={handelInput}
                  value={inputValue.poverty != null ? "yes" : "no"}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-45"></label>
                <label className="lbl" htmlFor="cbx-45">
                  هەژارە
                </label>
              </div>
              {/*end */}

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-46"
                  checked={inputValue.debt == "yes" ? true : false}
                  name="debt"
                  onChange={handelInput}
                  value={inputValue.debt != null ? "yes" : "no"}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-46"></label>
                <label className="lbl" htmlFor="cbx-46">
                  قەرزارە
                </label>
              </div>
              {/*end */}

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-48"
                  checked={inputValue.salaryEmploey == "yes" ? true : false}
                  name="salaryEmploey"
                  onChange={handelInput}
                  value={inputValue.salaryEmploey}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-48"></label>
                <label className="lbl" htmlFor="cbx-48">
                  مووچە خۆرە یاخود نا
                </label>
              </div>

              <div className="checkbox-wrapper-42">
                <input
                  id="cbx-60"
                  checked={inputValue.businessman == "yes" ? true : false}
                  name="businessman"
                  onChange={handelInput}
                  value={inputValue.businessman}
                  type="checkbox"
                />
                <label className="cbx" htmlFor="cbx-60"></label>
                <label className="lbl" htmlFor="cbx-60">
                  کاسبە
                </label>
              </div>
              {/*end */}
            </div>
          </div>
        )}

        {/* section 1 */}
        <section
          onClick={() => setIsOpenList(false)}
          className="parentInputGrid mt-5"
        >
          <div className="w-full ">
            <div className="w-full  text-right pb-4">
              <label htmlFor="number"> * چەند مەترە</label>
            </div>
            <input
              type="text"
              name="metters"
              value={inputValueText.metters}
              onChange={handelInputTextChange}
              id="number"
              className="inputUser text-right w-full"
              placeholder="چەند مەترە"
            />
          </div>

          <div className="w-full ">
            <div className="w-full  text-right pb-4">
              <label htmlFor="numberOfHouse">* ژمارەی خانوو </label>
            </div>
            <input
              type="text"
              name="numberOfHouse"
              value={inputValueText.numberOfHouse}
              onChange={handelInputTextChange}
              id="numberOfHouse"
              className="inputUser text-right  w-full"
              placeholder="ژمارەی خانوو"
            />
          </div>

          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="NeighborhoodType">* جۆری گەرەک </label>
            </div>
            <select
              name="NeighborhoodType"
              value={inputValueText.NeighborhoodType}
              onChange={handelInputTextChange}
              className="inputUser bg-white"
              id="NeighborhoodType"
            >
              <option value="">Select ..</option>
              <option value="هەژارنشین">هەژارنشین </option>
              <option value="ناوەند">ناوەند</option>
              <option value="دەوڵەمەند">دەوڵەمەند</option>
            </select>
          </div>
          <div className="w-full  ">
            <div className="w-full  text-right pb-4  ">
              <label htmlFor="ec" className="text-right  w-full mb-20 pb-20">
                * باری ئابووری
              </label>
            </div>
            <select
              name="economicStatus" // Make sure this matches the state variable key
              value={inputValueText.economicStatus}
              onChange={handelInputTextChange}
              className="inputUser  bg-white"
              id="ec"
            >
              <option value="">Select ..</option>
              <option value="خراپ">خراپ</option>
              <option value="ناوەند">ناوەند</option>
              <option value="باش">باش</option>
              <option value="زۆر باش">زۆر باش</option>
            </select>
          </div>
        </section>
        {/* section 2 */}
        <section
          onClick={() => setIsOpenList(false)}
          className="parentInputGrid "
        >
          <div></div>
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="emp">* شایستەی کاری راگەیاندنە</label>
            </div>

            <select
              name="patientDeserveMediaWork"
              value={inputValueText.patientDeserveMediaWork}
              onChange={handelInputTextChange}
              className="select  bg-white"
              id="emp"
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ </option>
              <option value="نەخێر">نەخێر </option>
            </select>
          </div>

          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="ch">* ژ. مناڵ</label>
            </div>

            <select
              name="numOfchildren"
              value={inputValueText.numOfchildren}
              onChange={handelInputTextChange}
              className="select bg-white"
              id="ch"
            >
              <option value="">Select ..</option>
              {number.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="numberOfStudent">* ژ. خوێندکار </label>
            </div>
            <select
              name="numberOfStudent"
              value={inputValueText.numberOfStudent}
              onChange={handelInputTextChange}
              className="select bg-white"
              id="numberOfStudent"
            >
              <option value="">Select ..</option>
              {number.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </section>

        {/* section  3 */}
        <section
          onClick={() => setIsOpenList(false)}
          className="parentInputGrid"
        >
          <div className="w-full">
            <div className="w-full text-right pb-4">
              <label htmlFor="hmc">
                * توانای لە ئەستۆ گرتنی تێچوی نەشتەرگەری
              </label>
            </div>
            <input
              name="HMCabilitySurgery"
              value={inputValueText.HMCabilitySurgery}
              onChange={handelInputTextChange}
              placeholder="توانای لە ئەستۆ گرتنی تێچوی نەشتەرگەری"
              className="inputUser text-right  w-full"
              id="encom"
            />
          </div>
          {/* new */}
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="مووچەی گشتی">* مووچەی گشتی</label>
            </div>
            <input
              type="text"
              name="totalSalary"
              value={inputValueText.totalSalary}
              onChange={handelInputTextChange}
              id="مووچەی گشتی"
              className="inputUser text-right  w-full"
              placeholder="مووچەی گشتی"
            />
          </div>

          {/* new */}
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="پلەیس">* شوێنی پیشە</label>
            </div>
            <input
              type="text"
              name="placeOfOccupation"
              value={inputValueText.placeOfOccupation}
              onChange={handelInputTextChange}
              id="پلەیس"
              className="inputUser text-right  w-full"
              placeholder="شوێنی پیشە"
            />
          </div>

          {/* new */}
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="جۆر">* جۆری پیشە</label>
            </div>
            <input
              type="text"
              name="typeOfOccupation"
              value={inputValueText.typeOfOccupation}
              onChange={handelInputTextChange}
              id="جۆر"
              className="inputUser text-right  w-full"
              placeholder="جۆری پیشە"
            />
          </div>
        </section>
        {/* section 4 */}

        <section
          onClick={() => setIsOpenList(false)}
          className="parentInputGrid"
        >
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="WHBATFUP">
                * پرسیار لە کێ کراوە بۆ بەدوداچوون
              </label>
            </div>
            <input
              type="text"
              name="WHBATFUP"
              value={inputValueText.WHBATFUP}
              onChange={handelInputTextChange}
              id="WHBATFUP"
              className="inputUser text-right  "
              placeholder="پرسیار لە کێ کراوە بۆ بەدوداچوون"
            />
          </div>
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="Typeofdisease">* جۆری نەخۆشیەکە</label>
            </div>
            <input
              type="text"
              name="Typeofdisease"
              value={inputValueText.Typeofdisease}
              onChange={handelInputTextChange}
              id="Typeofdisease"
              className="inputUser text-right  "
              placeholder="جۆری نەخۆشیەکە"
            />
          </div>
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="haveOtherDeasise">* نەخۆشی تریان هەیە </label>
            </div>

            <select
              value={inputValueText.haveOtherDeasise}
              name="haveOtherDeasise"
              onChange={handelInputTextChange}
              className="select  bg-white"
              id="haveOtherDeasise"
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ </option>
              <option value="نەخێر">نەخێر </option>
            </select>
          </div>
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="encom">
                * داهاتی مانگانەی هاوسەری یان خێزانی
              </label>
            </div>
            <input
              type="text"
              name="monthlyIncomeFamily"
              value={inputValueText.monthlyIncomeFamily}
              onChange={handelInputTextChange}
              id="encom"
              className="inputUser text-right  w-full"
              placeholder="داهاتی مانگانەی هاوسەری یان خێزانی"
            />
          </div>
        </section>

        <section
          onClick={() => setIsOpenList(false)}
          className="parentInputGrid"
        >
          <div></div>
          <div></div>
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="nameMember">
                * ناوی ئەندامی بەدوادوچوونی کردووە
              </label>
            </div>
            <input
              type="text"
              name="nameMember"
              value={inputValueText.nameMember}
              onChange={handelInputTextChange}
              id="nameMember"
              className="inputUser text-right  "
              placeholder="ناوی ئەندامی بەدوادوچوونی کردووە"
            />
          </div>
          <div className="w-full ">
            <div className="w-full text-right pb-4">
              <label htmlFor="phoneMemeber">* ژمارەی ئەندام</label>
            </div>
            <input
              type="text"
              name="phoneMemeber"
              value={inputValueText.phoneMemeber}
              onChange={handelInputTextChange}
              id="phoneMemeber"
              className="inputUser text-right  "
              placeholder="جۆری نەخۆشیەکە"
            />
          </div>
        </section>

        {/* section 5 */}
        {inputValue.helpingNear == "yes" ? (
          <div>
            <div className="flex justify-end">
              <div className="bg-slate-900/10 py-2 px-1 rounded-md backdrop-blur-3xl font-bold text-gray-800  mt-2 ">
                <p>خزم و کەس هاوکاری دەکەن ؟ </p>
              </div>
            </div>

            <section
              onClick={() => setIsOpenList(false)}
              className="parentInputGrid"
            >
              <div></div>
              <div className="w-full hidden ">
                <label htmlFor="number">Current Date *</label>
                <input
                  type="text"
                  name="nowDate"
                  readOnly
                  value={currentDate}
                  onChange={handelInputTextChange}
                  id="number"
                  className="inputUser text-right  w-full"
                  placeholder="Phone Number "
                />
              </div>

              <div className="w-full ">
                <div className="w-full text-right pb-4">
                  <label htmlFor="numberRelation">
                    * رەقەمی مۆبایل خزم یان کەس
                  </label>
                </div>
                <input
                  type="text"
                  name="phone"
                  value={inputValueText.phone}
                  onChange={handelInputTextChange}
                  id="numberRelation"
                  className="inputUser text-right  w-full"
                  placeholder="رەقەمی مۆبایل خزم یان کەس "
                />
              </div>

              <div className="w-full ">
                <div className="w-full text-right pb-4">
                  <label htmlFor="relationShip">* خزمایەتی</label>
                </div>
                <input
                  type="text"
                  name="relationship"
                  value={inputValueText.relationship}
                  onChange={handelInputTextChange}
                  id="relationShip"
                  className="inputUser text-right  w-full"
                  placeholder="خزمایەتی "
                />
              </div>
              <div className="w-full ">
                <div className="w-full text-right pb-4">
                  <label htmlFor="nameRealtion">* ناوی خزم یان کەس</label>
                </div>
                <input
                  type="text"
                  name="name"
                  value={inputValueText.name}
                  onChange={handelInputTextChange}
                  id="nameRealtion"
                  className="inputUser text-right  w-full"
                  placeholder="ناوی خزم و کەس"
                />
              </div>
            </section>
          </div>
        ) : (
          ""
        )}

        {/* section 6 */}

        {inputValue.alive == "yes" ||
        inputValue.smoke == "yes" ||
        inputValue.child == "yes" ||
        inputValue.moreThan40 == "yes" ? (
          <div className="flex justify-end">
            <div className="bg-slate-900/10 py-2 px-1 rounded-md backdrop-blur-3xl font-bold text-gray-800  mt-2 ">
              <p>تەمەنی نەخۆش & لە ژیان مانەوەی سەرپەرشتیار & جگەرە کێشان ؟ </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <section className="parentInputGrid">
          {inputValue.smoke == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="smoker">* جگەرە کێشان </label>
              </div>

              <select
                value={inputValueText.smoker}
                name="smoker"
                onChange={handelInputTextChange}
                className="select  bg-white"
                id="smoker"
              >
                <option value="">Select ..</option>
                <option value="بەڵێ">خۆی </option>
                <option value="باوک">باوک </option>
                <option value="برا">برا </option>
                <option value="مام">مام </option>
                <option value="خاڵ">خاڵ </option>
                <option value="دایک">دایک </option>
                <option value="خوشک">خوشک </option>
                <option value="هاوسەری">هاوسەری </option>
              </select>
            </div>
          ) : (
            ""
          )}

          {inputValue.alive == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="supervisor">* سەرپەرشتیار </label>
              </div>
              <input
                type="text"
                name="supervisor"
                value={inputValueText.supervisor}
                onChange={handelInputTextChange}
                id="supervisor"
                className="inputUser text-right  w-full"
                placeholder=" سەرپەرشتیار "
              />
            </div>
          ) : (
            ""
          )}

          {inputValue.child == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="workParent">* ئیشی دایک و باوک یان برا </label>
              </div>
              <input
                type="text"
                name="parentOrBortherJob"
                value={inputValueText.parentOrBortherJob}
                onChange={handelInputTextChange}
                id="workParent"
                className="inputUser text-right  w-full"
                placeholder="ئیشی دایک و باوک یان برا"
              />
            </div>
          ) : (
            ""
          )}

          {inputValue.moreThan40 == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="workChild">* ئیشی کورەکانی </label>
              </div>
              <input
                type="text"
                name="workOfSons"
                value={inputValueText.workOfSons}
                onChange={handelInputTextChange}
                id="workChild"
                className="inputUser text-right  w-full"
                placeholder="ئیشی کورەکانی"
              />
            </div>
          ) : (
            ""
          )}
        </section>

        {/* section 7 */}

        {inputValue.land == "yes" || inputValue.tenant == "yes" ? (
          <div className="flex justify-end">
            <div className="bg-slate-900/10 py-2 px-1 rounded-md backdrop-blur-3xl font-bold text-gray-800  mt-2 ">
              <p>بوونی موڵک و زەوی یان کرێچیە ؟ </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <section className="parentInputGrid">
          {inputValue.land == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="Price">
                  * جۆری موڵک یاخود زەوی نرخی خامڵیندراو
                </label>
              </div>
              <input
                type="text"
                name="typeOfEstiPrice"
                value={inputValueText.typeOfEstiPrice}
                onChange={handelInputTextChange}
                id="Price"
                className="inputUser text-right  w-full"
                placeholder="جۆری موڵک یاخود زەوی نرخی خامڵیندراو"
              />
            </div>
          ) : null}

          {inputValue.tenant == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="ژمارەی خاوەن خانوو">
                  * ژمارەی خاوەن خانوو{" "}
                </label>
              </div>
              <input
                type="text"
                value={inputValueText.numberOfland}
                name="numberOfland"
                onChange={handelInputTextChange}
                id="ژمارەی خاوەن خانوو"
                className="inputUser text-right  w-full"
                placeholder="ژمارەی خاوەن خانوو"
              />
            </div>
          ) : null}

          {inputValue.tenant == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="* بڕی کرێ">* بڕی کرێ خانوو </label>
              </div>
              <input
                type="text"
                name="amountOfRent"
                value={inputValueText.amountOfRent}
                onChange={handelInputTextChange}
                id="* بڕی کرێ"
                className="inputUser text-right  w-full"
                placeholder="بڕی کرێ خانوو"
              />
            </div>
          ) : null}

          {inputValue.tenant == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="چ جۆرە خانوویەک">* چ جۆرە خانوویەک </label>
              </div>
              <input
                type="text"
                name="typeOfHouse"
                value={inputValueText.typeOfHouse}
                onChange={handelInputTextChange}
                id="چ جۆرە خانوویەک"
                className="inputUser text-right  w-full"
                placeholder="چ جۆرە خانوویەک"
              />
            </div>
          ) : null}
        </section>

        {/* section 8 */}

        {inputValue.salaryEmploey == "yes" ||
        inputValue.salaryEmploey == "yes" ? (
          <div className="flex justify-end">
            <div className="bg-slate-900/10 py-2 px-1 rounded-md backdrop-blur-3xl font-bold text-gray-800  mt-2 ">
              <p>کارمەندی حکومەتە یان تایبەت ؟ </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <section className="parentInputGrid">
          <div></div>
          {inputValueText.GovOrPrivateEmp == "حکومەت" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="ئیشی دووەمی">* ئیشی دووەمی </label>
              </div>
              <input
                type="text"
                name="secoundWork"
                value={inputValueText.secoundWork}
                onChange={handelInputTextChange}
                id="ئیشی دووەمی"
                className="inputUser  text-right w-full"
                placeholder="ئیشی دووەمی"
              />
            </div>
          ) : (
            <div></div>
          )}

          {inputValue.salaryEmploey == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="salary">* بڕی مووچە </label>
              </div>
              <input
                type="text"
                name="amountOfSalary"
                value={inputValueText.amountOfSalary}
                onChange={handelInputTextChange}
                id="salary"
                className="inputUser  text-right w-full"
                placeholder="بڕی مووچە"
              />
            </div>
          ) : null}
          {inputValue.salaryEmploey == "yes" ? (
            <div className="w-full ">
              <div className="w-full  text-right pb-4">
                <label htmlFor="GovOrPrivateEmp">
                  * کارمەندی حکومییە یان کەرتی تایبەت
                </label>
              </div>

              <select
                name="GovOrPrivateEmp" // Make sure this matches the state variable key
                value={inputValueText.GovOrPrivateEmp}
                onChange={handelInputTextChange}
                className="select  bg-white"
                id="GovOrPrivateEmp"
              >
                <option value="">Select ..</option>
                <option value="حکومەت">حکومەت </option>
                <option value="تایبەت">تایبەت </option>
              </select>
            </div>
          ) : null}
        </section>

        {/* section 9 */}

        {inputValue.poverty == "yes" ||
        inputValue.debt == "yes" ||
        inputValue.car == " yes" ? (
          <div className="flex justify-end">
            <div className="bg-slate-900/10 py-2 px-1 rounded-md backdrop-blur-3xl font-bold text-gray-800  mt-2 ">
              <p>سەیارەی هەیە & هەژارە & قەرزارە ؟ </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="parentInputGrid">
          {inputValue.debt == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="debt">*بڕی قەرزەکە </label>
              </div>
              <input
                type="text"
                name="amountOfLoan"
                value={inputValueText.amountOfLoan}
                onChange={handelInputTextChange}
                id="debt"
                className="inputUser text-right  w-full"
                placeholder="بڕی قەرزەکە "
              />
            </div>
          ) : (
            ""
          )}

          {inputValue.debt == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="resonDebt">*هۆکاری قەرزەکە </label>
              </div>
              <input
                type="text"
                value={inputValueText.resonOfDebt}
                name="resonOfDebt"
                onChange={handelInputTextChange}
                id="resonDebt"
                className="inputUser text-right   w-full"
                placeholder="هۆکاری قەرزاری"
              />
            </div>
          ) : (
            <div className="hidden"> </div>
          )}
          {inputValue.poverty == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="pov">* ڕێژەی هەژاری </label>
              </div>

              <select
                name="povertyRates"
                value={inputValueText.povertyRates}
                onChange={handelInputTextChange}
                className="select  bg-white"
                id="pov"
              >
                <option value="">Select ..</option>
                {handleCost.map((cost, index) => {
                  return (
                    <option key={index} value={cost}>
                      {cost}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}

          {inputValue.car == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="typeCAR">* جۆری ئۆتۆمبێل </label>
              </div>
              <input
                type="text"
                name="typeVihicle"
                value={inputValueText.typeVihicle}
                onChange={handelInputTextChange}
                id="typeCAR"
                className="inputUser text-right  w-full"
                placeholder="جۆری ئۆتۆمبێل"
              />
            </div>
          ) : null}
        </div>
        {inputValue.businessman == "yes" ? (
          <div className="flex justify-end">
            <div className="bg-slate-900/10 py-2 px-1 rounded-md backdrop-blur-3xl font-bold text-gray-800  mt-2 ">
              <p>کاسبە ؟ </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {inputValue.businessman == "yes" && (
          <section className="parentInputGrid">
            <div></div>
            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="compareHouseHoldExpenses">
                  * بەراورد بە مەسروفاتی ماڵەوە{" "}
                </label>
              </div>
              <input
                type="text"
                value={inputValueText.compareHouseHoldExpenses}
                name="compareHouseHoldExpenses"
                onChange={handelInputTextChange}
                id="compareHouseHoldExpenses"
                className="inputUser text-right   w-full"
                placeholder="بەراورد بە مەسروفاتی ماڵەوە"
              />
            </div>

            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="داهاتی کاسبیەکە">*داهاتی کاسبیەکە </label>
              </div>
              <input
                type="text"
                value={inputValueText.incomeOfBussnisse}
                name="incomeOfBussnisse"
                onChange={handelInputTextChange}
                id="داهاتی کاسبیەکە"
                className="inputUser text-right   w-full"
                placeholder="داهاتی کاسبیەکە"
              />
            </div>

            <div className="w-full ">
              <div className="w-full text-right pb-4">
                <label htmlFor="typeOfBussnis">*جۆری کاسبیەکە </label>
              </div>
              <input
                type="text"
                value={inputValueText.typeOfBussnis}
                name="typeOfBussnis"
                onChange={handelInputTextChange}
                id="typeOfBussnis"
                className="inputUser text-right   w-full"
                placeholder="جۆری کاسبیەکە"
              />
            </div>
          </section>
        )}

        <div>
          <div className="py-5 flex gap-3 pb-4">
            <div className="text-right w-full mr-2">
              <div className="w-full flex justify-end pb-4 ">
                <label htmlFor="">* تێبینی </label>
              </div>
              <textarea
                name="note"
                onChange={handelInputTextChange}
                value={inputValueText.note}
                placeholder="تێبینی"
                className="outline-none  px-4 inputUser  w-full text-right "
                rows="7"
              ></textarea>
            </div>

            <div className="w-full">
              <div className="w-full flex justify-end pb-4 ">
                <label htmlFor="" className="text-right w-full mr-2">
                  * بەزەیی
                </label>
              </div>
              <textarea
                name="mercy"
                onChange={handelInputTextChange}
                value={inputValueText.mercy}
                placeholder="بەزەیی"
                className="outline-none  px-4 inputUser  w-full text-right "
                rows="7"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div>
            <button className="btn-success px-3 " onClick={handelSave}>
              <div className="flex gap-2 items-center">
                <p>Save</p>
                <div>
                  <p>
                    <MdOutlineSaveAlt />
                  </p>
                </div>
              </div>
              {/* Save */}
            </button>
          </div>
          <div>
            <button onClick={handleSubmit} className="btn-primary">
              <div className="flex gap-2 items-center">
                <div>
                  <p>Assign</p>
                </div>
                <p>
                  <MdOutlineAssignmentTurnedIn />
                </p>
              </div>
            </button>
          </div>

          <button
            onClick={(e) => {
              e.preventDefault();
              takeScreenshot();
            }}
            className="btn-primary"
          >
            <div className="flex gap-2 items-center">
              <div>
                <p>Screen Shoot</p>
              </div>
              <p>
                <RiScreenshot2Fill />
              </p>
            </div>
          </button>
        </div>
      </form>

      <div className="pb-20"></div>
    </div>
  );
};

export default Visit;
