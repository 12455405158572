import { useDispatch, useSelector } from "react-redux";
import {
  createVisitAction,
  getPatient,
  // rejectPatient,
} from "../../Action/patient";
import ReactPaginate from "react-paginate";
import "./css/invoice.css";
import { useEffect, useState } from "react";
// import { getUserAuth } from "../../Action/userRegister";
import Logo from "../../Image/Logo.jpg";
import {
  MdAssignmentReturn,
  MdAssignmentTurnedIn,
  MdOutlineAdd,
} from "react-icons/md";
import { TbPlayerEject } from "react-icons/tb";

import InvoiceModal from "../../Modal/InvoiceModal";
import AssignModal from "../../Modal/AssignModal";
import RejectModal from "../../Modal/RejectModal";

import { getNotificationAction } from "../../Action/receiveAction";
import EditModalPatient from "../../Modal/EditModalPatient";
import { AiFillEye, AiOutlineEdit, AiOutlineUserAdd } from "react-icons/ai";
import {
  FaCheck,
  FaNewspaper,
  FaRegNewspaper,
  FaUserInjured,
} from "react-icons/fa";
import { CiFilter } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import Loading from "../Loading/Loading";
import { FaPlus } from "react-icons/fa6";
import { button } from "@material-tailwind/react";

const NewInvoice = () => {
  const [isOpenEditModalPatient, setIsOpenEditModalPatient] = useState(false);

  const dispatch = useDispatch();

  const getPatients = useSelector((state) => state.getPatients);
  // const getNotification = useSelector((state) => state.getNotification);

  const { patients, loading, total } = getPatients;
  // const { notifi } = getNotification;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [reject, setReject] = useState(false);

  // localStorage.setItem("notifi", notifi);

  const [patientId, setPatientId] = useState(null);
  const locationID = localStorage.getItem("locationId");

  // useEffect(() => {
  //   if (locationID == null || locationID == undefined || locationID == "") {
  //     return;
  //   }
  //   dispatch(getNotificationAction(locationID));
  // }, [locationID]);
  const [select, setSelect] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAssignModal, setIsOpenAssignModal] = useState(false);
  const [searchParams, setSearchParams] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
  });
  const handelReject = (id) => {
    setPatientId(id);
    setReject(true);
  };
  const handelEdit = (event, id) => {
    event.preventDefault();
    setPatientId(id);
    setIsOpenEditModalPatient(true);
  };
  const regex = /^(\d{4})(\d{3})(\d{4})$/;
  useEffect(() => {
    if (locationID == undefined || locationID == null || locationID == "") {
      return;
    } else {
      const { code, fullname, phone, gender, age } = searchParams;

      dispatch(
        getPatient(
          locationID,
          currentPage,
          perPage,
          code,
          fullname,
          phone,
          gender,
          age,
          select
        )
      );
    }
  }, [select, perPage]);

  const [isOpenFilter, setIsOpentFilter] = useState(false);
  const [index, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };

  const [search, setSearch] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
  });
  const handleNewVisit = (event, patient_id) => {
    event.preventDefault();
    // console.log(patient_id)
    dispatch(createVisitAction(patient_id));
    const { code, fullname, phone, gender, age } = searchParams;
    dispatch(
      getPatient(
        locationID,
        currentPage,
        perPage,
        code,
        fullname,
        phone,
        gender,
        age,
        1
      )
    );
    dispatch(
      getPatient(
        locationID,
        currentPage,
        perPage,
        code,
        fullname,
        phone,
        gender,
        age,
        1
      )
    );
  };

  const handleSearch = (columnToClear) => {
    const { code, fullname, phone, gender, age } = searchParams;

    const updatedSearchParams = {
      code: columnToClear === "code" ? "" : code,
      fullname: columnToClear === "fullname" ? "" : fullname,
      phone: columnToClear === "phone" ? "" : phone,
      gender: columnToClear === "gender" ? "" : gender,
      age: columnToClear === "age" ? "" : age,
    };

    setIsOpentFilter(false);

    dispatch(
      getPatient(
        locationID,
        currentPage,
        perPage,
        updatedSearchParams.code,
        updatedSearchParams.fullname,
        updatedSearchParams.phone,
        updatedSearchParams.gender,
        updatedSearchParams.age,
        select
      )
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-[95%] m-auto">
          <div className="flex bg-white rounded-md shadow-sm justify-between px-4 py-2 items-center ">
            <div className="flex gap-2 items-center">
              <p className="text-teal-600  px-2 py-2 text-lg">
                <FaUserInjured />
              </p>

              <p className="text-lg tracking-wider">Patient</p>
            </div>

            <div>
              <button
                className="btn-success"
                onClick={() => setIsOpenModal(true)}
              >
                <div className="flex gap-2 items-center">
                  <p className=" tracking-wider">Patient</p>
                  <div>
                    <FaPlus />
                  </div>
                </div>
              </button>
            </div>
          </div>

          <div className="h-[75vh] bg-white   m-auto">
            <div className="overflow-y-auto  w-full  h-[90%]  ">
              <table className="w-full ">
                <thead className="sticky top-0 bg-gray-100 ">
                  <tr className="w-full">
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Patient ID</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(1)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 1
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Code"
                          value={searchParams.code || ""}
                          name="code"
                          onChange={(e) => {
                            setSearchParams({
                              ...searchParams,
                              code: e.target.value,
                            });
                          }}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("code");
                              setSearchParams({
                                ...searchParams,
                                code: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Full Name</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(2)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 2
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Full Name"
                          value={searchParams.fullname}
                          name="fullname"
                          onChange={(e) =>
                            setSearchParams({
                              ...search,
                              fullname: e.target.value,
                            })
                          }
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("fullname");
                              setSearchParams({
                                ...searchParams,
                                fullname: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Phone</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(3)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 3
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Phone"
                          value={searchParams.phone}
                          name="phone"
                          onChange={(e) =>
                            setSearchParams({
                              ...search,
                              phone: e.target.value,
                            })
                          }
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch  "
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("phone");
                              setSearchParams({
                                ...searchParams,
                                phone: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative  ">
                      <div className="flex   justify-between items-center">
                        <p className="pl-1">Gender</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(4)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 4
                            ? "parentInputSearch bg-white"
                            : "hidden"
                        }
                      >
                        <div>
                          <div className="w-full">
                            <select
                              onChange={(e) =>
                                setSearchParams({
                                  ...searchParams,
                                  gender: e.target.value,
                                })
                              }
                              value={searchParams.gender}
                              name="gender"
                              type="text"
                              placeholder="Gender"
                              className={
                                "inputSearch p-1.5 shadow-xl rounded  "
                              }
                            >
                              <option value="">ڕەگەز هەڵبژێرە</option>
                              <option value="Male">Male </option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("gender");
                              setSearchParams({
                                ...searchParams,
                                gender: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Birth Date</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(5)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 5
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Birth Date"
                          value={searchParams.age}
                          name="age"
                          onChange={(e) =>
                            setSearchParams({
                              ...search,
                              age: e.target.value,
                            })
                          }
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("age");
                              setSearchParams({
                                ...searchParams,
                                age: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>Date</th>
                    <th className="">
                      <p className="pl-1">Visits</p>
                    </th>
                    <th className="">Action</th>
                  </tr>
                </thead>

                <tbody className="w-full py-0">
                  {patients.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          item.reject == 2
                            ? "tableRowRject py-0 "
                            : item.assign == 1
                            ? "tableRowSave py-0"
                            : item.assign == 2
                            ? "tableRowGreen py-0"
                            : "tableRow py-0"
                        }
                      >
                        <td className="w-80 py-0 ">{item.code}</td>
                        <td className="w-80 py-0 ">{item.Pname}</td>
                        <td className="w-80 py-0 ">
                          {item.phone.replace(regex, "$1-$2-$3")}
                        </td>
                        <td className="w-80 py-0 ">{item.gender}</td>
                        <td className="w-80 py-0 ">{item.birthDate}</td>
                        <td className="w-80 py-0 ">
                          {item.created_at.toString().slice(0, 10)}
                        </td>
                        <td className="w-80  text-red-500 font-bold text-xl text-decoration underline">
                          {item.visits_with_reject_count == 0
                            ? 1
                            : item.visits_with_reject_count}
                        </td>

                        <td className="w-80 py-2">
                          <div className="flex flex-col  lg:flex lg:flex-row lg:gap-1 justify-center">
                            {item.assign == 1 || item.assign == 2 ? (
                              ""
                            ) : (
                              <button
                                onClick={() => {
                                  setIsOpenAssignModal(true);
                                  setPatientId(item.id);
                                }}
                                className="btn-primary"
                                title="assign"
                              >
                                <div className="flex gap-1 items-center">
                                  <p>Assign</p>
                                  <p>
                                    <MdAssignmentTurnedIn />
                                  </p>
                                </div>
                              </button>
                            )}
                            {item.assign == 2 ? (
                              <button
                                onClick={(event) => {
                                  handleNewVisit(event, item.id);
                                }}
                                className="btn-plus "
                                title="New Visit"
                              >
                                <div className="flex gap-2 items-center">
                                  <p>New Visit</p>
                                  <p>
                                    <FaNewspaper />
                                  </p>
                                </div>
                              </button>
                            ) : (
                              ""
                            )}

                            {item.assign == 1 && (
                              <button
                                className="btn-danger"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handelReject(item.id);
                                }}
                              >
                                <div className="flex gap-1 items-center">
                                  <p>Reject</p>
                                  <p>
                                    <TbPlayerEject />
                                  </p>
                                </div>
                              </button>
                            )}

                            {item.assign == 1 || item.assign == 2 ? (
                              <button
                                className="btn-info"
                                onClick={(event) => {
                                  handelEdit(event, item.id);
                                }}
                              >
                                <div className="flex gap-1 items-center">
                                  <p>{item.assign == 2 ? "View" : "Edit"}</p>
                                  <p>
                                    {item.assign == 2 ? (
                                      <AiFillEye />
                                    ) : (
                                      <AiOutlineEdit />
                                    )}
                                  </p>
                                </div>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
              <div className="flex items-center gap-4 ">
                <p className="text-xl ">Total: </p>
                <div
                  className={
                    "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                  }
                >
                  {total}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <select
                    name=""
                    className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                    onChange={(e) => setPerPage(e.target.value)}
                    value={perPage}
                    id=""
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="flex justify-center   mt-1">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel={">"}
                    // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                    breakLabel="..."
                    className="flex  items-center   -space-x-px h-8 text-sm"
                    previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                    nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                    pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                    breakClassName="w-8 py-2 text-center"
                    pageCount={Math.ceil(total / perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    // activeClassName=''
                    onPageChange={(event) => {
                      let currentPage = event.selected + 1;
                      dispatch(
                        getPatient(
                          locationID,
                          currentPage,
                          perPage,
                          "",
                          "",
                          "",
                          "",
                          "",
                          1
                        )
                      );
                      setCurrentPage(event.selected);
                    }}
                    activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          {isOpenModal && (
            <InvoiceModal closeModal={setIsOpenModal} locationID={locationID} />
          )}

          <div className="overflow-y-auto  ">
            {reject && (
              <RejectModal
                closeModal={setReject}
                isOpenReject={reject}
                patientId={patientId}
              />
            )}
          </div>
        </div>
      )}

      <div className="pb-20"></div>
      <div className="overflow-y-auto   ">
        {isOpenAssignModal && (
          <AssignModal
            closeModal={setIsOpenAssignModal}
            isOpenAssign={isOpenAssignModal}
            patientId={patientId}
          />
        )}
      </div>
      <div className="overflow-y-auto   ">
        {isOpenEditModalPatient && (
          <EditModalPatient
            closeModal={setIsOpenEditModalPatient}
            openEditModal={isOpenEditModalPatient}
            patientId={patientId}
          />
        )}
      </div>

      <div className="pb-8"></div>
    </>
  );
};

export default NewInvoice;
