import {
  CREATE_DOCTOR_FAIL,
  CREATE_DOCTOR_REQUEST,
  CREATE_DOCTOR_SUCCESS,
  FETCH_DOCTOR_FAIL,
  FETCH_DOCTOR_REQUEST,
  FETCH_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  UPDATE_DOCTOR_REQUEST,
  UPDATE_DOCTOR_SUCCESS,
} from "../../ActionType/DoctorType/DoctorType";

export const getDoctorReducer = (
  state = { loading: true, doctors: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_DOCTOR_REQUEST:
      return {
        loading: true,
      };
    case FETCH_DOCTOR_SUCCESS:
      return {
        loading: false,
        doctors: action.payload,
        error: "",
      };
    case FETCH_DOCTOR_FAIL:
      return {
        loading: false,
        doctors: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const createDoctorReducer = (
  state = { loading: true, doctors: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_DOCTOR_REQUEST:
      return {
        loading: true,
      };
    case CREATE_DOCTOR_SUCCESS:
      return {
        loading: false,
        doctors: action.payload,
        error: "",
      };
    case CREATE_DOCTOR_FAIL:
      
      return {
        loading: false,
        doctors: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export const updateDoctorReducer = (
  state = { loading: true, doctors: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_DOCTOR_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_DOCTOR_SUCCESS:
      return {
        loading: false,
        doctors: action.payload,
        error: "",
      };
    case UPDATE_DOCTOR_FAIL:
      return {
        loading: false,
        doctors: [],
        error: action.error,
      };
    default:
      return state;
  }
};
