import React, { useEffect, useState } from "react";
import ShowAllInfoMedia from "../../Table/Media/ShowAllInfoMedia";
import { useDispatch, useSelector } from "react-redux";
import { getAllInfoMediaAction } from "../../../Action/Media/sendMediaAction";
import Logo from "../../../Image/Logo.jpg";
import { HiViewColumns } from "react-icons/hi2";

import { VscListFilter } from "react-icons/vsc";
import ReactPaginate from "react-paginate";
import { TbFilterOff } from "react-icons/tb";
import ViewAllInfoMeidaModal from "../../../Modal/MediaModal/ViewAllInfoMeidaModal";
import { AiFillEye, AiTwotoneEye } from "react-icons/ai";
const ShowAllInfo = () => {
  const data = [
    // Add more data here
    // note:""
  ];
  const dispatch = useDispatch();
  const allInfoPatientMeda = useSelector((state) => state.allInfoPatientMeda);
  const { allInfo, loading, total } = allInfoPatientMeda;
  const [mediaId, setMediaId] = useState(null);
  const columns = [
    "View",
    "code",
    "FullName",
    "phone",
    "gender",
    "birthDate",
    "occuption",
    "nation",
    "country",
    "noFamily",
    "province",
    "district",
    "street",
    "closetHome",
    "secoundHome",
    "card",
    "smoke",
    "timeAllote",
    "SITD",
    "member",
    "memberLevel",
    "type_of_help",
    "TYP",
    "HEP",
    "finalDiagnosis",
    "earlyPatient",
    "earlySurgery",
    "Doctor",
    "ZODoctor",
    "medicalProvided",
    "supplyLocation",
    "Typemedicalsupplies",
    "helpingZO",
    "alive",
    "salaryEmploye",
    "date",
    "numberOfHouse",
    "house",
    "teanant",
    "numberOfLand",
    "poverty",
    "Vehicle",
    "land",
    "debt",
    "govOrPriveteEmp",
    "metters",
    "NeighborhoodTypeselect",
    "amountOfRent",
    "economicStatus",
    "typeofVehicle",
    "typeofLandEstimatedPrice",
    "monthlyIncome",
    "amountofSalary",
    "amountofLoan",
    "haveOtherDiseases",
    "Typeofdisease",
    "numberofChildren",
    "numberofStudent",
    "monthlyIncomeFamily",
    "PovertyRates",
    "HMCabilitToSurgery",
    "PMediaWork",
    "relationship",
    "Name",
    "resonOfDebt",
    "phoneNumber",
    "note",
  ];
  allInfo.map((all) => {
    data.push({
      View: (
        <button
          onClick={() => {
            setMediaId(all.media_id);
            setIsOenViewModalMedia(true);
          }}
          className="btnCoustmDesignGreen"
        >
          <AiTwotoneEye />
        </button>
      ),
      code: all.code,
      FullName: all.Pname,
      phone: all.phone,
      birthDate: all.birthDate,
      occuption: all.occuption,
      nation: all.nation,
      country: all.country,
      married: all.married,
      noFamily: all.noFamily,
      province: all.province,
      district: all.district,
      street: all.street,
      closetHome: all.closetHome,
      secoundHome: all.secoundHome,
      card: all.card,
      smoke: all.smoke,
      timeAllote: all.timeAllote,
      SITD: JSON.parse(all.SITD),
      member: JSON.parse(all.member),
      memberLevel: JSON.parse(all.memberLevel),
      type_of_help: JSON.parse(all.type_of_help),
      TYP: all.TYP,
      indiction: all.indiction,
      HEP: all.HEP,
      finalDiagnosis: all.finalDiagnosis,
      earlyPatient: all.earlyPatient,
      earlySurgery: all.earlySurgery,
      Doctor: all.Doctor,
      ZODoctor: all.ZODoctor,
      medicalProvided: all.medicalProvided,
      supplyLocation: all.supplyLocation,
      Typemedicalsupplies: all.Typemedicalsupplies,
      helpingZO: all.helpingZO,
      alive: all.alive,
      salaryEmploye: all.salaryEmploye,
      date: all.date,
      numberOfHouse: all.numberOfHouse,
      house: all.house,
      teanant: all.teanant,
      numberOfLand: all.numberOfLand,
      poverty: all.poverty,
      Vehicle: all.Vehicle,
      land: all.land,
      debt: all.debt,
      govOrPriveteEmp: all.govOrPriveteEmp,
      metters: all.metters,
      NeighborhoodTypeselect: all.NeighborhoodTypeselect,
      amountOfRent: all.amountOfRent,
      economicStatus: all.economicStatus,
      typeofVehicle: all.typeofVehicle,
      typeofLandEstimatedPrice: all.typeofLandEstimatedPrice,
      monthlyIncome: all.monthlyIncome,
      amountofSalary: all.amountofSalary,
      amountofLoan: all.amountofLoan,
      haveOtherDiseases: all.haveOtherDiseases,
      Typeofdisease: all.Typeofdisease,
      numberofChildren: all.numberofChildren,
      numberofStudent: all.numberofStudent,
      monthlyIncomeFamily: all.monthlyIncomeFamily,
      PovertyRates: all.PovertyRates,
      HMCabilitToSurgery: all.HMCabilitToSurgery,
      PMediaWork: all.PMediaWork,
      relationship: all.relationship,
      Name: all.Name,
      resonOfDebt: all.resonOfDebt,
      phoneNumber: all.alive,
      note: all.note,
    });
  });
  const [searchTerms, setSearchTerms] = useState({});
  const {
    code,
    FullName,
    phone,
    gender,
    birthDate,
    occuption,
    nation,
    country,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    type_of_help,
    TYP,
    HEP,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    Doctor,
    ZODoctor,
    medicalProvided,
    supplyLocation,
    Typemedicalsupplies,
    helpingZO,
    alive,
    salaryEmploye,
    numberOfHouse,
    house,
    teanant,
    numberOfLand,
    poverty,
    Vehicle,
    land,
    debt,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    amountOfRent,
    economicStatus,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountofSalary,
    amountofLoan,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    resonOfDebt,
    phoneNumber,
    note,
  } = searchTerms;
  useEffect(() => {
    dispatch(
      getAllInfoMediaAction(
        currentPage,
        perPage,
        locationId,
        searchTerms.code,
        FullName,
        phone,
        gender,
        birthDate,
        occuption,
        nation,
        country,
        noFamily,
        province,
        district,
        street,
        closetHome,
        secoundHome,
        card,
        smoke,
        timeAllote,
        SITD,
        member,
        memberLevel,
        type_of_help,
        TYP,
        HEP,
        finalDiagnosis,
        earlyPatient,
        earlySurgery,
        Doctor,
        ZODoctor,
        medicalProvided,
        supplyLocation,
        Typemedicalsupplies,
        helpingZO,
        alive,
        salaryEmploye,
        numberOfHouse,
        house,
        teanant,
        numberOfLand,
        poverty,
        Vehicle,
        land,
        debt,
        govOrPriveteEmp,
        metters,
        NeighborhoodTypeselect,
        amountOfRent,
        economicStatus,
        typeofVehicle,
        typeofLandEstimatedPrice,
        monthlyIncome,
        amountofSalary,
        amountofLoan,
        haveOtherDiseases,
        Typeofdisease,
        numberofChildren,
        numberofStudent,
        monthlyIncomeFamily,
        PovertyRates,
        HMCabilitToSurgery,
        PMediaWork,
        relationship,
        Name,
        resonOfDebt,
        phoneNumber,
        note
      )
    );
  }, [
    loading,
    searchTerms.code,
    FullName,
    phone,
    gender,
    birthDate,
    occuption,
    nation,
    country,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    type_of_help,
    TYP,
    HEP,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    Doctor,
    ZODoctor,
    medicalProvided,
    supplyLocation,
    Typemedicalsupplies,
    helpingZO,
    alive,
    salaryEmploye,
    numberOfHouse,
    house,
    teanant,
    numberOfLand,
    poverty,
    Vehicle,
    land,
    debt,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    amountOfRent,
    economicStatus,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountofSalary,
    amountofLoan,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    resonOfDebt,
    phoneNumber,
    note,
  ]);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const locationId = localStorage.getItem("locationId");

  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [showColumnDropdown, setShowColumnDropdown] = useState(false);

  const handleColumnToggle = (column) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.includes(column)
        ? prevColumns.filter((col) => col !== column)
        : [...prevColumns, column]
    );
  };

  const handleSearch = (column, value) => {
    setSearchTerms({ ...searchTerms, [column]: value });
  };
  const [isOenViewModalMedia, setIsOenViewModalMedia] = useState(false);
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[97%] bg-white h-[25rem] xl:h-[35rem] 3xl:[45rem] pb-4 m-auto rounded-md shadow-md">
          <div className="flex justify-end items-center gap-3 px-1 py-2">
            <div
              onClick={() => setIsOpenFilter(!isOpenFilter)}
              className="border p-2 rounded-md bg-teal-500 cursor-pointer text-white bg-opacity-70 hover:bg-opacity-100 duration-500"
              title="Search ..."
            >
              <VscListFilter />
            </div>

            <div
              onClick={() => setSearchTerms({})}
              title="Clear Filter"
              className="border p-2 rounded-md bg-red-500 cursor-pointer text-white bg-opacity-70 hover:bg-opacity-100 duration-500"
            >
              <TbFilterOff />
            </div>
            <div className="relative inline-block text-left">
              <div>
                <button
                  onClick={() => setShowColumnDropdown(!showColumnDropdown)}
                  className="border p-2 rounded-md bg-purple-500 text-white bg-opacity-70 hover:bg-opacity-100 duration-500"
                  title="View Columns" // This is the tooltip text
                >
                  <HiViewColumns />
                </button>
              </div>
              {showColumnDropdown && (
                <div className="origin-top-right absolute right-0 h-[34rem] overflow-y-auto mt-2  rounded-md shadow-lg shadow-blue-50 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-2">
                    {columns.map((column) => (
                      <label
                        key={column}
                        className="flex items-center space-x-2 p-2"
                      >
                        <input
                          type="checkbox"
                          checked={visibleColumns.includes(column)}
                          onChange={() => handleColumnToggle(column)}
                        />
                        {column}
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={
              columns.length > 6
                ? "overflow-x-auto  h-[75%] xl:h-[84%] "
                : "h-[75%] xl:h-[84%]"
            }
          >
            <table className="border-collapse h-[90%] overflow-y-auto  w-[1000rem]">
              <thead>
                <tr>
                  {columns.map(
                    (column) =>
                      visibleColumns.includes(column) && (
                        <th
                          key={column}
                          className="px-4 py-2 border"
                          style={{ width: "150px" }}
                        >
                          <div className="text-center">{column}</div>
                          {isOpenFilter && (
                            <input
                              type="text"
                              name={column}
                              placeholder={`Search ${column}...`}
                              // value={searchTerms[column] || ""}
                              onBlur={(e) => {
                                handleSearch(column, e.target.value);
                              }}
                              className="inputLogin p-1.5 shadow-xl rounded "
                            />
                          )}
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr className="tableRow" key={index}>
                    {columns
                      .filter((col) => visibleColumns.includes(col))
                      .map((col) => (
                        <td
                          key={col}
                          className="px-4 py-2  border"
                          // style={{ width: "290px" }}
                        >
                          {item[col]}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end   mt-3  mr-1">
            <ReactPaginate
              previousLabel="<"
              nextLabel={">"}
              // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
              breakLabel="..."
              className="flex  items-center   -space-x-px h-8 text-sm"
              previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
              nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
              pageLinkClassName="flex items-center  justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
              breakClassName="w-8 py-2 text-center"
              pageCount={Math.ceil(total / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={(event) => {
                let currentPage = event.selected + 1;
                dispatch(
                  getAllInfoMediaAction(
                    currentPage,
                    perPage,
                    locationId,
                    searchTerms.code,
                    FullName,
                    phone,
                    gender,
                    birthDate,
                    occuption,
                    nation,
                    country,
                    noFamily,
                    province,
                    district,
                    street,
                    closetHome,
                    secoundHome,
                    card,
                    smoke,
                    timeAllote,
                    SITD,
                    member,
                    memberLevel,
                    type_of_help,
                    TYP,
                    HEP,
                    finalDiagnosis,
                    earlyPatient,
                    earlySurgery,
                    Doctor,
                    ZODoctor,
                    medicalProvided,
                    supplyLocation,
                    Typemedicalsupplies,
                    helpingZO,
                    alive,
                    salaryEmploye,
                    numberOfHouse,
                    house,
                    teanant,
                    numberOfLand,
                    poverty,
                    Vehicle,
                    land,
                    debt,
                    govOrPriveteEmp,
                    metters,
                    NeighborhoodTypeselect,
                    amountOfRent,
                    economicStatus,
                    typeofVehicle,
                    typeofLandEstimatedPrice,
                    monthlyIncome,
                    amountofSalary,
                    amountofLoan,
                    haveOtherDiseases,
                    Typeofdisease,
                    numberofChildren,
                    numberofStudent,
                    monthlyIncomeFamily,
                    PovertyRates,
                    HMCabilitToSurgery,
                    PMediaWork,
                    relationship,
                    Name,
                    resonOfDebt,
                    phoneNumber,
                    note
                  )
                );
                setCurrentPage(currentPage);
              }}
              activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
            />
          </div>
        </div>
      )}
      {isOenViewModalMedia && (
        <ViewAllInfoMeidaModal
          closeModal={setIsOenViewModalMedia}
          meida_id={mediaId}
        />
      )}
    </>
  );
};

export default ShowAllInfo;
