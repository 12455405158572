import {
  FETCH_VISIT_BY_PATIENT_ID_FAIL,
  FETCH_VISIT_BY_PATIENT_ID_REQUEST,
  FETCH_VISIT_BY_PATIENT_ID_SUCCESS,
} from "../../ActionType/Visits/VisitsType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getVisitsByPatienIdAction = (patient_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: FETCH_VISIT_BY_PATIENT_ID_REQUEST,
    });
    const response = await api.get("visits/getVisitsByPatientId/" + patient_id, config);
    const { status, data } = response;
    console.log(data)
    if (status == 200) {
      dispatch({
        type: FETCH_VISIT_BY_PATIENT_ID_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_VISIT_BY_PATIENT_ID_FAIL,
    });
  }
};
