import React, { useEffect, useState } from "react";
import "./css/sidebar.css";
import { NavLink } from "react-router-dom";

import {
  MdDashboard,
  MdOutlinePersonalInjury,
  
} from "react-icons/md";
import { AiOutlineClose, AiOutlineUser } from "react-icons/ai";
import { DiHtml5Multimedia } from "react-icons/di";


import { TbPlayerEjectFilled } from "react-icons/tb";

import { CgMediaLive } from "react-icons/cg";

import Logo from "../../Image/Logo.jpg";

import { HiClipboardList, HiUserGroup } from "react-icons/hi";
import { RiFolderReceivedFill } from "react-icons/ri";

import { useDispatch } from "react-redux";

import { getBranch } from "../../Action/branch";
import { FaFile, FaSuitcaseMedical, FaUserDoctor } from "react-icons/fa6";
import { FaProcedures } from "react-icons/fa";

import { BsWhatsapp } from "react-icons/bs";

const Sidebar = ({ openModal, isOpenSidebar, users }) => {
  const pageMedical = [
    {
      id: 1,
      name: "Medical Commite",
      icon: <MdOutlinePersonalInjury />,
      route: "invoice",
    },
    {
      id: 2,
      name: "Follow-Up Commite",
      icon: <HiUserGroup />,
      route: "commite",
    },

    {
      id: 3,
      name: "Affiars Of Surgery",
      icon: <HiUserGroup />,
      route: "feedback",
    },
    {
      id: 4,
      name: "Report Monthly",
      icon: <HiClipboardList />,
      route: "reportMonthly",
    },
    {
      id: 5,
      name: "Whats App ",
      icon: <BsWhatsapp />,
      route: "whatsAppInfo",
    },
    {
      id: 6,
      name: "Medical Supplies",
      icon: <FaSuitcaseMedical />,
      route: "MedicalSupplies",
    },
    {
      id: 7,
      name: "Receive",
      icon: <RiFolderReceivedFill />,
      route: "receive",
    },
    {
      id: 8,
      name: "Doctor",
      icon: <FaUserDoctor />,
      route: "doctor",
    },
    {
      id: 9,
      name: "Surgery",
      icon: <FaProcedures />,
      route: "surgery",
    },
    {
      id: 10,
      name: "Reject",
      icon: <TbPlayerEjectFilled />,
      route: "reject",
    },
  ];

  const pageMedia = [
    {
      id: 15,
      name: "Media",
      icon: <DiHtml5Multimedia />,
      route: "show_receive",
    },
    {
      id: 16,
      name: "All Information",
      icon: <DiHtml5Multimedia />,
      route: "showAllInfoMedia",
    },
    {
      id: 17,
      name: "  Works",
      icon: <CgMediaLive />,
      route: "works",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranch());
  }, []);
  const [sectionId, setSectionId] = useState(null);
  useEffect(() => {
    if (users == undefined || users.length == 0) {
      return;
    } else {
      // console.log(users.role.permission[0].section_id)
      setSectionId(users.role.permission[0].section_id);
    }
  }, [sectionId, users]);

  return (
    <>
      {users == undefined || users.length == 0 || sectionId == null ? (
        ""
      ) : (
        <div className="">
          <div
            onClick={() => openModal(false)}
            className={
              isOpenSidebar
                ? "absolute bg-slate-700 z-50  bg-opacity-20 duration-700 h-screen w-full noScrool"
                : "bg-opacity-0 duration-700"
            }
          ></div>

          <div
            className={
              isOpenSidebar
                ? "absolute duration-700 left-0    z-50 noScrool text-white    h-screen  opacity-100 overflow-y-auto overflow-x-hidden bg-[#070F2B]  "
                : "absolute duration-700 -left-96 w-72 text-white  h-screen z-50 bg-slate-900"
            }
          >
            <div className="headSidebar">
              <div
                className={
                  "duration-500 w-20 brightness-150 hover:animate-bounce cursor-pointer "
                }
              >
                <img src={Logo} />
              </div>
              <div
                className="cursor-pointer duration-500 hover:scale-150 text-white animate-pulse"
                onClick={() => openModal(false)}
              >
                <AiOutlineClose />
              </div>
            </div>
            <hr />
            <div className="-z-50">
              <img
                src={Logo}
                className={
                  isOpenSidebar
                    ? " fixed md:-left-64 lg:-left-72  h-full object-cover  top-10 opacity-30 w-full -z-10 md:w-[30%] lg:w-[35%] duration-500 ease-linear "
                    : "w-[47%] -z-20 opacity-20 fixed -left-[90rem] top-20 duration-500 ease-in-out"
                }
                alt=""
              />
            </div>
            <nav className={"nav"}>
              <NavLink
                onClick={() => openModal(false)}
                to={"/"}
                className={({ isActive }) =>
                  isActive ? "navListActive" : "nav_list "
                }
              >
                <div>
                  {" "}
                  <MdDashboard />{" "}
                </div>
                <span className={""}>Dashboard</span>
              </NavLink>
              {/* end */}

              <hr />

              {/* medical Side ROute */}
              {sectionId == 5 || sectionId == 8 ? (
                <div>
                  {pageMedical.map((p, index) => {
                    return (
                      <>
                        {users.role.permission.map((permission, index) => {
                          return (
                            <>
                              {permission.page_id == p.id ||
                              (permission.page_id != p.id &&
                                permission.section_id == 8) ? (
                                <NavLink
                                  key={index}
                                  onClick={() => openModal(false)}
                                  to={p.route}
                                  className={({ isActive }) =>
                                    isActive ? "navListActive" : "nav_list "
                                  }
                                >
                                  <div> {p.icon}</div>
                                  <span>{p.name}</span>
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                  <hr />
                </div>
              ) : (
                ""
              )}

              {sectionId == 6 || sectionId == 8 ? (
                <div>
                  {pageMedia.map((p, index) => {
                    return (
                      <>
                        {users.role.permission.map((permission, index) => {
                          return (
                            <>
                              {permission.page_id == p.id ||
                              (permission.page_id != p.id &&
                                permission.section_id == 8) ? (
                                <NavLink
                                  key={index}
                                  onClick={() => openModal(false)}
                                  to={p.route}
                                  className={({ isActive }) =>
                                    isActive ? "navListActive" : "nav_list "
                                  }
                                >
                                  <div> {p.icon}</div>
                                  <span>{p.name}</span>
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                  <hr />
                </div>
              ) : (
                ""
              )}

              <NavLink
                onClick={() => openModal(false)}
                to={`/reciveFileAdminstrator`}
                className={({ isActive }) =>
                  isActive ? "navListActive" : "nav_list "
                }
              >
                <div>
                  <FaFile />
                </div>
                <span>Recive File Adminstrator</span>
              </NavLink>
              <div className="pt-2"></div>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
