import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_DOCTOR_FAIL,
  CREATE_DOCTOR_REQUEST,
  CREATE_DOCTOR_SUCCESS,
  FETCH_DOCTOR_REQUEST,
  FETCH_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  UPDATE_DOCTOR_REQUEST,
  UPDATE_DOCTOR_SUCCESS,
} from "../../ActionType/DoctorType/DoctorType";

export const getDoctorAction = (name, expert, status) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      name,
      expert,
      status,
    },
  };
  await api
    .get("doctor", config)
    .then((response) => {
      dispatch({
        type: FETCH_DOCTOR_REQUEST,
      });
      const { status, data } = response;

      if (status == 200) {
        dispatch({
          type: FETCH_DOCTOR_SUCCESS,
          payload: data.data,
        });
      } else if (status == 500) {
        Swal.fire({
          icon: "info",
          title: "Some Thing Went Wrong",

          timer: 1500,
        });
      }
    })
    .catch(() => {
      Swal.fire({
        icon: "warning",
        text: "Please Check Your Request",
        timer: 1500,
      });
    });
};
export const createDoctorAction = (name, expert) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  Swal.showLoading();
  api
    .post(
      "doctor/create",
      {
        dr_name: name,
        expert,
        // status:"Active"
      },
      config
    )
    .then((response) => {
      dispatch({
        type: CREATE_DOCTOR_REQUEST,
      });
      const { status } = response;

      if (status == 200) {
        dispatch(getDoctorAction("", "", ""));
        dispatch(getDoctorAction("", "", ""));
        dispatch({
          type: CREATE_DOCTOR_SUCCESS,
          payload: response.data.data,
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Doctor Was Saved Succesfuly",
          timer: 1500,
        });
      } else if (status == 422) {
        Swal.fire({
          icon: "info",
          title: "Some Thing Went Wrong",

          timer: 1500,
        });
      }
    })
    .catch((err) => {
      const { status, data } = err.response;
      // console.log(data);
      if (status == 422) {
        dispatch({
          type: CREATE_DOCTOR_FAIL,
          payload: [],
          error: data,
        });
        return;
      }
    });
};

export const updateDoctorAction = (id, name, status, expert) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .put(
      `doctor/update/${id}`,
      {
        dr_name: name,
        status,
        expert,
      },
      config
    )
    .then((response) => {
      const { status } = response;
      dispatch({
        type: UPDATE_DOCTOR_REQUEST,
      });

      if (status == 200) {
        dispatch({
          type: UPDATE_DOCTOR_SUCCESS,
          payload: response.data,
        });
        dispatch(getDoctorAction("", "", ""));
        dispatch(getDoctorAction("", "", ""));
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Doctor Was Updated Succesfuly",
          timer: 1500,
        });
      }
    })
    .catch((err) => {
      const { status, data } = err.response;
      if (status == 422) {
        dispatch({
          type: UPDATE_DOCTOR_FAIL,
          payload: [],
          error: data,
        });
      }
    });
};
