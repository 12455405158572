import React, { useEffect, useState } from "react";
import "./css/navbar.css";

import { BiLogOut } from "react-icons/bi";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
// import { getUserAuth } from "../../Action/userRegister";

import Sidebar from "./Sidebar";
import { MdKeyboardDoubleArrowRight, MdManageAccounts } from "react-icons/md";
import { decryptData, logOut } from "../../Action/login";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  RiAccountPinCircleFill,
  RiAccountPinCircleLine,
  RiAdminLine,
} from "react-icons/ri";
import { getNotificationAction } from "../../Action/receiveAction";
import Footer from "./Footer";
// import { FaClinicMedical } from "react-icons/fa";
import { FaStore } from "react-icons/fa6";
import { BsFillKeyFill, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
// import { sendTokenAction } from "../../Action/SendToken/SendTokenAction";

const Navbar = ({ users }) => {
  const location = useLocation();
  const pathName = location.pathname;
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  // const getNotification = useSelector((state) => state.getNotification);
  // const { notifi } = getNotification;
  const dispatch = useDispatch();

  const locationID = localStorage.getItem("locationId");
  let first = [];
  useEffect(() => {
    if (locationID == "" || locationID == null || locationID == undefined) {
      return;
    } else {
      dispatch(getNotificationAction(locationID));
    }
  }, [locationID]);
  // const [firstCharchter, setFirstCharecter] = useState([]);

  // useEffect(() => {
  //   if (users.fullname_en == undefined || users.fullname_en == null) {
  //     return;
  //   }
  //   first.push(users.fullname_en);

  //   setFirstCharecter(first[0].charAt(0));
  // }, [firstCharchter, users]);

  const handelLogoutAccount = () => {
    dispatch(logOut());
  };
  const handelLogout = () => {
    setIsOpenLogout(isOpenLogout ? false : true);
  };
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const handelSideBarOpen = () => {
    setIsOpenSideBar(true);
  };
  const isLoggedIn = localStorage.getItem("loggedIn");
  if (isLoggedIn == null || isLoggedIn == undefined) {
    return null;
  }

  const sendDataToIframe = () => {
    const iframe = document.getElementById("myIframe");

    const token = localStorage.getItem("token");
    const loggedIn = localStorage.getItem("loggedIn");
    const defaultPackage = localStorage.getItem("defaultPackage");
    iframe.contentWindow.postMessage(
      {
        token: token,
        loggedIn: loggedIn,
        defaultPackage: defaultPackage,
      },
      "https://administrator.zadorg.site"
    );
    // navigate("http://localhost:3001")
  };

  const decrypteData = decryptData(isLoggedIn, "8");
  return (
    <>
      {users == undefined || users.length == 0 ? (
        ""
      ) : (
        <div className={decrypteData ? "flex" : "hidden"}>
          <iframe
            className="hidden"
            id="myIframe"
            src="https://administrator.zadorg.site"
            title="My Iframe"
          ></iframe>
          <div>
            {" "}
            <Sidebar
              isOpenSidebar={isOpenSideBar}
              openModal={setIsOpenSideBar}
              users={users}
            />
          </div>
          <div className="containerNav bg-[#212121] backdrop-blur-3xl ">
            <div className="flex items-center lg:gap-5">
              {pathName == "/" ? (
                ""
              ) : pathName == "/editProfile" ? (
                ""
              ) : (
                <div
                  className="cursor-pointer  text-white text-[1.9rem] animate-pulse"
                  onClick={handelSideBarOpen}
                >
                  <MdKeyboardDoubleArrowRight />
                </div>
              )}
              <div>
                <p className="text-sm lg:text-2xl  text-white">
                  ZAD Organization
                </p>
              </div>
            </div>
            <div className=" text-xl tracking-wider  cursor-pointer">
              <div className="flex gap-4 items-center">
                <div className="text-white text-xl">
                  <Link to={"/"}>
                    <p className="text-white text-xl">
                      <AiOutlineHome />
                    </p>
                  </Link>
                </div>
                <div
                  onClick={handelLogout}
                  className="flex items-center  gap-4"
                >
                  <p className="text-white  ">
                    {/* {firstCharchter} */}
                    <BsThreeDotsVertical />
                  </p>
                </div>
              </div>
              <div
                className={
                  isOpenLogout
                    ? "absolute   text-lg z-50 transition duration-300 md:absolute top-14 right-1   bg-white  shadow-2xl shadow-gray-400 rounded-md md:rounded-b    p-2"
                    : " absolute right-2  bg-[#fff] p-2 text-base  shadow-2xl  rounded-md opacity-0  duration-500 hidden   "
                }
              >
                <Link to={"/"} onClick={handelLogout}>
                  <div className="pb-2 flex flex-col items-center gap-2">
                    <p className="text-4xl">
                      {" "}
                      <RiAccountPinCircleLine />
                    </p>
                    <p>{users.fullname_en}</p>
                  </div>
                </Link>

                <hr />

                {users.role.permission[0].section_id == 8 && (
                  <Link
                    to={`https://administrator.zadorg.site`}
                    onClick={sendDataToIframe}
                  >
                    <div className="py-2 flex gap-2 items-center w-full justify-center m-auto duration-500 ">
                      {/* <div className="text-black text-xl">
                        <RiAdminLine />
                      </div> */}
                      <p className="px-4 bg-gray-100 text-base font-bold py-1 rounded-sm hover:bg-[#522261] duration-500 hover:text-gray-100">
                        Go To Zad Administrator
                      </p>
                    </div>
                  </Link>
                )}

                <hr />
           
                {/* <hr /> */}

                <Link to={`editProfile`} onClick={handelLogout}>
                  <div className="py-2 flex gap-2 items-center  hover:translate-x-1 duration-500 ">
                    <div className="text-black rotate-45  text-xl">
                      <BsFillKeyFill />
                    </div>
                    <p className="text-base">Change Passowrd</p>
                  </div>
                </Link>

                <hr />

                <button
                  onClick={handelLogoutAccount}
                  className="flex items-center gap-1  w-full hover:translate-x-1 duration-300 py-1 rounded-md"
                >
                  <div className="rotate-180">
                    <BiLogOut className="text-black text-xl" />
                  </div>
                  <p>Sign Out</p>
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Navbar;
