import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllInfoPatient } from "../Action/patient";
import { Link } from "react-router-dom";
// import { all } from "axios";
import Logo from "../Image/Logo.jpg";

const ViewAllInfoPatient = ({ closeModal, lezhnaId, patientId }) => {
  const locationId = localStorage.getItem("locationId");
  const allInfoPatient = useSelector((state) => state.allInfoPatient);
  const { allInfo, loading, image, visit } = allInfoPatient;
  const [selectVisit, setSelectVisit] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllInfoPatient(lezhnaId, locationId, patientId, selectVisit));
    // console.log("all info : ",allInfo)
  }, [selectVisit]);

  return (
    <div className="fixed duration-500  overflow-y-auto bg-slate-700 bg-opacity-20 py-8 h-screen left-0 top-0  z-50 w-full ">
      <div className="bg-[#fafafa] w-[95%] py-4 mt-3 rounded-lg shadow-lg px-4  m-auto z-50 opacity-100">
        <div className="flex flex-row-reverse justify-between">
          <div>
            {loading ? (
              ""
            ) : (
              <select
                name=""
                onChange={(e) => setSelectVisit(e.target.value)}
                className="select"
                id=""
                value={selectVisit}
                // value={selectVisit}
              >
                <option value="" selected>
                  Select Visit ...
                </option>
                {visit.map((visit, index) => (
                  <option key={index} value={visit.id}>
                    {" "}
                    {visit.created_at.toString().slice(0, 10)}{" "}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <button onClick={() => closeModal(false)}>
              <span className="text-red-500 animate-pulse text-2xl">x</span>
            </button>
          </div>
        </div>
        {/* end Head */}
        {loading ||
        selectVisit == null ||
        selectVisit == "" ||
        allInfo == undefined ||
        allInfo == [] ||
        allInfo == null ? (
          <div className="flex flex-col">
            <img
              src={Logo}
              alt="Logo"
              className="w-56 m-auto animate-pulse mt-2 "
            />
            <div className="text-2xl text-center font-bold  ">
              <div className="flex justify-center gap-4">
                <p className="animate-charcter">Please Wait</p>
                <div class="container2">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>{" "}
              </div>
            </div>
          </div>
        ) : (
          <form action="">
            {allInfo.map((element, index) => {
              return (
                <div key={index} className="">
                  <div className="bg-teal-500 mt-7 m-auto rounded-sm shadow-lg text-white py-2 px-3 w-7/12 md:w-2/12">
                    <p className="text-center">فۆرمی زانیاری نەخۆش </p>
                  </div>
                  <div className="parentInputGrid">
                    <div className="w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number"> *رەقەم مۆبایل</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.patients.phone}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="gender">*ڕەگەز </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.patients.gender}
                      />
                    </div>
                    {/* end Of Input  patient */}

                    <div className="w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="name">*ناوی نەخۆش</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.patients.Pname}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="w-full">
                      <label htmlFor="">Patient ID *</label>
                      <input
                        type="text"
                        className="inputUser  w-full"
                        readOnly
                        value={element.commite.patients.code}
                      />
                    </div>
                    {/* end Of Input */}
                  </div>

                  <div className="bg-yellow-500 text-white py-2 xl:w-2/12 rounded-sm shadow-md mt-4  m-auto">
                    <p className="text-center">فۆرمی کاروباری نەشتەرگەری </p>
                  </div>

                  <div className="parentInputGrid">
                   

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="name">* لەسەر ئاستی ئەندام</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.memberLevel}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="member">* ئەندام</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.member}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        TYP *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.TYP}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="number">* پسپۆڕی جۆری نەخۆشی </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.SITD}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="name">* نەخۆشی پێش وەخت</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.earlyPatient}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        indiction *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.indiction}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        HEP *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.HEP}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        final Diagnosis *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.finalDiagnosis}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="name">
                          * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.Typemedicalsupplies}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="ZODoctor">
                          {" "}
                          *پزیشکی هاوکاری ڕیکخراوی زادە
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.ZODoctor}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="doctor"> * پزیشکی نێردراو بۆ</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.Doctor}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="name">* نەشتەرگەری پێشوەخت </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.earlySurgery}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="supplyLocation">
                          شوێنی دابینکردنی پێداویستی
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.supplyLocation}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="supplyLocation">
                          هاوکاری رێکخراوی زادە
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.helpingZO}
                      />
                    </div>
                    {/* end Of Input Commite */}
                  </div>
                  <div className="my-2 w-full">
                    <textarea
                      name=""
                      id=""
                      value={element.commite.note}
                      className="inputUser py-2 w-full"
                      rows="8"
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div className="bg-blue-500 text-white py-2 xl:w-2/12 rounded-sm shadow-md mt-4  m-auto">
                    <p className="text-center">فۆرمی لیژنەی بەدواداچون </p>
                  </div>
                  <div className="parentInputGrid">
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="province">* پارێزگا </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.province}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end pr-5">
                        <label htmlFor="nation"> *نەتەوە</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.nation}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end pr-5">
                        <label htmlFor="country"> * وڵات </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.country}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end pr-5">
                        <label htmlFor="name"> *پیشە</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.occuption}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="gender"> * خێزاندارە </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.married}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="closetHome">
                          *نزیکترین شوێن لە ماڵیانەوە
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.closetHome}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="province">*شەقام گەڕەک </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.street}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="province">* ناحیە/قەزا </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.district}
                      />
                    </div>
                    {/* end Of Input */}

                    <div className="mt-2 w-full">
                      <div className="w-full justify-end flex">
                        <label htmlFor="scoundPhoneNo">*ژ.مۆبایلی دووەم</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.secoundHome}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end pr-5">
                        <label htmlFor="name"> *جگەرە کێشە</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.smoke}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full justify-end flex">
                        <label htmlFor="timeAllot">
                          * کاتی دیاریکراو بۆ هەڵسەنگاندن{" "}
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.timeAllote}
                      />
                    </div>
                    <div className="mt-2 w-full">
                      <div className="flex justify-end pr-5">
                        <label htmlFor="noFamily">*ژمارەی خێزان</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.commite.noFamily}
                      />
                    </div>
                    {/* end Of Input */}

               
                    {/* end Of Input Commite */}
                  </div>
                  <div className="parentInputGrid">
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        alive *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.alive}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        salaryEmploye *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.salaryEmploye}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        Visit Date *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.date}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        house *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.house}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        teanant *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.teanant}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        Vehicle *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.Vehicle}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        land *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.land}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <label htmlFor="" className="capitalize">
                        debt *
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.debt}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full  text-right">
                        <label htmlFor="number">* چەند مەترە </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.metters}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full text-right">
                      <label htmlFor="number ">
                        * جۆری موڵک یاخود زەوی نرخی خامڵیندراو
                      </label>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.typeofLandEstimatedPrice}
                      />
                    </div>
                    <div className="mt-2 w-full">
                      <div className="w-full  text-right">
                        <label htmlFor="number" className="text-right  w-full">
                          * کارمەندی حکومییە یان کەرتی تایبەت
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.govOrPriveteEmp}
                      />
                    </div>
                    <div className="mt-2 w-full">
                      <div className="w-full  text-right">
                        <label htmlFor="number" className="text-right  w-full">
                          * باری ئابووری
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.economicStatus}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* بڕی قەرزەکە </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.amountofLoan}
                      />
                    </div>
                    {/* end Of Input */}{" "}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* بڕی مووچە </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.amountofSalary}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* بڕی کرێ خانوو</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.amountOfRent}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* جۆری گەرەک</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.NeighborhoodTypeselect}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number"> * ژ. خوێندکار</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.numberofStudent}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">* نەخۆشی تریان هەیە *</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.haveOtherDiseases}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">* جۆری نەخۆشیەکە</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.Typeofdisease}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">* ڕێژەی هەژاری </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.PovertyRates}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* جۆری ئۆتۆمبێل </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.typeofVehicle}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">
                          * توانای لە ئەستۆ گرتنی تێچوی نەشتەرگەری
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.HMCabilitToSurgery}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">
                          {" "}
                          * داهاتی مانگانەی هاوسەری یان خێزانی
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.monthlyIncomeFamily}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">* ژ. مناڵ</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.numberofChildren}
                      />
                    </div>
                    {/* end Of Input */}
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number">
                          * شایستەی کاری راگەیاندنە
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.PMediaWork}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* خزمایەتی</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.relationship}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">* ناوی خزم یان کەس</label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.Name}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full text-right">
                        <label htmlFor="number">
                          * رەقەمی مۆبایل خزم یان کەس
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.phoneNumber}
                      />
                    </div>
                    {/* end Of Input */}
                    <div className="mt-2 w-full">
                      <div className="w-full flex justify-end">
                        <label htmlFor="" className="capitalize">
                          *ڕەقەمی خزم و کەسوکار
                        </label>
                      </div>
                      <input
                        type="text"
                        className="inputUser w-full text-right"
                        readOnly
                        value={element.phoneNumber}
                      />
                    </div>
                    {/* end Of Input */}
                  </div>
                  <div className="flex gap-3">
                    <div className="mt-2 w-full">
                      <label
                        htmlFor="message"
                        class="block mb-2 mr-2 text-right text-sm font-medium text-gray-900 "
                      >
                        تێبینی
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        className="inputUser w-full text-right"
                        value={element.note}
                        placeholder="تێبینی"
                      ></textarea>
                    </div>

                    <div className="mt-2 w-full">
                      <label
                        htmlFor="message"
                        className="block mb-2 mr-2 text-sm text-right font-medium text-gray-900 "
                      >
                        بەزەیی
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        class="inputUser w-full text-right"
                        value={element.mercy}
                        placeholder="بەزەیی"
                      ></textarea>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="grid grid-cols-3  gap-4">
              {image.map((element, index) => {
                return (
                  <Link
                    to={element.image}
                    key={index}
                    target="_blank"
                    className="border-2 border-black rounded-md mt-4  shadow-lg"
                  >
                    <div className="mt-2 w-full">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Image
                      </label>

                      <img
                        src={element.image}
                        className="w-full h-44 object-cover border-2  "
                        alt=""
                      />
                    </div>
                  </Link>
                );
              })}
            </div>
          </form>
        )}
      </div>
      <div className="pb-16"></div>
    </div>
  );
};

export default ViewAllInfoPatient;
