import Swal from "sweetalert2";
import {
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_ALL_USER_FAIL,
  FETCH_ALL_USER_REQUEST,
  FETCH_ALL_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SHOW_USER_ADMINSTRATOR_FAIL,
  SHOW_USER_ADMINSTRATOR_REQUEST,
  SHOW_USER_ADMINSTRATOR_SUCCESS,
  SHOW_USER_FAIL,
  SHOW_USER_REQUEST,
  SHOW_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_INFORMATION_FAIL,
  UPDATE_USER_INFORMATION_REQUEST,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../ActionType/userCreateType";
import { api } from "../Api/api";
import { getToken, logOut } from "./login";

export const userRegister =
  (
    EnName,
    KuName,
    birthDate,
    gender,
    phone,
    secPhone,
    pnpc,
    email,
    married,
    NumberOfChildern,
    bloodGorup,
    DateOfCommencement,
    ChronicDiseases,
    certificate,
    certificateField,
    OccupationOutside,
    Workplace,
    jobTitle,
    LanguageLevel,
    Computerskills,
    branch,
    section,
    role,
    image
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // Authorization: `Bearer ${getToken()}`,
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const location_id=localStorage.getItem("locationId")
    const formData = new FormData();
    formData.append("fullname_en", EnName);
    formData.append("fullname_ku", KuName);
    formData.append("email", email);
    formData.append("password", "12345678");
    formData.append("birth_date", birthDate);
    formData.append("gender", gender);
    formData.append("phone", phone);
    formData.append("secound_phone", secPhone);
    formData.append("close_phone", pnpc);
    formData.append("married", married);
    formData.append("number_of_children", NumberOfChildern);
    formData.append("blood_group", bloodGorup);
    formData.append("date_of_commencement", DateOfCommencement);
    formData.append("chronic_diseases", ChronicDiseases);
    formData.append("certificate", certificate);
    formData.append("certificate_field", certificateField);
    formData.append("occupationoutside", OccupationOutside);
    formData.append("work_place", Workplace);
    formData.append("job_tiltle", jobTitle);
    formData.append("language_level", LanguageLevel);
    formData.append("computer_skills", Computerskills);
    formData.append("branch_id", branch || location_id );
    formData.append("section_id", section);
    formData.append("role_id", role);
    formData.append("user_cv", image);

    api
      .post("user/create", formData, config)
      .then((response) => {
        console.log(response);
        dispatch({ type: CREATE_USER_REQUEST });
        if (response.status == 200) {
          const user = response.data.user;
          dispatch({ type: CREATE_USER_SUCCESS, payload: user });
          Swal.fire("Good job!", "Account Was Created", "success");
        } else if (response.status === 404) {
          console.log(response.data);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please Fill The Gaps",
          });
        }
      })
      .catch((error) => {
        // console.log(error.message)
        dispatch({ type: CREATE_USER_FAIL, payload: [] });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getUserAuth = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get("user/getAuth", config)
    .then((response) => {
      dispatch({ type: FETCH_USER_REQUEST });
      if (response.data.status === 200) {
        // console.log("resp: ", response);
        dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.userInfo });
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("locationId");
        localStorage.removeItem("notifi");
        localStorage.removeItem("loggedIn");
      }
      dispatch({ type: FETCH_USER_FAIL, payload: [] });
    });
};

export const showUserAdminstratorAction = (location, section) => (dispatch) => {
  const arrayLocationTemp = [];
  location.map((loc) => {
    arrayLocationTemp.push(loc.value);
  });
  const arraySectionTemp = [];
  section.map((loc) => {
    arraySectionTemp.push(loc.value);
  });
  // console.log(arraySectionTemp);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      location: arrayLocationTemp,
      section: arraySectionTemp,
    },
  };

  api
    .get("user/showUserAdminstrator", config)
    .then((response) => {
      const { status, data } = response;
      // console.log('data1 : ',data);
      dispatch({ type: SHOW_USER_ADMINSTRATOR_REQUEST });
      if (status == 200) {
        dispatch({ type: SHOW_USER_ADMINSTRATOR_SUCCESS, payload: data });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: SHOW_USER_ADMINSTRATOR_FAIL, payload: [] });
    });
};

export const updateUserAction = (id, password) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(
      "user/update",
      {
        id: id,
        password: password,
      },
      config
    )
    .then((response) => {
      const { status } = response;

      dispatch({ type: UPDATE_USER_REQUEST });
      if (status === 200) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response.data.userInfo,
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "User Was Saved",
        });
      }

      if (status == 500) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response.data.userInfo,
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Some thing Went Wrong",
        });
      }
    })
    .catch((error) => {
      dispatch({ type: UPDATE_USER_FAIL, payload: [] });

      dispatch({
        type: UPDATE_USER_SUCCESS,
        // payload: response.data.userInfo,
      });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Your Request Is Faild",
      });
    });
};

export const getAllUserAction =
  (
    page,
    perPage,
    fullname_ku,
    fullname_en,
    email,
    birth_date,
    gender,
    phone,
    secound_phone,
    close_phone,
    married,
    number_of_children,
    blood_group,
    date_of_commencement,
    chronic_diseases,
    certificate,
    certificate_field,
    occupationoutside,
    work_place,
    job_tiltle,
    language_level,
    computer_skills
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        fullname_ku,
        fullname_en,
        email,
        birth_date,
        gender,
        phone,
        secound_phone,
        close_phone,
        married,
        number_of_children,
        blood_group,
        date_of_commencement,
        chronic_diseases,
        certificate,
        certificate_field,
        occupationoutside,
        work_place,
        job_tiltle,
        language_level,
        computer_skills,
      },
    };
    api
      .get("user", config)
      .then((response) => {
        const { data, status } = response;
        dispatch({
          type: FETCH_ALL_USER_REQUEST,
        });
        if (status == 200) {
          dispatch({
            type: FETCH_ALL_USER_SUCCESS,
            payload: { data: data.data, total: data.total },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_USER_FAIL,
          payload: [],
          error: "Please Check Your Request",
        });
      });
  };

export const resetPasswordUserAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`user/reset/${id}`, {}, config)
    .then((response) => {
      const { status } = response;

      if (status === 200) {
      }

      if (status == 500) {
      }
    })
    .catch((error) => {
      dispatch({ type: UPDATE_USER_FAIL, payload: [] });

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Your Request Is Faild",
      });
    });
};

export const showUserAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_USER_REQUEST,
    // payload:response.data,
  });
  api
    .get(`user/show/${id}`, config)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: SHOW_USER_SUCCESS,
          payload: data,
        });
      }

      if (status == 500) {
        dispatch({
          type: SHOW_USER_FAIL,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_USER_FAIL, payload: [] });

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Your Request Is Faild",
      });
    });
};

export const updateUserInformationAction =
  (
    id,
    EnName,
    KuName,
    birthDate,
    gender,
    phone,
    secPhone,
    pnpc,
    email,
    married,
    NumberOfChildern,
    bloodGorup,
    DateOfCommencement,
    ChronicDiseases,
    certificate,
    certificateField,
    OccupationOutside,
    Workplace,
    jobTitle,
    LanguageLevel,
    Computerskills,
    branch,
    section,
    role,
    image
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_USER_INFORMATION_REQUEST,
      // payload:response.data,
    });

    const formData = new FormData();
    formData.append("fullname_en", EnName);
    formData.append("fullname_ku", KuName);
    formData.append("email", email);
    // formData.append("password", "12345678");
    formData.append("birth_date", birthDate);
    formData.append("gender", gender);
    formData.append("phone", phone);
    formData.append("secound_phone", secPhone);
    formData.append("close_phone", pnpc);
    formData.append("married", married);
    formData.append("number_of_children", NumberOfChildern);
    formData.append("blood_group", bloodGorup);
    formData.append("date_of_commencement", DateOfCommencement);
    formData.append("chronic_diseases", ChronicDiseases);
    formData.append("certificate", certificate);
    formData.append("certificate_field", certificateField);
    formData.append("occupationoutside", OccupationOutside);
    formData.append("work_place", Workplace);
    formData.append("job_tiltle", jobTitle);
    formData.append("language_level", LanguageLevel);
    formData.append("computer_skills", Computerskills);
    formData.append("branch_id", branch);
    formData.append("section_id", section);
    formData.append("role_id", role);
    formData.append("user_cv", image);

    api
      .post(`user/updateUser/${id}`,  formData , config)
      .then((response) => {
        const { status, data } = response;
        console.log("data : ", response);
        if (status === 200) {
          dispatch({
            type: UPDATE_USER_INFORMATION_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 1500,
            title: "سەرکەوتوو بوو",
            text: "زانیاریەکە گۆڕدرا",
          });
        }

        if (status == 500) {
          dispatch({
            type: UPDATE_USER_INFORMATION_FAIL,
            payload: data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: SHOW_USER_FAIL, payload: [] });
        console.log(error)
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Your Request Is Faild",
        });
      });
  };
