import html2canvas from "html2canvas";

export const useScreenshot = (ref) => {
    
  
    const takeScreenshot = () => {
      // Swal.showLoading()
      if (ref.current) {
        html2canvas(ref.current)
          .then((canvas) => {
            const link = document.createElement("a");
            link.download = "screenshot.png";
            link.href = canvas.toDataURL();
            link.click();
            // Swal.hideLoading();
          })
          .catch((error) => {
            console.error("Error taking screenshot:", error);
          });
      }
    };
  
    return { ref, takeScreenshot };
  };