import React, { useEffect, useState } from "react";
import Logo from "../../Image/Logo.jpg";
import ViewModal from "../../Modal/ViewModal";
import RejectModal from "../../Modal/RejectModal";
import { useDispatch, useSelector } from "react-redux";
import { getCommiteAction } from "../../Action/commiteAction";
import { CiFilter } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { TbPlayerEject } from "react-icons/tb";
import { RxCross1 } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import Loading from "../Loading/Loading";
function Commite() {
  const dispatch = useDispatch();
  const getCommite = useSelector((state) => state.getCommite);
  const { commites, loading, total } = getCommite;
  const locationId = localStorage.getItem("locationId");
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    code: "",
    gender: "",
    phone: "",
    birthDate: "",
  });
  const { fullname, code, gender, phone, birthDate } = searchParams;
  const [page, setPage] = useState(0);
  const [select, setSelect] = useState(1);
  const [perPage, setPerPage] = useState(10);
  useEffect(() => {
    dispatch(
      getCommiteAction(
        locationId,
        page,
        perPage,
        fullname,
        code,
        gender,
        phone,
        birthDate
      )
    );
  }, [perPage]);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const thead = [
    {
      id: 1,
      value: "fullname",
      label: "Full Name",
    },
    {
      id: 2,
      value: "code",
      label: "Code",
    },
    {
      id: 3,
      value: "gender",
      label: "Gender",
    },
    {
      id: 4,
      value: "phone",
      label: "Phone",
    },
    {
      id: 5,
      value: "birthDate",
      label: "Birth Date",
    },
  ];
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [indexOfFilter, setIndexOfFilter] = useState(null);
  const handleSearch = () => {
    dispatch(
      getCommiteAction(
        locationId,
        page,
        perPage,
        fullname,
        code,
        gender,
        phone,
        birthDate
      )
    );
    setIsFilterOpen(false);
  };
  const handleClearSearch = (name) => {
    const updatedSearchParams = { ...searchParams };
    updatedSearchParams[name] = "";
    setSearchParams(updatedSearchParams);
    dispatch(
      getCommiteAction(
        locationId,
        page,
        perPage,
        updatedSearchParams.fullname,
        updatedSearchParams.code,
        updatedSearchParams.gender,
        updatedSearchParams.phone,
        updatedSearchParams.birthDate
      )
    );
    setIsFilterOpen(false);
  };

  const [patientId, setPatientId] = useState(null);
  const [commiteId, setCommiteId] = useState(null);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [color, setColor] = useState([]);
  useEffect(() => {
    const lastColors = [];
    if (!commites) {
      return;
    }
    commites.map((commite) => {
      if (commite.commites.length > 0) {
        lastColors.push(commite.commites[commite.commites.length - 1].color);
        setColor(lastColors);
      }
    });
  }, [commites]);
  return (
    <>
      {commites == undefined || loading ? (
        <Loading/>
      ) : (
        <div>
          <div className="h-[75vh] bg-white w-[95%]   m-auto">
            {/* {color} */}
            <div className="overflow-y-auto  w-full  h-[90%] ">
              <table className="w-full ">
                <thead className="sticky top-0">
                  <th className="global-th">#</th>

                  {thead.map((th, index) => {
                    return (
                      <React.Fragment key={index}>
                        <th className="global-th relative ">
                          <div className="flex justify-between items-center">
                            <p>{th.label}</p>
                            {/* <div className="bg-slate-900/30 backdrop-blur-3xl py-4 px-3"> */}
                            <p
                              className="text-xl filterIcon"
                              onClick={() => {
                                setIndexOfFilter(th.id);
                                setIsFilterOpen(!isFilterOpen);
                              }}
                            >
                              <CiFilter />
                            </p>
                            {/* </div> */}
                          </div>
                          {isFilterOpen && indexOfFilter == th.id && (
                            <div className="parent-filter  ">
                              <div>
                                {th.id == 3 ? (
                                  <select
                                    onChange={handleInputChange}
                                    type="text"
                                    className="inputUser w-full text-black"
                                    name={th.value}
                                    value={searchParams[th.value]}
                                    placeholder={th.label}
                                  >
                                    <option value="">Choose Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                ) : (
                                  <input
                                    onChange={handleInputChange}
                                    type="text"
                                    className="inputUser w-full text-black"
                                    name={th.value}
                                    value={searchParams[th.value]}
                                    placeholder={th.label}
                                  />
                                )}
                              </div>
                              <div className="flex gap-2 mt-2 ml-1">
                                <div>
                                  <p
                                    className="btn-primary"
                                    onClick={handleSearch}
                                  >
                                    <FaCheck />
                                  </p>
                                </div>
                                <div>
                                  <p
                                    className="btn-danger"
                                    onClick={() => handleClearSearch(th.value)}
                                  >
                                    <RxCross1 />
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </th>
                      </React.Fragment>
                    );
                  })}

                  <th className="global-th">Visits</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {commites.map((commite, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          color[index] == 2 ? "tableRowSave" : "tableRow"
                        }
                      >
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                          className="global-td"
                        >
                          {index + 1}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                        >
                          {commite.Pname}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                        >
                          {commite.code}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                        >
                          {commite.gender}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                        >
                          {commite.phone}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                        >
                          {commite.birthDate}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/tabsVisitCommite/" + commite.id)
                          }
                          className="text-center font-bold text-red-500 text-xl underline "
                        >
                          {commite.visits_with_reject_count}
                        </td>
                        <td>
                          <div className="flex gap-2 justify-center">
                            <button
                              className={"btn-primary text-lg"}
                              onClick={(e) => {
                                e.preventDefault();
                                setPatientId(commite.id);
                                setCommiteId(commite.commites[0].id);
                                setIsOpenViewModal(!isOpenViewModal);
                              }}
                            >
                              <AiFillEye />
                            </button>
                            <button
                              title="reject"
                              className="btn-danger py-1 text-lg"
                              onClick={() => {
                                setPatientId(commite.id);
                                setIsOpenRejectModal(!isOpenRejectModal);
                              }}
                            >
                              {" "}
                              <TbPlayerEject />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
              <div className="flex items-center gap-4 ">
                <p className="text-xl ">Total: </p>
                <div
                  className={
                    select == 1
                      ? "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                      : "bg-red-600 px-4 py-0.5 text-white rounded-md shadow-md"
                  }
                >
                  {total}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <select
                    name=""
                    className="select w-20"
                    onChange={(e) => setPerPage(e.target.value)}
                    value={perPage}
                    id=""
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="flex justify-center   mt-1">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel={">"}
                    // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                    breakLabel="..."
                    className="flex  items-center   -space-x-px h-8 text-sm"
                    previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                    nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                    pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                    breakClassName="w-8 py-2 text-center"
                    pageCount={Math.ceil(total / perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={page}
                    // activeClassName=''
                    onPageChange={(event) => {
                      let currentPage = event.selected + 1;
                      dispatch(
                        getCommiteAction(
                          locationId,
                          currentPage,
                          perPage,
                          fullname,
                          code,
                          gender,
                          phone,
                          birthDate
                        )
                      );
                      setPage(event.selected);
                    }}
                    activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenViewModal && (
        <ViewModal
          closeModal={setIsOpenViewModal}
          patientId={patientId}
          commiteId={commiteId}
        />
      )}

      {isOpenRejectModal && (
        <RejectModal
          closeModal={setIsOpenRejectModal}
          patientId={patientId}
          type={"commite"}
        />
      )}

      <div className="space-bottom"></div>
    </>
  );
}

export default Commite;
