import React, { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
// import { useDispatch } from "react-redux";
import { uploadImage } from "../../../Action/patient";
import { useLocation } from "react-router-dom";
import { FaPlus, FaTrash } from "react-icons/fa";
const ProfileVisits = ({ patients, patientId }) => {
  const [image, setImage] = useState([]);
  const location = useLocation();
  // const dispatch = useDispatch();
  const handleSave = () => {
    uploadImage(patientId, image, location);
  };
  const handleImageChange = (e, index) => {
    const newValue = [...image];
    newValue[index] = e.target.files[0];
    setImage(newValue);
  };

  const handleAdd = () => {
    setImage([...image, ""]);
  };
  const handleRemove = (index) => {
    const removeInput = [...image];
    removeInput.splice(index, 1);
    setImage(removeInput);
  };
  return (
    <React.Fragment>
      <div className="bg-white pb-2 rounded-md w-11/12   shadow-md mx-auto px-3">
        {patients.map((patient, index) => {
          return (
            <div>
              {patients.map((patient, index) => {
                return (
                  <div key={index} className=" ">
                    <div className="parentInputGrid ">
                      {/* end parent Input & label */}

                      <div className="w-full mt-2">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number"> *رەقەم مۆبایل</label>
                        </div>
                        <input
                          type="text"
                          name="closetHome"
                          readOnly
                          defaultValue={patient.phone}
                          id="number"
                          className="inputLogin w-full text-right"
                          placeholder="رەقەم مۆبایلرەقەم مۆبایل"
                        />
                      </div>
                      {/* end parent Input & label */}
                      <div className="w-full mt-2">
                        <div className="w-full  ">
                          <div className="relative w-full ">
                            <div className="child ">
                              <div className="w-full flex justify-end">
                                <label htmlFor="gender">*ڕەگەز </label>
                              </div>
                              <div className="coustmSelect ">
                                <p>
                                  {patient.gender == "" ||
                                  patient.gender == null
                                    ? ".. ڕەگەز "
                                    : patient.gender}
                                </p>
                                <div className={"parentCaretDown"}>
                                  <AiFillCaretDown />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end parent Input & label */}

                      <div className="w-full mt-2">
                        <div className="w-full flex justify-end">
                          <label htmlFor="name">* بەرواری لە دایکبوون</label>
                        </div>
                        <input
                          type="text"
                          name="card"
                          readOnly
                          value={patient.birthDate}
                          id="number"
                          className="inputLogin w-full text-right"
                          placeholder="بەرواری لە دایکبوون"
                        />
                      </div>
                      {/* end of label and inpt */}

                      <div className="w-full mt-2">
                        <div className="w-full flex justify-end">
                          <label htmlFor="name">*ناوی نەخۆش</label>
                        </div>
                        <input
                          type="text"
                          name="street"
                          readOnly
                          value={patient.Pname}
                          id="number"
                          className="inputLogin w-full text-right"
                          placeholder="ناوی نەخۆش"
                        />
                      </div>
                      {/* end parent Input & label */}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}

        <div className="w-full mt-2  flex justify-end">
          <button className="btn-success px-2" onClick={handleAdd} title="add">
            <FaPlus />
          </button>
        </div>
        {image.map((image, index) => {
          return (
            <div
              className=" py-2  flex gap-4 justify-center items-center"
              // key={index}
            >
              <label
                id="large_size"
                className="p-4 flex flex-col items-center gap-2 w-full bg-blue-50 text-blue-500 rounded-lg hover:bg-blue-100 cursor-pointer"
              >
                <p>
                  <BsFillCloudArrowUpFill className="w-6 h-6" />
                </p>

                <span>{image == null ? "" : image.name}</span>
                <input
                  onChange={(e) => handleImageChange(e, index)}
                  name={`image`}
                  id="large_size"
                  type="file"
                  className="hidden"
                />
              </label>

              <div className="">
                <button
                  className="btn-danger py-2"
                  onClick={() => handleRemove(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          );
        })}
        {image.length > 0 ? (
          <div className="ml-2  w-full">
            <button className="btn-success  " onClick={handleSave}>
              Save
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="pb-20"></div>
    </React.Fragment>
  );
};

export default ProfileVisits;
