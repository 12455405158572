import Swal from "sweetalert2";
import { api } from "../Api/api";
import { getToken } from "./login";
import {
  CREATE_COMMITE_FAIL,
  CREATE_COMMITE_REQUEST,
  CREATE_COMMITE_SUCCESS,
  FETCH_COMMITE_FAIL,
  FETCH_COMMITE_INFO_SUCCESS,
  FETCH_COMMITE_INFo_FAIL,
  FETCH_COMMITE_REQUEST,
  FETCH_COMMITE_SUCCESS,
  FETCH_COMMITE_WITH_VISIT_FAIL,
  FETCH_COMMITE_WITH_VISIT_REQUEST,
  FETCH_COMMITE_WITH_VISIT_SUCCESS,
} from "../ActionType/commiteType";
import { getPatient, updateVisitAction } from "./patient";

export const createCommite =
  (
    visit_id,
    PatientId,
    assign,
    occuption,
    nation,
    country,
    married,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    TYP,
    indiction,
    HEP,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    Doctor,
    ZODoctor,
    medicalProvided,
    Typemedicalsupplies,
    supplyLocation,
    helpingZO,
    reset,
    type_of_help,
    note,
    literate,
    had_surgery,
    updated_at,
    typeOfHospital,
    whyPrivate
  ) =>
  (dispatch) => {
    const arraySurgery = [];
    const doctorArray = [];

    for (let index = 0; index < Doctor.length; index++) {
      const elementSurg = Doctor[index].label;
      doctorArray.push(elementSurg);
    }
    for (let index = 0; index < SITD.length; index++) {
      const elementSurg = SITD[index].label;

      arraySurgery.push(elementSurg);
    }

    const memberArray = [];

    for (let index = 0; index < member.length; index++) {
      const elementSurg = member[index].label;
      memberArray.push(elementSurg);
    }
    const organLevel = [];
    for (let index = 0; index < memberLevel.length; index++) {
      const elementSurg = memberLevel[index].label;
      organLevel.push(elementSurg);
    }

    

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    Swal.showLoading();
    api
      .post(
        "commite/create",
        {
          visit_id: visit_id,
          PatientId: PatientId,
          // assign,
          occuption,
          nation,
          married,
          country,
          noFamily,
          province,
          district,
          street,
          closetHome,
          secoundHome,
          card,
          smoke,

          timeAllote,
          SITD: arraySurgery,
          member: memberArray,
          memberLevel: organLevel,
          TYP,
          indiction,
          HEP,
          finalDiagnosis,
          earlyPatient,
          earlySurgery,
          Doctor: doctorArray,
          ZODoctor,
          medicalProvided,
          Typemedicalsupplies,
          supplyLocation,
          helpingZO,
          type_of_help,
          note,
          literate,
          had_surgery,
          updated_at,
          type_of_hospital: typeOfHospital,
          why_private: whyPrivate,
        },
        config
      )
      .then((response) => {
        dispatch({ type: CREATE_COMMITE_REQUEST });
        console.log(response)
        if (response.data.status == 200) {
          updateAssignAction(assign, PatientId);
          if (assign == 2) {
            dispatch(updateVisitAction(visit_id));
          }

          dispatch(
            getPatient(
              localStorage.getItem("locationId"),
              1,
              10,
              "",
              "",
              "",
              "",
              "",
              1
            )
          );
          const suliCommite = response.data.suliCommite;
          dispatch({ type: CREATE_COMMITE_SUCCESS, payload: suliCommite });
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: assign == 1 ? "patient Was Saved" : "Patient Was Assigned",
            timer: 1500,
          });
        } else if (response.data.status == 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Pleas Fill all the gaps",
          });
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: CREATE_COMMITE_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getCommiteAction =
  (branch_id, page, perPage, fullname, code, gender, phone, birthDate) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",

        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        branch_id,
        page,
        perPage,
        fullname,
        phone,
        code,
        gender,
        birthDate,
      },
    };

    dispatch({
      type: FETCH_COMMITE_REQUEST,
    });

    // console.log("branch id : ",branch_id)
    try {
      const response = await api.get("commite", config);
      const { status, data } = response;
      // console.log(birthDate);
      if (status == 200) {
        dispatch({
          type: FETCH_COMMITE_SUCCESS,
          payload: data.data,
          total: data.total,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_COMMITE_FAIL,
        payload: [],
        error: error.message,
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Check Your Request",
      });
    }
  };
// Commite Page

// view Modal Page
export const getInfoCommite = (location_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`commite/getInformationPatient/${location_id}`, config)
    .then((response) => {
      console.log(response);

      const jsonData = response.data.data[0].SITD;

      const decodedData = JSON.parse(jsonData);
      if (Array.isArray(decodedData) && decodedData.length > 0) {
        console.log(decodedData[0]);
      }
      if (response.data.status == 200) {
        const { data } = response.data;
        dispatch({ type: FETCH_COMMITE_INFO_SUCCESS, payload: data });
      } else if (response.data.status === 404) {
        console.log(response.data);
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_COMMITE_INFo_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const updateAssignAction = (assign, patientId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(
      "patient/updateAssign",
      {
        assign,
        patientId,
      },
      config
    )
    .then((response) => {})
    .catch((err) => {});
};

export const getCommiteWithVisitAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get(`commite/show/${id}`, config)
    .then((response) => {
      dispatch({ type: FETCH_COMMITE_WITH_VISIT_REQUEST });
      if (response.status == 200) {
        const { data } = response;

        dispatch({ type: FETCH_COMMITE_WITH_VISIT_SUCCESS, payload: data });
      } else if (response.status === 404) {
        // console.log("hi");
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_COMMITE_WITH_VISIT_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const updateColorCommiteAction =
  (commiteId, surgery,typeOfHelp,date) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    try {
      const response = await api.put(
        `commite/updateColor/${commiteId}`,
        { surgery,typeOfHelp,date },
        config
      );
    } catch (error) {
      console.log(" error : ", error);
    }
  };

export const updateCommiteAction =
  (
    commiteId,
    visit_id,
    patientId,
    assign,
    occupation,
    nation,
    country,
    married,
    NoFamily,
    province,
    district,
    street,
    closetHome,
    scoundPhoneNo,
    card,
    smoke,
    timeAllot,
    SPID,
    member,
    noMember,
    typ,
    indiction,
    HPE,
    finalDiagnos,
    earlyPatient,
    earlySurgery,
    doctor,
    ZODoctor,
    medicalProvid,
    typeMedical,
    supplyLocation,
    helpingZO,

    type_of_help,
    note,
    literate,
    had_surgery,
    updated_at,
    color,
    typeOfHospital,
    whyPrivate
  ) =>
  async (dispatch) => {
    let typeArray = [];
    type_of_help.map((type) => {
      
      typeArray.push(type.label);
    });
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    try {
      const response = await api.put(
        `commite/update/${commiteId}`,
        {
          color: 2,
          visit_id: visit_id,
          occuption: occupation,
          nation: nation,
          country: country,
          married: married,
          noFamily: NoFamily,
          province: province,

          //
          district: district,
          street: street,
          closetHome: closetHome,
          secoundHome: scoundPhoneNo,
          card: card,
          timeAllote: timeAllot,
          SITD: SPID,
          member: member,
          memberLevel: noMember,
          TYP: typ,
          indiction: indiction,
          HEP: HPE,
          finalDiagnosis: finalDiagnos,
          earlySurgery: earlySurgery,
          Doctor: doctor,
          ZODoctor: ZODoctor,
          medicalProvided: medicalProvid,
          Typemedicalsupplies: typeMedical,
          supplyLocation: supplyLocation,
          helpingZO: helpingZO,
          type_of_help: typeArray,
          smoke,
          earlyPatient,
          literate: literate,
          had_surgery: had_surgery,
          type_of_hospital: typeOfHospital,
          why_private: whyPrivate,
          note: note,
        },
        config
      );
      console.log("upadte", response);
    } catch (error) {
      console.log(" error commite : ", error);
    }
  };
