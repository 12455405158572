import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ImCross } from "react-icons/im";
import {
  createDoctorAction,
  // getDoctorAction,
} from "../../Action/Doctor/doctorAction";
const DoctorModal = ({ setIsOpenDoctorModal }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    name: "",
    expert: "",
  });
  function handleInputChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createDoctorAction(inputValue.name, inputValue.expert));
    setIsOpenDoctorModal(false);
  };

  const createDoctor = useSelector((state) => state.createDoctor);
  const { loading, error } = createDoctor;

  return (
    <div className="openModal">
      <div className="containerModal w-5/12 p-0 rounded-sm">
        <div className="head-modal mt-2 p-3">
          <p> </p>
          <div>
            <p
              className="close-icon text-xl"
              onClick={() => setIsOpenDoctorModal(false)}
            >
              <ImCross />
            </p>
          </div>
        </div>
        <form action="" className="px-4">
          <div className="parent-label">
            <label htmlFor="doctor">Doctor</label>
            <div>
              <input
                type="text"
                className="inputUser"
                placeholder="Doctor"
                name="name"
                id="doctor"
                onChange={handleInputChange}
                value={inputValue.name}
              />
            </div>
            <p className="text-red-500">{error.dr_name}</p>
          </div>

          <div className="parent-label mt-2">
            <label htmlFor="exp" id="exp">
              Expert
            </label>
            <div>
              <input
                type="text"
                className="inputUser"
                placeholder="Expert"
                name="expert"
                id="exp"
                onChange={handleInputChange}
                value={inputValue.expert}
              />
              <p className="text-red-500">{error.expert}</p>
            </div>
          </div>
          
          <div className="footer-modal">
            <button className="btn-danger rounded-sm px-4">Close</button>
            <button
              className="btn-success rounded-sm px-4"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DoctorModal;
