import React, { useEffect, useRef, useState } from "react";
import "../Pages/user_profile/css/add_user.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Image/Logo.jpg";
import { MdAssignmentTurnedIn, MdOutlineSaveAlt } from "react-icons/md";
import { RiScreenshot2Fill } from "react-icons/ri";
import {
  getSurgeryAction,
  getTypeSurgeryAction,
} from "../Action/Surgery/surgeryAction";
import {
  editPatientAction,
  getPatient,
  updatePatientAction,
} from "../Action/patient";

import FeedbackPrint from "../Print/FeedbackPrint";
import { IoMdPrint } from "react-icons/io";

import { getDoctorAction } from "../Action/Doctor/doctorAction";
import { getMedicalSupliesTypesAction } from "../Action/MedicalSupliesTypesAction/MedicalSupliesTypesAction";
import { getOrganLevelAction } from "../Action/SurgeryTypeAction/surgery";
import { useScreenshot } from "../Action/ScreenShot/ScreenShoot";
import ReactToPrint from "react-to-print";
const EditModalPatient = ({ closeModal, patientId }) => {
  const [inputValue, setInputValue] = useState({
    visit_id: "",
    fullname: "",
    phone: "",
    gender: "",
    birthDate: "",
    assign: "",
    commiteId: "",
    occupation: "",
    nation: "",
    country: "",
    NoFamily: "",
    province: "",
    district: "",
    street: "",
    closetHome: "",
    secoundPhoneNo: "",
    card: "",
    timeAllot: "",
    SPID: "",
    member: "",
    noMember: "",
    typ: "",
    indiction: "",
    HPE: "",
    finalDiagnos: "",
    earlyPatient: "",
    earlySurgery: "",
    doctor: "",
    ZODoctor: "",
    medicalProvid: "",
    typeMedical: "",
    supplyLocation: "",
    helpingZO: "",
    note: "",
    literate: "",
    hadSurgery: "",
    updated_at: "",
    typeOfHospital: "",
    whyPrivate: "",
    smoke: "",
    married: "",
    typeOfDiseas:"",
  });
  const dispatch = useDispatch();
  const locationID = localStorage.getItem("locationId");
  const [visitId, setVisitId] = useState(null);
  const getEditPatient = useSelector((state) => state.editPatient);
  const getSurgery = useSelector((state) => state.getSurgery);
  const getTypeSurgery = useSelector((state) => state.getTypeSurgery);
  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const getDoctor = useSelector((state) => state.getDoctor);
  const getMedicalSupliesTypes = useSelector(
    (state) => state.getMedicalSupliesTypes
  );
  const { patients, loading } = getEditPatient;
  const { surgery } = getSurgery;
  const { organLevel } = getOrganLevel;
  const { typeSurgery } = getTypeSurgery;
  const { doctors } = getDoctor;

  const { typeMedicals } = getMedicalSupliesTypes;

  const optionTypeMedical = [];
  let memberArray = [];
  const organOptions = [];
  let TypeOptionArray = [];
  const doctorsOptions = [];
  doctors?.map((doctor) => {
    doctorsOptions.push({
      value: doctor.dr_name,
      label: doctor.dr_name,
    });
  });
  typeSurgery?.forEach((element) => {
    memberArray.push({
      value: `${element.id}`,
      label: `${element.typeName}`,
    });
  });
  typeSurgery.forEach((element) => {
    TypeOptionArray.push({
      value: `${element.type_id}`,
      label: `${element.type_name_en}`,
    });
  });

  organLevel?.map((organ) => {
    organOptions.push({
      label: organ.organ_level_name,
      value: organ.organ_level_name,
    });
  });
  const [selectSurgeryId, setSelectSurgeryId] = useState([]);
  const [selectOrganId, setSelectOrganId] = useState([]);

  const handleSelectSurgeryId = (selectedOptions) => {
    const newSurgeryIds = selectedOptions.map((option) => option.value);

    setSelectSurgeryId(newSurgeryIds);
    setValueSITD(selectedOptions);
  };

  const handleSelectOrganId = (selectedOptions) => {
    const newSurgeryIds = selectedOptions.map((option) => option.value);
    setValueOrgan(selectedOptions);
    setSelectOrganId(newSurgeryIds);
  };

  const handleSelectOrganLevel = (selectOptions) => {
    setValueOrganLevel(selectOptions);
  };

  const handleSelectTypeOfHelp = (selectOptions) => {
    const vlaueTypeHelp = selectOptions.map((select) => select.label);

    setValueTypeOfHelp(selectOptions);
    setShowTypeOfHelp(vlaueTypeHelp);
  };

  const handleSelectDoctor = (selectOptions) => {
    setValueDoctor(selectOptions);
  };
  const handelSelectTYP = (selectOptions) => {
    setValueTYP(selectOptions);
  };
  useEffect(() => {
    if (selectSurgeryId == null || selectSurgeryId.length <= 0) {
      return;
    } else {
      dispatch(getTypeSurgeryAction(selectSurgeryId));
    }
  }, [selectSurgeryId]);

  useEffect(() => {
    if (selectOrganId == null || selectOrganId.length == 0) {
      return;
    } else {
      dispatch(getOrganLevelAction(selectOrganId));
    }
  }, [selectOrganId]);

  typeMedicals?.map((type) => {
    optionTypeMedical.push({
      value: type?.name,
      label: type?.name,
    });
  });

  const optionTypeSurgery = [];
  surgery?.map((type) => {
    optionTypeSurgery.push({
      value: type.id,
      label: type.surguryName,
    });
  });

  useEffect(() => {
    dispatch(editPatientAction(patientId, visitId));
  }, [visitId]);
  useEffect(() => {
    dispatch(getMedicalSupliesTypesAction());
    dispatch(getSurgeryAction());
    dispatch(getDoctorAction("", "", "Active"));
  }, []);
  const [valueSITD, setValueSITD] = useState([]);
  const [valueOrgan, setValueOrgan] = useState([]);
  const [valueOrganLevel, setValueOrganLevel] = useState([]);
  const [valueTYP, setValueTYP] = useState([]);
  const [valueDoctor, setValueDoctor] = useState([]);
  const [valueTypeOfHelp, setValueTypeOfHelp] = useState([]);
  let [showTypeOfHelp, setShowTypeOfHelp] = useState([]);
  useEffect(() => {
    console.log("patients : ", patients);
    setValueSITD([]);
    setValueOrgan([]);
    setValueOrganLevel([]);
    setValueTYP([]);
    setValueDoctor([]);
    setValueTypeOfHelp([]);

    if (
      visitId == null ||
      visitId == "" ||
      visitId == -1 ||
      patients == undefined
    ) {
      return;
    }

    patients?.forEach((patient) => {
      patient?.commites
        ?.filter((filter) => filter.visit_id == visitId)
        .map((element) => {
          const tempSITD = element.SITD.map((sitd) => sitd);
          const tempMember = element.member.map((member) => member);
          const tempMemberLevel = element.memberLevel.map(
            (memberLevel) => memberLevel
          );
          const tempTYP = element.TYP.map((typ) => typ);
          const tempDoctor = element.Doctor.map((doctor) => doctor);

          const typeOfHelp = element.type_of_help.map(
            (type_of_help) => type_of_help
          );

          let SITD = [];
          tempSITD.map((value) => {
            SITD.push({
              label: value,
              value: value,
            });
          });

          let member = [];
          tempMember.map((value) => {
            member.push({
              label: value,
              value: value,
            });
          });

          let memberLevel = [];
          tempMemberLevel.map((value) => {
            memberLevel.push({
              label: value,
              value: value,
            });
          });

          let TYP = [];
          tempTYP.map((value) => {
            TYP.push({
              label: value,
              value: value,
            });
          });

          let typeHelp = [];
          typeOfHelp.map((value) => {
            typeHelp.push({
              label: value,
              value: value,
            });
          });

          let doctor = [];
          tempDoctor.map((value) => {
            doctor.push({
              label: value,
              value: value,
            });
          });
          setValueTypeOfHelp(typeHelp);
          setShowTypeOfHelp(typeOfHelp);
          setValueDoctor(doctor);
          setValueSITD(SITD);
          setValueOrgan(member);
          setValueOrganLevel(memberLevel);
          setValueTYP(TYP);
          // console.log(object)
          setInputValue({
            visit_id: element.visit_id,
            assign: element.assign,
            commiteId: element.id,
            occupation: element.occuption,
            nation: element.nation,
            country: element.country,
            NoFamily: element.noFamily,
            province: element.province,
            district: element.district,
            street: element.street,
            closetHome: element.closetHome,
            secoundPhoneNo: element.secoundHome,
            card: element.card,
            timeAllot: element.timeAllote,
            SPID: element.SITD,
            member: element.member,
            noMember: element.memberLevel,
            typ: element.TYP,
            indiction: element.indiction,
            HPE: element.HEP,

            finalDiagnos: element.finalDiagnosis,
            earlyPatient: element.earlyPatient,
            earlySurgery: element.earlySurgery,
            doctor: element.Doctor,
            ZODoctor: element.ZODoctor,
            medicalProvid: element.medicalProvided,
            typeMedical: element.Typemedicalsupplies,
            supplyLocation: element.supplyLocation,
            helpingZO: element.helpingZO,
            note: element.note,
            literate: element.literate,
            hadSurgery: element.had_surgery,

            typeOfHospital: element.type_of_hospital,
            whyPrivate: element.why_private,
            smoke: element.smoke,
            married: element.married,

            updated_at: element.updated_at
              ? new Date(element.updated_at).toISOString().slice(0, 16)
              : "",
          });
        });
    });
  }, [visitId, patients]);

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleSubmit = (event, assign) => {
    event.preventDefault();

    const {
      commiteId,
      occupation,
      nation,
      country,
      NoFamily,
      province,
      district,
      street,
      closetHome,
      secoundPhoneNo,
      card,
      timeAllot,
      SPID,
      member,
      noMember,
      typ,
      HPE,
      finalDiagnos,
      earlyPatient,
      earlySurgery,
      // doctor,
      ZODoctor,
      medicalProvid,
      typeMedical,
      supplyLocation,
      helpingZO,
      indiction,
      visit_id,
      updated_at,
      typeOfHospital,
      whyPrivate,
    } = inputValue;

    dispatch(
      updatePatientAction(
        visit_id,
        commiteId,
        patientId,
        assign,
        occupation,
        nation,
        country,
        inputValue.married,
        NoFamily,
        province,
        district,
        street,
        closetHome,
        secoundPhoneNo,
        card,
        inputValue.smoke,
        timeAllot,
        valueSITD,
        valueOrgan,
        valueOrganLevel,
        valueTYP,
        indiction,
        HPE,
        finalDiagnos,
        earlyPatient,
        earlySurgery,
        valueDoctor,
        ZODoctor,
        medicalProvid,
        typeMedical,
        supplyLocation,
        helpingZO,

        valueTypeOfHelp,
        inputValue.note,
        inputValue.literate,
        inputValue.hadSurgery,
        updated_at,
        2,
        typeOfHospital,
        whyPrivate
      )
    );

    dispatch(editPatientAction(patientId, visitId));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    closeModal(false);

    // }
  };
  const currentref = useRef(null);
  const componentRef = useRef(null);
  const { ref, takeScreenshot } = useScreenshot(currentref);
  return (
    <>
      {loading || patients == undefined ? (
        <div className="flex flex-col absolute top-20 left-[50%] -translate-x-[50%]">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="openModal">
          <div className="containerModal">
            <div className="head-modal">
              <div
                onClick={() => {
                  closeModal(false);

                  setValueSITD([]);
                  setValueOrgan([]);
                  setValueOrganLevel([]);
                  setValueTYP([]);
                  setValueDoctor([]);
                  setValueTypeOfHelp([]);
                }}
                className="text-red-400 cursor-pointer"
              >
                <p>X</p>
              </div>
              <div className="w-4/12 lg:w-2/12">
                <select
                  name=""
                  onChange={(e) => setVisitId(e.target.value)}
                  value={visitId}
                  id=""
                  className="select w-full "
                >
                  <option value="" selected>
                    تکایە زانیاریەک هەڵبژێرە
                  </option>
                  {patients?.map((patient, index) => {
                    return patient?.visit?.map((visit, index) => {
                      return (
                        <option value={visit.id} key={index}>
                          {visit?.created_at?.toString().slice(0, 10)}{" "}
                        </option>
                      );
                    });
                  })}
                </select>
              </div>
            </div>
            {visitId == null || visitId == "" ? (
              ""
            ) : (
              <form action="" className="px-4" ref={ref}>
                <div className=" bg-blue-500 mt-2 m-auto lg:w-2/12 w-6/12 py-2 rounded-sm shadow-md ">
                  <p className="text-center text-gray-100 text-lg">
                    فۆرمی زانیاری نەخۆش
                  </p>
                </div>
                <div className="parentInputGrid">
                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="پارێزگا">* پارێزگا</label>
                    </div>

                    <input
                      type="text"
                      id="پارێزگا"
                      className="inputUser w-full text-right"
                      placeholder="پارێزگا"
                      name="province"
                      onChange={handleInputChange}
                      value={inputValue.province}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="نەتەوە">* نەتەوە</label>
                    </div>

                    <input
                      type="text"
                      id="نەتەوە"
                      className="inputUser w-full text-right"
                      placeholder="نەتەوە"
                      name="nation"
                      onChange={handleInputChange}
                      value={inputValue.nation}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="وڵات">* وڵات</label>
                    </div>

                    <input
                      type="text"
                      id="وڵات"
                      className="inputUser w-full text-right"
                      placeholder="وڵات"
                      name="country"
                      onChange={handleInputChange}
                      value={inputValue.country}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="پیشە">* پیشە</label>
                    </div>

                    <input
                      type="text"
                      id="پیشە"
                      className="inputUser w-full text-right"
                      placeholder="پیشە"
                      name="occupation"
                      onChange={handleInputChange}
                      value={inputValue.occupation}
                    />
                  </div>

                  {/* end of 4 input */}

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="خێزاندارە">* خێزاندارە</label>
                    </div>
                    <select
                      name="married"
                      onChange={handleInputChange}
                      value={inputValue.married}
                      className="inputUser w-full"
                      id=""
                    >
                      <option value="" selected>
                        تکایە زانیاریەکە هەڵبژێرە
                      </option>
                      <option value="خێزاندار">خێزاندار</option>
                      <option value="زوگرت">زوگرت</option>
                      <option value="جیابۆتەوە">جیابۆتەوە</option>
                      <option value="بێوەژن">بێوەژن</option>
                      <option value="منداڵ">منداڵ</option>
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex w-full justify-end">
                      <label htmlFor="شەقام/گەڕەک">* شەقام/گەڕەک</label>
                    </div>

                    <input
                      type="text"
                      id="شەقام/گەڕەک"
                      className="inputUser w-full text-right"
                      placeholder="شەقام/گەڕەک"
                      onChange={handleInputChange}
                      name="street"
                      value={inputValue.street}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="نزیکترین شوێن لە ماڵیانەوە">
                        * نزیکترین شوێن لە ماڵیانەوە
                      </label>
                    </div>

                    <input
                      type="text"
                      id="نزیکترین شوێن لە ماڵیانەوە"
                      className="inputUser w-full text-right"
                      placeholder="نزیکترین شوێن لە ماڵیانەوە"
                      onChange={handleInputChange}
                      value={inputValue.closetHome}
                      name="closetHome"
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="ناحیە/قەزا">* ناحیە/قەزا</label>
                    </div>

                    <input
                      type="text"
                      id="ناحیە/قەزا"
                      className="inputUser w-full text-right"
                      placeholder="ناحیە/قەزا"
                      onChange={handleInputChange}
                      value={inputValue.district}
                      name="district"
                    />
                  </div>
                  {/* end of 4 input */}

                  <div className="flex flex-col gap-1">
                    <div className="flex w-full justify-end">
                      <label htmlFor="smoke">* جگەرە کێشە</label>
                    </div>

                    <select
                      name="smoke"
                      onChange={handleInputChange}
                      value={inputValue.smoke}
                      className="select w-full"
                      id="smoke"
                    >
                      <option value="">تکایە زانیاریەکە هەڵنبژێرە</option>
                      {["بەڵێ", "نەخێر"].map((smoke, index) => {
                        return (
                          <option value={smoke} key={index}>
                            {smoke}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="ژ.مۆبایلی دووەم">* ژ.مۆبایلی دووەم</label>
                    </div>

                    <input
                      type="text"
                      id="ژ.مۆبایلی دووەم"
                      className="inputUser w-full text-right"
                      placeholder="ژ.مۆبایلی دووەم"
                      value={inputValue.secoundPhoneNo}
                      onChange={handleInputChange}
                      name="secoundPhoneNo"
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="ژمارەی کارتی نیشتمانی یاخود ناسنامە">
                        * ژمارەی کارتی نیشتمانی یاخود ناسنامە
                      </label>
                    </div>

                    <input
                      type="text"
                      id="ژمارەی کارتی نیشتمانی یاخود ناسنامە"
                      className="inputUser w-full text-right"
                      placeholder="ژمارەی کارتی نیشتمانی یاخود ناسنامە"
                      value={inputValue.card}
                      onChange={handleInputChange}
                      name="card"
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                      <label htmlFor="ژمارەی خێزان">* ژمارەی خێزان</label>
                    </div>
                    <select
                      name="NoFamily"
                      className="inputUser w-full"
                      id="ژمارەی خێزان"
                      value={inputValue.NoFamily}
                      onChange={handleInputChange}
                    >
                      <option value="" selected>
                        تکایە زانیاریەکە هەڵبژێرە
                      </option>
                      {[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                      ].map((family, index) => {
                        return (
                          <option value={family} key={index}>
                            {family}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* end of 4 input */}
                  <div></div>
                  <div></div>
                  <div className="flex flex-col gap-1">
                    <div className="flex w-full justify-end">
                      <label htmlFor="xwendawara">* خوێندەوارە</label>
                    </div>

                    <select
                      name="literate"
                      value={inputValue.literate}
                      onChange={handleInputChange}
                      className="select w-full"
                      id="xwendawara"
                    >
                      <option value="">تکایە زانیاریەکە هەڵنبژێرە</option>
                      {[
                        "سەرەتای",
                        "ناوەندی",
                        "دواناوەندی",
                        "زانکۆ",
                        "پەیمانگا",
                        "نەخوێندەوار",
                      ].map((litrate, index) => {
                        return (
                          <option value={litrate} key={index}>
                            {litrate}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex w-full justify-end">
                      <label htmlFor="time">
                        * کاتی دیاریکراو بۆ هەڵسەنگاندن
                      </label>
                    </div>

                    <select
                      name="timeAllot"
                      onChange={handleInputChange}
                      value={inputValue.timeAllot}
                      className="select w-full"
                      id="time"
                    >
                      <option value="">تکایە زانیاریەکە هەڵنبژێرە</option>
                      {[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                      ].map((time, index) => {
                        return (
                          <option value={time} key={index}>
                            {time}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* end of 4 input */}
                </div>
                {/* end first form */}
                <div className=" bg-teal-500 mt-2 m-auto lg:w-2/12 w-6/12 py-2 rounded-sm shadow-md ">
                  <p className="text-center text-gray-100 text-lg">
                    زانیاری پزیشکی
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex justify-end w-full">
                    <label htmlFor="typeOfHelp">* جۆری هاوکاری </label>
                  </div>
                  <div className="w-full mt-2">
                    <Select
                      id="typeOfHelp"
                      isRtl
                      placeholder=".... Choose "
                      isMulti
                      value={valueTypeOfHelp}
                      onChange={handleSelectTypeOfHelp}
                      options={optionTypeMedical}
                    />
                  </div>
                </div>
                {/* soho : {showTypeOfHelp} */}
                {showTypeOfHelp?.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      {value == "نەشتەرگەری" ? (
                        <div className="parentInputGrid">
                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="typ">TYP *</label>
                            </div>
                            <Select
                              isMulti
                              onChange={handelSelectTYP}
                              options={TypeOptionArray}
                              value={valueTYP}
                              id="typ"
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="lasar">
                                * (جۆری نەشتەرگەری) لەسەر ئاستی ئەندام{" "}
                              </label>
                            </div>
                            <Select
                              id="lasar"
                              isMulti
                              onChange={handleSelectOrganLevel}
                              options={organOptions}
                              value={valueOrganLevel}
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="andam">
                                * (ئەندامی نەشتەرگەری لەسەرکراو) ئەندام{" "}
                              </label>
                            </div>
                            <Select
                              id="andam"
                              isMulti
                              onChange={handleSelectOrganId}
                              options={memberArray}
                              value={valueOrgan}
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="spid">* پسپۆری جۆری نەخۆشی</label>
                            </div>
                            <Select
                              isMulti
                              id="spid"
                              value={valueSITD}
                              onChange={handleSelectSurgeryId}
                              options={optionTypeSurgery}
                            />
                          </div>

                          {/* end for item */}

                          <div className="flex flex-col gap-1">
                            <div className="">
                              <label htmlFor="typeOfDiseas">
                                {" "}
                                Type Of Diseas *
                              </label>
                            </div>
                            <input
                              type="text"
                              id="typeOfDiseas"
                              placeholder="Type Of Diseas"
                              className="w-full inputUser"
                              value={inputValue.typeOfDiseas}
                              name="typeOfDiseas"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="">
                              <label htmlFor="indication"> Indication *</label>
                            </div>
                            <input
                              type="text"
                              id="indication"
                              placeholder="Indication"
                              className="w-full inputUser"
                              value={inputValue.indiction}
                              name="indiction"
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="">
                              <label htmlFor="final">Final Diagnosis * </label>
                            </div>
                            <input
                              type="text"
                              id="final"
                              placeholder="Final Diagnosis"
                              className="w-full inputUser"
                              value={inputValue.finalDiagnos}
                              name="finalDiagnos"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="">
                              <label htmlFor="hpe"> H.P.E * </label>
                            </div>
                            <input
                              type="text"
                              id="hpe"
                              placeholder="H.P.E"
                              className="w-full inputUser"
                              value={inputValue.HPE}
                              onChange={handleInputChange}
                              name="HPE"
                            />
                          </div>
                          {/* end */}
                          {inputValue.typeOfHospital == "تایبەت" ? (
                            <div className="flex flex-col gap-1">
                              <div className="flex justify-end">
                                <label htmlFor="بۆچی">* بۆچی </label>
                              </div>
                              <textarea
                                name="whyPrivate"
                                className="inputUser w-full text-right"
                                id="بۆچی"
                                placeholder="بۆچی"
                                value={inputValue.whyPrivate}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="جۆری نەخۆشخانە">
                                * جۆری نەخۆشخانە{" "}
                              </label>
                            </div>
                            <select
                              name="typeOfHospital"
                              onChange={handleInputChange}
                              value={inputValue.typeOfHospital}
                              className="select w-full"
                              id=""
                            >
                              <option value="" selected>
                                تکایە زانیاریەکە هەڵبژێرە
                              </option>
                              <option value="حکومی">حکومی</option>
                              <option value="تایبەت">تایبەت</option>
                            </select>
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="earlySurg">
                                * نەشتەرگەری پێشوەخت{" "}
                              </label>
                            </div>
                            <input
                              type="text"
                              id="earlySurg"
                              placeholder="نەشتەرگەری پێشوەخت"
                              className="w-full inputUser text-right"
                              value={inputValue.earlySurgery}
                              onChange={handleInputChange}
                              name="earlySurgery"
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="early">* نەخۆشی پێشوەخت </label>
                            </div>
                            <input
                              type="text"
                              id="early"
                              placeholder="نەخۆشی پێشوەخت"
                              className="w-full inputUser text-right"
                              value={inputValue.earlyPatient}
                              name="earlyPatient"
                              onChange={handleInputChange}
                            />
                          </div>
                          {/* end another 4 item */}

                          {/* end */}
                          <div></div>
                          <div></div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="help">
                                {" "}
                                *پزیشکی هاوکاری ڕیکخراوی زادە{" "}
                              </label>
                            </div>
                            <select
                              name="ZODoctor"
                              onChange={handleInputChange}
                              value={inputValue.ZODoctor}
                              id="help"
                            >
                              <option value="">
                                تکایە زانیاریەکە هەڵبژێرە
                              </option>
                              <option value="بەڵێ">بەڵێ</option>
                              <option value="نەخێر">نەخێر</option>
                            </select>
                          </div>

                          <div className="flex flex-col gap-1">
                            <div className="flex justify-end">
                              <label htmlFor="doctor">
                                * پزیشکی نێردراو بۆ{" "}
                              </label>
                            </div>
                            <Select
                              id="doctor"
                              isMulti
                              isClearable
                              value={valueDoctor}
                              options={doctorsOptions}
                              onChange={handleSelectDoctor}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
                {showTypeOfHelp?.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      {value == "پێداویستی پزیشکی" ? (
                        <div className="parentInputGrid">
                          <div></div>
                          <div className="w-full mt-2">
                            <div className="flex justify-end ">
                              <label
                                htmlFor="typeMedical"
                                className="lblDesign"
                              >
                                * بۆ چی
                              </label>
                            </div>
                            <input
                              type="text"
                              name="typeMedical"
                              onChange={handleInputChange}
                              defaultValue={inputValue.typeMedical}
                              id="typeMedical"
                              className="inputUser text-right w-full "
                              placeholder="بۆ چی"
                            />
                          </div>
                          {/* end parent Input & label */}
                          <div className="w-full mt-2">
                            <div className="flex justify-end ">
                              <label
                                htmlFor="supplyLocation"
                                className="lblDesign"
                              >
                                * شوێنی دابینکردنی پێداویستی
                              </label>
                            </div>
                            <input
                              type="text"
                              name="supplyLocation"
                              onChange={handleInputChange}
                              value={inputValue.supplyLocation}
                              id="supplyLocation"
                              className="inputUser text-right w-full"
                              placeholder="شوێنی دابینکردنی پێداویستی"
                            />
                          </div>
                          <div className="w-full mt-2">
                            <div className="flex justify-end ">
                              <label
                                htmlFor="medicalProvid"
                                className="lblDesign"
                              >
                                * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                              </label>
                            </div>

                            <select
                              name="medicalProvid"
                              id="medicalProvid"
                              value={inputValue.medicalProvid || ""}
                              className=" inputUser w-full bg-white "
                              onChange={handleInputChange}
                            >
                              <option value="">Select ..</option>
                              <option value="بەڵێ">بەڵێ</option>
                              <option value="نەخێر">نەخێر</option>
                            </select>
                          </div>
                          {/* end parent Input & label */}
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
                <textarea
                  name="note"
                  value={inputValue.note}
                  onChange={handleInputChange}
                  className="text-area"
                  id=""
                  placeholder=".... تێبینی"
                ></textarea>
                <div className="flex justify-between w-full">
                  <div className="flex gap-2 w-full ">
                    <button
                      className="btn-success"
                      onClick={(e) => handleSubmit(e, 1)}
                    >
                      <div className="flex gap-2 items-center">
                        <p>Save</p>
                        <p>
                          <MdOutlineSaveAlt />
                        </p>
                      </div>
                    </button>
                    <button
                      className="btn-primary"
                      onClick={(e) => handleSubmit(e, 2)}
                    >
                      <div className="flex gap-2 items-center">
                        <p>Assign</p>
                        <p>
                          <MdAssignmentTurnedIn />
                        </p>
                      </div>
                    </button>

                    <button
                      className="btn-plus"
                      onClick={(e) => {
                        e.preventDefault();
                        takeScreenshot();
                      }}
                    >
                      <div className="flex gap-2 items-center">
                        <p className="capitalize">screen shoot</p>
                        <p>
                          <RiScreenshot2Fill />
                        </p>
                      </div>
                    </button>
                  </div>

                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <div className="btn-info">
                          <div className="flex gap-2 items-center">
                            <p>Print</p>
                            <p>
                              <IoMdPrint />
                            </p>
                          </div>
                        </div>
                      )}
                      documentTitle="Print"
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>

          <div ref={componentRef} className="table-print">
            <FeedbackPrint patients={patients} />
          </div>
          <div className="space-bottom"></div>
        </div>
      )}
    </>
  );
};

export default EditModalPatient;
