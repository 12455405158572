import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import "./css/staticstic.css";
import Logo from "../../Image/Logo.jpg";
import {
  PiDotsThreeOutlineBold,
  PiMicrosoftExcelLogoDuotone,
} from "react-icons/pi";
import { FaFilter } from "react-icons/fa";
import { getStatisticsAction } from "../../Action/StatisticsAction/StatisticsAction";
import {
  getAllTypeSurgeryAction,
  getOrganLevelAction,
} from "../../Action/SurgeryTypeAction/surgery";
import { getSurgeryAction } from "../../Action/Surgery/surgeryAction";
import { getDoctorAction } from "../../Action/Doctor/doctorAction";
import { MdFilterAltOff } from "react-icons/md";
import { api } from "../../Api/api";
import { getToken } from "../../Action/login";

const Statistics = () => {
  const [fromUpdate, setFromUpdate] = useState(null);
  const [toUpdate, setToUpdate] = useState(null);
  const [count, setCount] = useState(null);
  const [reject, setReject] = useState(null);
  const getStatistics = useSelector((state) => state.getStatistics);
  const dispatch = useDispatch();
  const { data, loading, total, totalVisit } = getStatistics;
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectAll, setSelectAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  
  const listData = [];
  const getSurgery = useSelector((state) => state.getSurgery);
  const { surgery } = getSurgery;
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;

  const getAllTypeSurgery = useSelector((state) => state.getAllTypeSurgery);
  const { typeSurgery } = getAllTypeSurgery;

  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;
  const [dateTime, setDateTme] = useState({
    from: "",
    to: "",
  });

  const [medicalProvidedSearch, setMedicalProvidedSearch] = useState(null);
  const regex = /^(\d{4})(\d{3})(\d{4})$/;

  const [isOpenColumns, setIsopenColumns] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [hadSurgery, setHadSurgery] = useState(null);
  const handleReset = () => {
    // Create a new object with all state variables set to null
    const resetSearchparams = Object.fromEntries(
      Object.keys(filterValues).map((key) => [key, null])
    );

    // Update the state with the new object
    // setDateTime(resetDateTime);
    setFilterValues(resetSearchparams);
  };
  useEffect(() => {
    dispatch(getDoctorAction());
    dispatch(getOrganLevelAction(0));
    dispatch(getSurgeryAction());
    dispatch(getAllTypeSurgeryAction());
  }, []);
  const columns = [
    { name: "code", label: "PatientID" },
    { name: "Pname", label: "Full Name" },

    { name: "phone", label: "Phone" },
    { name: "gender", label: "Gender" },

    { name: "birthDate", label: "Age" },
    { name: "occuption", label: "Occuption" },
    { name: "nation", label: "Nation" },
    { name: "country", label: "Country" },
    { name: "married", label: "Married" },
    { name: "noFamily", label: "Number Of Family" },
    { name: "province", label: "Province" },
    { name: "district", label: "District" },
    { name: "street", label: "Street" },
    { name: "closetHome", label: "Closet Home" },
    { name: "secoundHome", label: "Secound Phone" },
    { name: "card", label: "Card" },
    { name: "smoke", label: "Smoke" },
    { name: "timeAllote", label: "Time Allote" },
    { name: "SITD", label: "Disease Type Specialist" },
    { name: "member", label: "Member" },
    { name: "memberLevel", label: "Member Level" },

    { name: "TYP", label: "TYP" },
    { name: "indiction", label: "Indiction" },
    { name: "finalDiagnosis", label: "Final Diagnosis" },
    { name: "earlyPatient", label: "Early Patient" },
    { name: "Doctor", label: "Doctor" },
    { name: "ZODoctor", label: "Zad Organazation Doctor" },
    { name: "medicalProvided", label: "Medical Provided" },
    { name: "Typemedicalsupplies", label: "Type Medical Supplies" },
    { name: "supplyLocation", label: "Supply Location" },
    { name: "helpingZO", label: "Helping Zad Organazation" },

    { name: "literate", label: "literate" },

    { name: "alive", label: "alive" },
    { name: "salaryEmploye", label: "salaryEmploye" },
    { name: "house", label: "house" },
    { name: "numberOfHouse", label: "numberOfHouse" },
    { name: "teanant", label: "teanant" },
    { name: "numberOfLand", label: "numberOfLand" },
    { name: "poverty", label: "poverty" },
    { name: "Vehicle", label: "Vehicle" },
    { name: "land", label: "land" },
    { name: "debt", label: "debt" },
    { name: "govOrPriveteEmp", label: "govOrPriveteEmp" },
    { name: "metters", label: "metters" },
    { name: "NeighborhoodTypeselect", label: "NeighborhoodTypeselect" },
    // { name: "NeighborhoodTypeselect", label: "NeighborhoodTypeselect" },
    { name: "amountOfRent", label: "amountOfRent" },
    { name: "economicStatus", label: "economicStatus" },
    { name: "typeofVehicle", label: "typeofVehicle" },
    { name: "typeofLandEstimatedPrice", label: "typeofLandEstimatedPrice" },
    { name: "monthlyIncome", label: "monthlyIncome" },
    { name: "amountofSalary", label: "amountofSalary" },
    { name: "amountofLoan", label: "amountofLoan" },
    { name: "haveOtherDiseases", label: "haveOtherDiseases" },
    { name: "Typeofdisease", label: "Typeofdisease" },
    { name: "numberofChildren", label: "numberofChildren" },
    { name: "numberofStudent", label: "numberofStudent" },
    { name: "monthlyIncomeFamily", label: "monthlyIncomeFamily" },
    { name: "PovertyRates", label: "PovertyRates" },
    { name: "HMCabilitToSurgery", label: "HMCabilitToSurgery" },
    { name: "PMediaWork", label: "PMediaWork" },
    { name: "relationship", label: "relationship" },
    { name: "Name", label: "Name" },
    { name: "resonOfDebt", label: "resonOfDebt" },
    { name: "phoneNumber", label: "phoneNumber" },
    { name: "typeOfDeasise", label: "typeOfDeasise" },
    { name: "note", label: "note" },
    { name: "mercy", label: "mercy" },

    // ... Add more columns
  ];
  const [hiddenColumns, setHiddenColumns] = useState([
    "alive",
    "note",
    "salaryEmploye",
    "house",
    "numberOfHouse",
    "teanant",
    "numberOfLand",
    "poverty",
    "Vehicle",
    "land",
    "debt",
    "govOrPriveteEmp",
    "metters",
    "NeighborhoodTypeselect",
    "amountOfRent",
    "economicStatus",
    "typeofVehicle",
    "typeofLandEstimatedPrice",
    "monthlyIncome",
    "amountofSalary",
    "amountofLoan",
    "haveOtherDiseases",
    "Typeofdisease",
    "numberofChildren",
    "numberofStudent",
    "monthlyIncomeFamily",
    "PovertyRates",
    "HMCabilitToSurgery",
    "PMediaWork",
    "relationship",
    "Name",
    "resonOfDebt",
    "phoneNumber",
    "typeOfDeasise",
    "numberOfHouse",
    "teanant",
    "alive",
    "note",
    "mercy",
  ]);

  data.forEach((element) => {
    element.commites.map((commite) => {
      commite.lezhnas.map((lezhna) => {
        const doctor = commite.Doctor.map((dr) => dr).join(", ");
        listData.push({
          code: element.code,
          Pname: element.Pname,
          phone: element.phone.replace(regex, "$1-$2-$3"),
          gender: element.gender,
          birthDate: element.birthDate,
          occuption: commite.occuption,
          nation: commite.nation,
          country: commite.country,
          married: commite.married,
          noFamily: commite.noFamily,
          province: commite.province,
          district: commite.district,
          street: commite.street,
          closetHome: commite.closetHome,
          secoundHome: commite.secoundHome,
          card: commite.card,
          smoke: commite.smoke,
          timeAllote: commite.timeAllote,
          SITD: commite.SITD,
          member: commite.member,
          memberLevel: commite.memberLevel,
          TYP: commite.TYP,
          indiction: commite.indiction,

          HEP: commite.HEP,
          finalDiagnosis: commite.finalDiagnosis,
          earlyPatient: commite.earlyPatient,
          earlySurgery: commite.earlySurgery,
          Doctor: doctor,
          ZODoctor: commite.ZODoctor,
          medicalProvided: commite.medicalProvided,
          Typemedicalsupplies: commite.Typemedicalsupplies,
          member: commite.member,
          memberLevel: commite.memberLevel,
          supplyLocation: commite.supplyLocation,
          helpingZO: commite.helpingZO,
          literate: commite.literate,

          alive: lezhna.alive,
          salaryEmploye: lezhna.salaryEmploye,
          house: lezhna.house,
          numberOfHouse: lezhna.numberOfHouse,
          teanant: lezhna.teanant,
          numberOfLand: lezhna.numberOfLand,
          poverty: lezhna.poverty,
          Vehicle: lezhna.Vehicle,
          land: lezhna.land,
          debt: lezhna.debt,
          govOrPriveteEmp: lezhna.govOrPriveteEmp,
          metters: lezhna.metters,
          NeighborhoodTypeselect: lezhna.NeighborhoodTypeselect,
          amountOfRent: lezhna.amountOfRent,
          economicStatus: lezhna.economicStatus,
          typeofVehicle: lezhna.typeofVehicle,
          typeofLandEstimatedPrice: lezhna.typeofLandEstimatedPrice,
          monthlyIncome: lezhna.monthlyIncome,
          amountofSalary: lezhna.amountofSalary,
          amountofLoan: lezhna.amountofLoan,
          haveOtherDiseases: lezhna.haveOtherDiseases,
          Typeofdisease: lezhna.Typeofdisease,
          numberofChildren: lezhna.numberofChildren,
          numberofStudent: lezhna.numberofStudent,
          monthlyIncomeFamily: lezhna.monthlyIncomeFamily,
          PovertyRates: lezhna.PovertyRates,
          HMCabilitToSurgery: lezhna.HMCabilitToSurgery,
          PMediaWork: lezhna.PMediaWork,
          relationship: lezhna.relationship,
          Name: lezhna.Name,
          resonOfDebt: lezhna.resonOfDebt,
          phoneNumber: lezhna.phoneNumber,
          typeOfDeasise: lezhna.typeOfDeasise,
          note: lezhna.note,
          mercy: lezhna.mercy,
        });
      });
    });
    // console.log('commite: ',commite.occuption)

    // listData.push({});
  });

  const {
    code,
    Pname,
    phone,
    gender,
    birthDate,
    occuption,
    nation,
    married,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    TYP,
    indiction,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    country,
    Doctor,
    ZODoctor,
    medicalProvided,
    Typemedicalsupplies,
    supplyLocation,
    helpingZO,
    salaryEmploye,
    date,
    house,
    numberOfHouse,
    teanant,
    numberOfLand,
    poverty,
    Vehicle,
    alive,
    land,
    debt,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    amountOfRent,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountofSalary,
    amountofLoan,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    resonOfDebt,
    phoneNumber,
    typeOfDeasise,
    note,
    mercy,
  } = filterValues;
  useEffect(() => {
    if (hadSurgery == "") {
      setHadSurgery(null);
    }
    if (medicalProvidedSearch == "") {
      setMedicalProvidedSearch(null);
    }

    if (count == "") {
      setCount(null);
    }
    dispatch(
      getStatisticsAction(
        currentPage,
        perPage,
        selectedOption,
        code,
        Pname,
        phone,
        gender,
        birthDate,
        occuption,

        nation,

        country,
        married,
        noFamily,
        province,
        district,
        street,
        closetHome,
        secoundHome,
        card,
        smoke,
        timeAllote,
        SITD,
        member,
        memberLevel,
        TYP,
        indiction,
        finalDiagnosis,
        earlyPatient,
        earlySurgery,
        Doctor,
        ZODoctor,
        medicalProvided,
        Typemedicalsupplies,
        supplyLocation,
        helpingZO,
        salaryEmploye,
        date,
        house,
        numberOfHouse,
        teanant,
        numberOfLand,
        poverty,
        Vehicle,
        land,
        debt,
        govOrPriveteEmp,
        metters,
        NeighborhoodTypeselect,
        amountOfRent,
        typeofVehicle,
        typeofLandEstimatedPrice,
        monthlyIncome,
        amountofSalary,
        amountofLoan,
        haveOtherDiseases,
        Typeofdisease,
        numberofChildren,
        numberofStudent,
        monthlyIncomeFamily,
        PovertyRates,
        HMCabilitToSurgery,
        PMediaWork,
        relationship,
        Name,
        resonOfDebt,
        phoneNumber,
        typeOfDeasise,
        note,
        mercy,
        dateTime.from,
        dateTime.to,
        count,
        reject,
        alive,
        hadSurgery,
        medicalProvidedSearch,
        fromUpdate,
        toUpdate
      )
    );
  }, [
    filterValues,
    dateTime.to,
    dateTime.from,
    perPage,
    currentPage,
    selectedOption,
    count,
    reject,
    hadSurgery,
    medicalProvidedSearch,
    fromUpdate,
    toUpdate,
  ]);

  const toggleColumnVisibility = (columnName) => {
    if (hiddenColumns.includes(columnName)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== columnName));
    } else {
      setHiddenColumns([...hiddenColumns, columnName]);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    console.log(option);
    if (option == 1) {
      setSelectedOption("");

      setIsOpen(false);
    } else {
      setSelectedOption(option);
      setIsOpen(false);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(columns.map((column) => column.name));
    }
    setSelectAll(!selectAll);
  };
  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  const handleFilterChange = (columnName, value) => {
    setFilterValues({ ...filterValues, [columnName]: value });
  };

  const getBranchs = useSelector((state) => state.getBranch);
  const { branchs, loader } = getBranchs;
  const [loadingExcle,setLoadingExcle]=useState(false);
  const handleDownload = async () => {
    const {from,to}=dateTime;
    
    setLoadingExcle(true)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // Authorization: `Bearer ${getToken()}`,
        Authorization: `Bearer ${getToken()}`,
      },
      params:{
        from,
        to,
        fromUpdate,
        toUpdate
        
      },
      responseType: "blob",
    };
    try {
      const response = await api.get("statistics/export", config);

      
      const blob = new Blob([response.data], { type: "text/csv" });
      if(response.status==200){
        setLoadingExcle(false)
      }
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = "ئامار.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };
  return (
    <>
      {loading || loadingExcle || data == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white w-[95%]  m-auto rounded-md  mt-2">
          <div className="flex items-center justify-between rounded-md px-4 py-1 ">
            <div className="flex w-full gap-2">
              <div className="w-2/12">
                <select
                  name=""
                  className="inputUser w-full"
                  onChange={(e) => handleOptionClick(e.target.value)}
                  id=""
                >
                  <option value="">Select Branch</option>
                  {branchs
                    .filter((filter) => filter.id != 8)
                    .map((item) => (
                      <>
                        {item.bName == "Office" ? (
                          <option value={`${item.id}`}>All</option>
                        ) : (
                          <option value={`${item.id}`}>{item.bName}</option>
                        )}
                      </>
                    ))}
                </select>
              </div>

              <div className="flex w-full items-center gap-3 ">
                <div className="w-2/12">
                  <input
                    type="date"
                    onChange={(e) =>
                      setDateTme({
                        ...dateTime,
                        from: e.target.value,
                      })
                    }
                    value={dateTime.from}
                    className="inputUser w-full mt-0"
                  />
                </div>
                <div>To</div>
                <div className="w-2/12">
                  <input
                    type="date"
                    onChange={(e) =>
                      setDateTme({
                        ...dateTime,
                        to: e.target.value,
                      })
                    }
                    value={dateTime.to}
                    className="inputUser w-full mt-0"
                  />
                </div>

                <div className=" w-32" title="Visits">
                  <select
                    name=""
                    onChange={(e) => setCount(e.target.value)}
                    className="inputUser w-full"
                    id=""
                  >
                    <option value="" selected>
                      All ...
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    {/* <option value="11">11</option> */}
                  </select>
                </div>

                <div className=" w-32" title="Reject & Approve">
                  <select
                    name=""
                    onChange={(e) => setReject(e.target.value)}
                    className="inputUser w-full"
                    id=""
                  >
                    <option value="" selected>
                      All ...
                    </option>
                    <option value="2">Approve</option>
                    <option value="1">Reject</option>

                    {/* <option value="11">11</option> */}
                  </select>
                </div>
              </div>
            </div>

            <div>
              <div className="flex space-x-4">
                <div
                  title="Show & hide Columns"
                  className="text-md m-auto animate-pulse bg-indigo-600 px-2 py-2 text-white rounded-md bg-opacity-60 hover:cursor-pointer"
                  onClick={() => setIsopenColumns(!isOpenColumns)}
                >
                  <PiDotsThreeOutlineBold
                    onClick={() => setIsopenColumns(!isOpenColumns)}
                  />
                </div>
                <div
                  title="Filter"
                  className="text-md m-auto animate-pulse bg-teal-600 px-2 py-2 text-white rounded-md bg-opacity-60 hover:cursor-pointer"
                  onClick={handleOpenFilter}
                >
                  <FaFilter />
                </div>

                <div
                  className="text-md m-auto animate-pulse bg-red-600 px-2 py-2 text-white rounded-md bg-opacity-60 hover:cursor-pointer"
                  // onClick={handleOpenFilter}
                  title="Clear Filter"
                  onClick={handleReset}
                >
                  <MdFilterAltOff />
                </div>

                <div
                  className={
                    isOpenColumns
                      ? "bg-white shadow-2xl rounded-sm overflow-y-auto py-4 w-[15%] shadow-black absolute h-[70%] top-40 right-0 opacity-100 z-50 duration-500"
                      : "bg-white shadow-2xl  rounded-sm overflow-y-auto py-4 w-[15%] shadow-black absolute h-[70%] top-40 right-0 opacity-0 -z-50 duration-500 "
                  }
                >
                  <>
                    <div className="checkbox-wrapper-35 w-full pb-4">
                      <input
                        className="switch"
                        checked={selectAll}
                        onChange={handleSelectAll}
                        type="checkbox"
                        id="switch"
                        name="switch"
                        value="private"
                      />
                      <label htmlFor="switch">
                        <span className="switch-x-toggletext">
                          <span className="switch-x-unchecked">
                            <span className="switch-x-hiddenlabel">
                              Unchecked:{" "}
                            </span>
                            show
                          </span>
                          <span className="switch-x-checked">
                            <span className="switch-x-hiddenlabel">
                              Checked:{" "}
                            </span>
                            Hide All
                          </span>
                        </span>
                      </label>
                    </div>

                    <hr />
                  </>
                  <>
                    {columns.map((column, index) => (
                      <div class="checkbox-wrapper-42  ">
                        <input
                          id={`cbx-${index}`}
                          type="checkbox"
                          checked={!hiddenColumns.includes(column.name)}
                          onChange={() => toggleColumnVisibility(column.name)}
                        />
                        <label className="cbx" htmlFor={`cbx-${index}`}></label>
                        <label className="lbl" htmlFor={`cbx-${index}`}>
                          <span className="text-gray-700">{column.label}</span>
                        </label>
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4 items-center px-2">
            <div className="my-2">
              <label htmlFor="FromUpdate"></label>
              <input
                type="date"
                onChange={(e) => setFromUpdate(e.target.value)}
                className="inputUser w-full"
              />
            </div>
            <div className="my-2">
              <label htmlFor="toUpdate"></label>
              <input
                type="date"
                onChange={(e) => setToUpdate(e.target.value)}
                className="inputUser w-full"
              />
            </div>

            <div className=" " title="نەشتەرگەری بۆ کراوە">
              <select
                name=""
                onChange={(e) => {
                  setHadSurgery(e.target.value);
                }}
                className="inputUser w-full"
                id=""
              >
                <option value={""} selected>
                  نەشتەرگەری بۆ کراوە
                </option>
                <option value="بەڵێ">بەڵێ</option>
                <option value="نەخێر">نەخێر</option>
              </select>
            </div>

            <div className="" title="پێداویستی پزیشکی">
              <select
                name=""
                onChange={(e) => {
                  setMedicalProvidedSearch(e.target.value);
                }}
                className="inputUser w-full"
                id=""
              >
                <option value={""} selected>
                  پێداویستی پزیشکی
                </option>
                <option value="بەڵێ">بەڵێ</option>
                <option value="نەخێر">نەخێر</option>
              </select>
            </div>
            
              
            <button
            className="bg-teal-600   items-center px-2 py-1.5 text-white rounded-md shadow-sm hover:bg-teal-00 flex gap-2 "
            onClick={handleDownload}
          >
            <p>Export</p>
            <div>
              <PiMicrosoftExcelLogoDuotone />
            </div>
          </button>
        
            
              
            
          </div>
          <div
            className="overflow-x-auto xl:h-[25rem] 2xl:[34rem] "
            onClick={() => setIsopenColumns(false)}
          >
            <table>
              <thead className="sticky top-0 ">
                <tr>
                  {columns.map(
                    (column, index) =>
                      !hiddenColumns.includes(column.name) && (
                        <th
                          key={index}
                          className="  py-2"
                          style={{
                            minWidth: "300px",
                            borderBottom: "solid 1px #e5e7eb",
                          }}
                        >
                          <div className="flex text-left  px-3 flex-col">
                            <p className="pl-1">{column.label}</p>
                            {isOpenFilter && (
                              <div>
                                {column.label == "TYP" ? (
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className="select p-0 "
                                    id=""
                                  >
                                    <option value="" selected>
                                      زانیاریەکە هەڵبژێرە{" "}
                                    </option>
                                    {typeSurgery.map((type, index) => {
                                      return (
                                        <option
                                          value={type.type_name_en}
                                          key={index}
                                        >
                                          {type.type_name_en}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : column.label == "Member" ? (
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className="select p-0 "
                                    id=""
                                  >
                                    <option value="" selected>
                                      تکایە زانیاریەکە هەڵبژێرە{" "}
                                    </option>
                                    {typeSurgery.map((type, index) => {
                                      return (
                                        <option
                                          value={type.typeName}
                                          key={index}
                                        >
                                          {type.typeName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : column.label ==
                                  "Disease Type Specialist" ? (
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className="select p-0 "
                                    id=""
                                  >
                                    <option value="" selected>
                                      زانیاریەکە هەڵبژێرە{" "}
                                    </option>
                                    {surgery.map((element, index) => {
                                      return (
                                        <option
                                          value={element.surguryName}
                                          key={index}
                                        >
                                          {element.surguryName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : column.label == "Member Level" ? (
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className="select p-0 "
                                    id=""
                                  >
                                    <option value="" selected>
                                      زانیاریەکە هەڵبژێرە{" "}
                                    </option>
                                    {organLevel.map((organ, index) => {
                                      return (
                                        <option
                                          value={organ.organ_level_name}
                                          key={index}
                                        >
                                          {organ.organ_level_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : column.label == "Doctor" ? (
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className="select p-0 "
                                    id=""
                                  >
                                    <option value="" selected>
                                      زانیاریەکە هەڵبژێرە{" "}
                                    </option>
                                    {doctors.map((doctor, index) => {
                                      return (
                                        <option
                                          value={doctor.dr_name}
                                          key={index}
                                        >
                                          {doctor.dr_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : column.label == "Gender" ? (
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className="select p-0 "
                                    id=""
                                  >
                                    <option value="" selected>
                                      زانیاریەکە هەڵبژێرە{" "}
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                ) : (
                                  <input
                                    type="text"
                                    placeholder={`${column.label}`}
                                    onBlur={(e) =>
                                      handleFilterChange(
                                        column.name,
                                        e.target.value
                                      )
                                    }
                                    className={"inputUser p-1.5 rounded"}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {listData.map((item, index) => (
                  <tr key={index} className="tableRow">
                    {columns.map(
                      (column, index) =>
                        !hiddenColumns.includes(column.name) && (
                          <td
                            key={index}
                            className="border-b  py-4 "
                            style={{
                              minWidth: "390px",
                              borderBottom: "solid 1px #e5e7eb",
                            }}
                          >
                            <div className="flex gap-2">
                              {column.label == "Member Level"
                                ? item[column.name].map((element) => {
                                    return (
                                      <p>
                                        {filterValues.memberLevel == element ? (
                                          <p className="bg-yellow-300 bg-opacity-75 rounded-sm px-2 py-1 shadow-md">
                                            {element}
                                          </p>
                                        ) : (
                                          element
                                        )}
                                      </p>
                                    );
                                  })
                                : item[column.name]}
                            </div>
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="gap-3 px-3 flex  mt-3 items-center justify-between m-auto py-3">
            <div className=" flex gap-4 items-center font-semibold">
              <div>
                <p className="text-xl ">Total Patient : </p>
              </div>

              <div className="bg-teal-600 px-2 py-0.5 text-white rounded-md shadow-md  font-bold ">
                <p> {total} </p>
              </div>

              {/* {hadSurgery ||
              medicalProvidedSearch ||
              reject ||
              count == null ? (
                <>
                  <div>
                    <p className="text-xl ">Total Visits : </p>
                  </div>
                  <div className="bg-blue-600 px-2 py-0.5 text-white rounded-md shadow-md  font-bold ">
                    <p> {totalVisit} </p>
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>
            <div className="flex items-center gap-3">
              <select
                name=""
                className="select  w-20 p-0.5 "
                onChange={(e) => setPerPage(e.target.value)}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>

              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getStatisticsAction(
                      currentPage,
                      perPage,
                      selectedOption,
                      code,
                      Pname,
                      phone,
                      gender,
                      birthDate,
                      occuption,

                      nation,

                      country,
                      married,
                      noFamily,
                      province,
                      district,
                      street,
                      closetHome,
                      secoundHome,
                      card,
                      smoke,
                      timeAllote,
                      SITD,
                      member,
                      memberLevel,
                      TYP,
                      indiction,
                      finalDiagnosis,
                      earlyPatient,
                      earlySurgery,
                      Doctor,
                      ZODoctor,
                      medicalProvided,
                      Typemedicalsupplies,
                      supplyLocation,
                      helpingZO,
                      salaryEmploye,
                      date,
                      house,
                      numberOfHouse,
                      teanant,
                      numberOfLand,
                      poverty,
                      Vehicle,
                      land,
                      debt,
                      govOrPriveteEmp,
                      metters,
                      NeighborhoodTypeselect,
                      amountOfRent,
                      typeofVehicle,
                      typeofLandEstimatedPrice,
                      monthlyIncome,
                      amountofSalary,
                      amountofLoan,
                      haveOtherDiseases,
                      Typeofdisease,
                      numberofChildren,
                      numberofStudent,
                      monthlyIncomeFamily,
                      PovertyRates,
                      HMCabilitToSurgery,
                      PMediaWork,
                      relationship,
                      Name,
                      resonOfDebt,
                      phoneNumber,
                      typeOfDeasise,
                      note,
                      mercy
                    )
                  );

                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Statistics;
