import React, { useEffect, useState } from "react";
import { RiAdminFill } from "react-icons/ri";
import "./roles.css";
import RolesModal from "../../Modal/RolesModal/RolesModal";
import { useDispatch, useSelector } from "react-redux";
import { getRolesAction } from "../../Action/RolesAction/RolesAction";
import ReactPaginate from "react-paginate";
import {Link} from 'react-router-dom'
import Logo from '../../Image/Logo.jpg'
const Roles = () => {

  
  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const getRoles = useSelector((state) => state.getRoles);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { roles, loading, total } = getRoles;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRolesAction(currentPage, perPage));
  }, [perPage]);
  return (
    <>
      {loading || roles == undefined ? (
           <div className="flex flex-col">
           <img
             src={Logo}
             alt="Logo"
             className="w-56 m-auto animate-pulse mt-20 "
           />
           <div className="text-2xl text-center font-bold  ">
             <div className="flex justify-center gap-4">
               <p className="animate-charcter">Please Wait</p>
               <div class="container2">
                 <div class="dot"></div>
                 <div class="dot"></div>
                 <div class="dot"></div>
               </div>{" "}
             </div>
           </div>
         </div>
      ) : (
        <div className="bg-white rounded-md shadow-md w-11/12 mx-auto px-3 py-4">
          <div className="flex gap-2 justify-between mb-2">
            <div className="flex gap-2 items-center">
              <div className="text-violet-500 text-xl ">
                <RiAdminFill />
              </div>
              <div>
                <p className="font-bold">Roles</p>
              </div>
            </div>
            <button
              className="btnViolet"
              onClick={() => setIsOpenRolesModal(true)}
            >
              Add New
            </button>
          </div>
          <hr />
          <div className="parentTablePrimary">
          <table className="tb">
            <thead className="thead">
              <tr>
                <th className="thPrimary">#</th>
                <th className="thPrimary">Name</th>
                <th className="thPrimary">View</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role, index) => {
                return (
                  <tr key={index} className="tableRow">
                    <td>{index + 1}</td>
                    <td>{role.name}</td>
                    <td className="w-56 ">
                      <Link to={`/roles/edit/${role.id}`}>
                      <button className="btnViolet"  >View</button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
          <div className="flex items-center justify-end gap-4">
            <div>
              <select
                name=""
                className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                onChange={(e) => setPerPage(e.target.value)}
                value={perPage}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="flex justify-center   mt-1">
              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                // activeClassName=''
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(getRolesAction(currentPage, perPage));
                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-violet-600 text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}

      {isOpenRolesModal && <RolesModal closeModal={setIsOpenRolesModal} />}
    </>
  );
};

export default Roles;
