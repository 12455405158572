import Swal from "sweetalert2";
import {
  FETCH_SORTING_MEDIA_WORK_FAIL,
  FETCH_SORTING_MEDIA_WORK_REQUEST,
  FETCH_SORTING_MEDIA_WORK_SUCCESS,
  UPDATE_SECTION_MEDIA_WORK_REQUEST,
  UPDATE_SECTION_MEDIA_WORK_SUCCESS,
} from "../../ActionType/MediaType/sendMediaType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getSortingMediaWorkAction =
  (currentPage, perPage) => (dispatch) => {
    
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page: currentPage,
        perPage: perPage,
      },
    };
    api
      .get(`sortingMedia`, config)
      .then((response) => {
        const { status } = response;
        dispatch({ type: FETCH_SORTING_MEDIA_WORK_REQUEST });
        if (status == 200) {
          dispatch({
            type: FETCH_SORTING_MEDIA_WORK_SUCCESS,
            payload: response.data.data,
            total: response.data.total,
          });
        }

        if (status == 500) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Some thing Went Wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SORTING_MEDIA_WORK_FAIL,
          payload: [],
          error: "please Check Your Request",
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Please Check Your Requset & some thing went Wrong",
        });
      });
  };

export const updateSortingMediaWork = (sorting, id) => (dispatch) => {
  Swal.showLoading();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`sortingMedia/update/${id}/${sorting}`, {}, config)
    .then((response) => {
      const { status, data } = response;

      dispatch({
        type: UPDATE_SECTION_MEDIA_WORK_REQUEST,
      });

      if (status == 202) {
        Swal.hideLoading();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Changed Postion Was Succesfully",
        });
        dispatch({
          type: UPDATE_SECTION_MEDIA_WORK_SUCCESS,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: UPDATE_SECTION_MEDIA_WORK_SUCCESS,
      });
    });
};

export const approvedMediaWorkAction = (id) => (dispatch) => {
  Swal.showLoading();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .put(`sortingMedia/approved/${id}`, {}, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        Swal.hideLoading();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "The work is done",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
