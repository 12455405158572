import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GiReturnArrow } from "react-icons/gi";
import { AiTwotoneEye } from "react-icons/ai";
import {
  disapprovedRejectPatientAction,
  getRejectPatientAction,
  rejectPatientVisitAction,
} from "../../Action/rejectPatientAction";

import ReactPaginate from "react-paginate";
import ViewRejectModal from "../../Modal/ViewRejectModal/ViewRejectModal";
import { CiFilter } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Loading from "../Loading/Loading";

const RejectPatient = () => {
  const dispatch = useDispatch();
  const getRejectPatient = useSelector((state) => state.getRejectPatient);
  const { rejectPatient, loading, total } = getRejectPatient;
  const locationId = localStorage.getItem("locationId");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [patientId, setPatientId] = useState(null);
  const [rejectId, setRejectId] = useState(null);
  const rejectPatientVisit = useSelector((state) => state.rejectPatientVisit);
  const { rejects } = rejectPatientVisit;
  useEffect(() => {
    if (patientId == null) {
      return;
    }
    dispatch(rejectPatientVisitAction(patientId));
  }, [patientId]);
  const [searchParams, setSearchParams] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
    note: "",
    month: "",
  });
  const { fullname, code, phone, age, gender, note, month } = searchParams;
  useEffect(() => {
    if (locationId == null || locationId == undefined) {
      return;
    } else {
      dispatch(
        getRejectPatientAction(
          locationId,
          currentPage,
          perPage,
          fullname,
          code,
          phone,
          age,
          gender,
          note,
          month
        )
      );
    }
  }, [currentPage, perPage]);

  const [isOpenFilter, setIsOpentFilter] = useState(false);

  const [index, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };

  const handleSearch = (columnToClear) => {
    const { code, fullname, phone, gender, age, patientFrom, doctor } =
      searchParams;
    // console.log(hospital);
    const updatedSearchParams = {
      // patientFrom: columnToClear === "patientFrom" ? "" : patientFrom,
      fullname: columnToClear === "fullname" ? "" : fullname,
      phone: columnToClear === "phone" ? "" : phone,
      gender: columnToClear === "gender" ? "" : gender,
      age: columnToClear === "age" ? "" : age,
      doctor: columnToClear === "doctor" ? "" : doctor,
      code: columnToClear === "code" ? "" : code,
      note: columnToClear === "note" ? "" : note,
      month: columnToClear === "month" ? "" : month,
    };

    setIsOpentFilter(false);
    dispatch(
      getRejectPatientAction(
        locationId,
        currentPage,
        perPage,
        updatedSearchParams.fullname,
        updatedSearchParams.code,
        updatedSearchParams.phone,
        updatedSearchParams.age,
        updatedSearchParams.gender,
        updatedSearchParams.note,
        updatedSearchParams.month
      )
    );
  };
  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClearSearchChange = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };
  const [isOpenDisapprovedReject, setIsOpenDisapprovedReject] = useState(false);

  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleDisapporvedReject = (visit_id, rejectId) => {
    dispatch(disapprovedRejectPatientAction(visit_id, rejectId, patientId));
    // dispatch(rejectPatientWithVisitAction(patientId))
    // dispatch(rejectPatientWithVisitAction(patientId))
    dispatch(
      getRejectPatientAction(
        locationId,
        currentPage,
        perPage,
        fullname,
        code,
        phone,
        age,
        gender,
        note,
        searchParams.month
      )
    );
    dispatch(
      getRejectPatientAction(
        locationId,
        currentPage,
        perPage,
        fullname,
        code,
        phone,
        age,
        gender,
        note,
        searchParams.month
      )
    );
    // console.log(patientId)
    // dispatch(rejectPatientAction(patientId,visitId));
    dispatch(rejectPatientVisitAction(patientId));
    dispatch(rejectPatientVisitAction(patientId));
  };
  let listReject = [];
  const [visitId, setVisitId] = useState(null);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  rejectPatient.map((element, index) => {
    listReject.push(
      <tr key={index} className="border-b border-gray-200 hover:bg-slate-100">
        <td className="py-4 px-6 whitespace-nowrap">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {element.code}
              </div>
            </div>
          </div>
        </td>
        <td className="py-4 px-6 whitespace-nowrap">
          <div className="text-sm text-gray-900">{element.Pname}</div>
        </td>
        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full ">
            {element.phone}
          </span>
        </td>
        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full ">
            {element.birthDate}
          </span>
        </td>

        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full  ">
            {element.gender}
          </span>
        </td>

        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full  ">
            {element.created_at.toString().slice(0, 10)}
          </span>
        </td>

        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
            <button
              className="bg-blue-400 text-white py-2 px-3 rounded-md shadow-md "
              onClick={() => {
                setPatientId(element.id);
                element.reject.map((reject) => {
                  setRejectId(reject.id);
                });

                setIsOpenDisapprovedReject(true);
              }}
            >
              <GiReturnArrow />
            </button>
          </span>
        </td>

        {/* View */}
        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
            <button
              className="bg-blue-400 text-white py-2 px-3 rounded-md shadow-md "
              onClick={() => {
                setPatientId(element.id);
                element.reject.map((reject) => {
                  setRejectId(reject.id);
                });

                setIsOpenRejectModal(true);
              }}
            >
              <AiTwotoneEye />
            </button>
          </span>
        </td>
      </tr>
    );
  });

  return (
    <>
      {loading || rejects == undefined ? (
        <Loading />
      ) : (
        <div className="w-[95%] m-auto ">
          <div className="bg-white h-[75vh]">
            <div className="h-[90%] overflow-y-auto ">
              <table className="w-full">
                <thead className="bg-gray-100 sticky top-0">
                  <tr>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Patient ID</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(2)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 2
                            ? "parentInputSearch mt-1"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Code"
                          value={searchParams.code}
                          name="code"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("code");
                              handleClearSearchChange("code");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Full Name</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(3)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 3
                            ? "parentInputSearch mt-1"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Full Name"
                          value={searchParams.fullname}
                          name="fullname"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("fullname");
                              handleClearSearchChange("fullname");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Phone</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(4)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 4
                            ? "parentInputSearch mt-1"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Phone"
                          value={searchParams.phone}
                          name="phone"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("phone");
                              handleClearSearchChange("phone");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Birth Date</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(5)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 5
                            ? "parentInputSearch mt-1"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Birth Date"
                          value={searchParams.age}
                          name="age"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("Birth Date");
                              handleClearSearchChange("Birth Date");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Gender</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(6)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 6
                            ? "parentInputSearch mt-1"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Birth Date"
                          value={searchParams.age}
                          name="age"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("age");
                              handleClearSearchChange("age");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Date</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(7)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 7
                            ? "parentInputSearch mt-1"
                            : "hidden"
                        }
                      >
                        <input
                          type="date"
                          className="inputSearch"
                          placeholder="date"
                          value={searchParams.month}
                          name="month"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("month");
                              handleClearSearchChange("month");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th>
                      <span>Action</span>
                    </th>
                    <th>
                      <span>View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listReject}
                  {/* More rows */}
                </tbody>
              </table>
            </div>
            {loading == false ? (
              <div className=" flex justify-between p-2   ">
                <div className="bg-green-600 rounded-md shadow-md text-white w-20 py-1 text-center mt-3 bg-opacity-70">
                  <p>total : {total}</p>
                </div>
                {/* end Total */}
                <div className="flex gap-3 items-center">
                  <select
                    name=""
                    className="border w-20 p-0  rounded-md focus:border-slate-950"
                    onChange={(e) => setPerPage(e.target.value)}
                    id=""
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>

                  <div className="flex justify-center  gap-4 ">
                    <ReactPaginate
                      previousLabel="<"
                      nextLabel={">"}
                      // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                      breakLabel="..."
                      className="flex  items-center   -space-x-px h-8 text-sm"
                      previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                      nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                      pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                      breakClassName="w-8 py-2 text-center"
                      pageCount={Math.ceil(total / perPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={(event) => {
                        let currentPage = event.selected + 1;
                        dispatch(
                          getRejectPatientAction(
                            locationId,
                            currentPage,
                            perPage,
                            fullname,
                            code,
                            phone,
                            age,
                            gender,
                            note
                          )
                        );
                        setCurrentPage(currentPage);
                      }}
                      activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {isOpenRejectModal && (
            <ViewRejectModal
              patient_id={patientId}
              closeModal={setIsOpenRejectModal}
            />
          )}
          {/* {isOpenReturnRejectModal && <ReturnRejectModal />} */}
          {isOpenDisapprovedReject && (
            <div className="w-full fixed top-0 left-0 z-20">
              <div
                className="overlay "
                onClick={() => setIsOpenDisapprovedReject(false)}
              ></div>
              <div className="bg-white w-5/12 py-2 absolute top-10 left-[50%] -translate-x-[50%] rounded-md shadow-md  px-3 z-50">
                <div className="flex justify-between items-center">
                  <div>
                    <select
                      onChange={(event) => setVisitId(event.target.value)}
                      name=""
                      className="select"
                      id=""
                    >
                      <option value={-1}>
                        ... تکایە کاتی سەردانیەکە دیاری بکە
                      </option>
                      {rejects.map((reject, index) => {
                        return (
                          <option key={index} value={reject.id}>
                            {reject.created_at.toString().slice(0, 10)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <p
                      className="text-red-500 animate-pulse text-xl"
                      onClick={() => setIsOpenDisapprovedReject(false)}
                    >
                      X
                    </p>
                  </div>
                </div>
                {visitId == null ? (
                  ""
                ) : (
                  <>
                    <div className="text-center pt-4">
                      <p>دڵنیای لە گەڕاندنەوەی زانیاری ئەم نەخۆشە ؟</p>
                    </div>

                    <div className="flex justify-center gap-2 mt-4 ">
                      <button
                        className="rejectBtn"
                        onClick={() => setIsOpenDisapprovedReject(false)}
                      >
                        No,Cancel
                      </button>
                      <button
                        className="assignBtn"
                        onClick={() => {
                          handleDisapporvedReject(visitId, rejectId);
                          setIsOpenDisapprovedReject(false);
                        }}
                      >
                        Yes, I'm sure
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="pb-28"></div>
    </>
  );
};

export default RejectPatient;
