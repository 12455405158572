import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_MEDICAL_SUPLIES_TYPES_FAIL,
  CREATE_MEDICAL_SUPLIES_TYPES_REQUEST,
  CREATE_MEDICAL_SUPLIES_TYPES_SUCCESS,
  DELETE_MEDICAL_SUPLIES_TYPES_FAIL,
  DELETE_MEDICAL_SUPLIES_TYPES_REQUEST,
  DELETE_MEDICAL_SUPLIES_TYPES_SUCCESS,
  FETCH_MEDICAL_SUPLIES_TYPES_FAIL,
  FETCH_MEDICAL_SUPLIES_TYPES_REQUEST,
  FETCH_MEDICAL_SUPLIES_TYPES_SUCCESS,
  UPDATE_MEDICAL_SUPLIES_TYPES_FAIL,
  UPDATE_MEDICAL_SUPLIES_TYPES_REQUEST,
  UPDATE_MEDICAL_SUPLIES_TYPES_SUCCESS,
} from "../../ActionType/MedicalSupliesTypes/MedicalSupliesTypes";

export const getMedicalSupliesTypesAction = () => async (dispatch) => {
  try {
    // Swal.showLoading();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: FETCH_MEDICAL_SUPLIES_TYPES_REQUEST,
    });

    const response = await api.get("medicalSupliesTypes", config);
    if (response.status == 200) {
      dispatch({
        type: FETCH_MEDICAL_SUPLIES_TYPES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_MEDICAL_SUPLIES_TYPES_FAIL,
      payload: [],
    });
  }
};

export const createMedicalSupliesTypesAction = (name) => async (dispatch) => {
  try {
    Swal.showLoading();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_MEDICAL_SUPLIES_TYPES_REQUEST,
    });

    const response = await api.post(
      "medicalSupliesTypes/create",
      { name },
      config
    );
    console.log(response);
    if (response.status == 200) {
      dispatch({
        type: CREATE_MEDICAL_SUPLIES_TYPES_SUCCESS,
        payload: response.data,
      });
      Swal.fire({
        icon: "success",
        timer: 1500,
        title: "Good Job",
        text: "Data Was Save",
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: CREATE_MEDICAL_SUPLIES_TYPES_FAIL,
      payload: [],
    });
  }
};

export const deleteMedicalSupliesTypesAction = (id) => async (dispatch) => {
  try {
    Swal.showLoading();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: DELETE_MEDICAL_SUPLIES_TYPES_REQUEST,
    });

    const response = await api.delete(
      `medicalSupliesTypes/delete/${id}`,
      config
    );
    // console.log(response);
    if (response.status == 200) {
      dispatch({
        type: DELETE_MEDICAL_SUPLIES_TYPES_SUCCESS,
        payload: response.data,
      });
      Swal.fire({
        icon: "success",
        timer: 1500,
        title: "Good Job",
        text: "Data Was Delete",
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: DELETE_MEDICAL_SUPLIES_TYPES_FAIL,
      payload: [],
    });
  }
};

export const updateMedicalSupliesTypesAction =
  (id, name) => async (dispatch) => {
    try {
      Swal.showLoading();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      };
      dispatch({
        type: UPDATE_MEDICAL_SUPLIES_TYPES_REQUEST,
      });

      const response = await api.put(
        `medicalSupliesTypes/update/${id}`,
        { name },
        config
      );
      if (response.status == 200) {
        dispatch({
          type: UPDATE_MEDICAL_SUPLIES_TYPES_SUCCESS,
          payload: response.data,
        });

        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Data Was Update",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MEDICAL_SUPLIES_TYPES_FAIL,
        payload: [],
      });
    }
  };
