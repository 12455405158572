import {
  CREATE_VISIT_FAIL,
  CREATE_VISIT_REQUEST,
  CREATE_VISIT_SUCCESS,
  FETCH_VISIT_BY_PATIENT_ID_FAIL,
  FETCH_VISIT_BY_PATIENT_ID_REQUEST,
  FETCH_VISIT_BY_PATIENT_ID_SUCCESS,
  FETCH_VISIT_FAIL,
  FETCH_VISIT_REQUEST,
  FETCH_VISIT_SUCCESS,
  UPDATE_VISIT_FAIL,
  UPDATE_VISIT_REQUEST,
  UPDATE_VISIT_SUCCESS,
} from "../../ActionType/Visits/VisitsType";

export const getVisitReducer = (
  state = { loading: true, visits: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_VISIT_SUCCESS:
      return {
        loading: false,
        payload: action.payload,
        error: "",
      };
    case FETCH_VISIT_FAIL:
      return {
        loading: false,
        payload: [],
        error: "",
      };
    default:
      return state;
  }
};


export const getVisitByPatientIdReducer = (
  state = { loading: true, visits: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_VISIT_BY_PATIENT_ID_REQUEST:
      return {
        loading: true,
      };
    case FETCH_VISIT_BY_PATIENT_ID_SUCCESS:
      return {
        loading: false,
        visits: action.payload,
        error: "",
      };
    case FETCH_VISIT_BY_PATIENT_ID_FAIL:
      return {
        loading: false,
        payload: [],
        error: "",
      };
    default:
      return state;
  }
};


export const createVisitReducer = (
  state = { loading: true, visits: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_VISIT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_VISIT_SUCCESS:
      return {
        loading: false,
        payload: action.payload,
        error: "",
      };
    case CREATE_VISIT_FAIL:
      return {
        loading: false,
        payload: [],
        error: "",
      };
    default:
      return state;
  }
};

export const updateVisitReducer = (
  state = { loading: true, visits: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_VISIT_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_VISIT_SUCCESS:
      return {
        loading: false,
        payload: action.payload,
        error: "",
      };
    case UPDATE_VISIT_FAIL:
      return {
        loading: false,
        payload: [],
        error: "",
      };
    default:
      return state;
  }
};

