import { Children } from "react";
import {
  EXPORT_STATISTICS_FAIL,
  EXPORT_STATISTICS_REQUEST,
  EXPORT_STATISTICS_SUCCESS,
  FETCH_STATISTICS_FAIL,
  FETCH_STATISTICS_REQUEST,
  FETCH_STATISTICS_SUCCESS,
} from "../../ActionType/StatisticsType/StatisticsType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getStatisticsAction =
  (
    page,
    perPage,
    id,
    code,
    fullname,
    phone,
    gender,
    birthDate,
    occuption,

    nation,

    country,
    married,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    TYP,
    indiction,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    Doctor,
    ZODoctor,
    medicalProvided,
    Typemedicalsupplies,
    supplyLocation,
    helpingZO,
    salaryEmploye,
    date,
    house,
    numberOfHouse,
    teanant,
    numberOfLand,
    poverty,
    Vehicle,
    land,
    debt,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    amountOfRent,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountofSalary,
    amountofLoan,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    resonOfDebt,
    phoneNumber,
    typeOfDeasise,
    note,
    mercy,
    from,
    to,
    count,
    reject,
    alive,
    hadSurgery,
    medicalProvidedSearch,
    fromUpdate,
    toUpdate
  ) =>
  (dispatch) => {
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        location_patinet: id,
        code: code,
        Pname: fullname,
        phone: phone,
        gender: gender,
        birthDate: birthDate,
        occuption: occuption,
        nation: nation,
        country: country,
        married: married,
        noFamily: noFamily,
        province: province,
        district: district,
        street: street,
        closetHome: closetHome,
        secoundHome: secoundHome,
        card: card,
        smoke: smoke,
        timeAllote: timeAllote,
        SITD: SITD,
        member: member,
        memberLevel: memberLevel,
        TYP: TYP,
        indiction: indiction,
        finalDiagnosis: finalDiagnosis,
        earlyPatient: earlyPatient,
        earlySurgery: earlySurgery,
        Doctor: Doctor,
        ZODoctor: ZODoctor,
        medicalProvided: medicalProvided,
        Typemedicalsupplies: Typemedicalsupplies,
        supplyLocation: supplyLocation,
        helpingZO: helpingZO,
        secoundHome: secoundHome,
        salaryEmploye: salaryEmploye,
        date: date,
        house: house,
        numberOfHouse: numberOfHouse,
        teanant: teanant,
        numberOfLand: numberOfLand,
        poverty: poverty,
        Vehicle: Vehicle,
        land: land,
        debt: debt,
        govOrPriveteEmp: govOrPriveteEmp,
        metters: metters,
        NeighborhoodTypeselect: NeighborhoodTypeselect,
        amountOfRent: amountOfRent,
        typeofVehicle,
        typeofLandEstimatedPrice,
        monthlyIncome,
        amountofSalary,
        amountofLoan,
        haveOtherDiseases,
        Typeofdisease,
        numberofChildren,
        numberofStudent,
        monthlyIncomeFamily,
        PovertyRates,
        HMCabilitToSurgery,
        PMediaWork,
        relationship,
        Name,
        resonOfDebt,
        phoneNumber,
        typeOfDeasise,
        note,
        mercy,
        from,
        to,
        count,
        reject,
        alive,
        had_surgery: hadSurgery,
        medicalProvided: medicalProvidedSearch,
        fromUpdate,
        toUpdate,
      },
    };

    api
      .get("statistics", config)
      .then((response) => {
        dispatch({ type: FETCH_STATISTICS_REQUEST });
        const { status, data } = response;
        // console.log("total visit : ",data.totalVisit)
        if (status == 200) {
          dispatch({
            type: FETCH_STATISTICS_SUCCESS,
            payload: {
              data: data.staticstics.data,
              total: data.staticstics.total,
              totalVisit: data.totalVisit,
            },
            erorr: "",
          });
        }
      })
      .catch((err) => {
        dispatch({ type: FETCH_STATISTICS_FAIL, payload: [] });
      });
  };

export const exportSatitisticAction = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",

      Authorization: `Bearer ${getToken()}`,
    },
    responseType: "blob",
  };
  try {
    const response = api.get("statistics/export", config);
    
    const blob = new Blob([response.data], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "dynamic_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading CSV:", error);
  }
};
