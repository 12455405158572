import React from "react";
import Logo from '../../Image/Logo.jpg'
const Loading = () => {
  return (
    <div className="flex flex-col">
      <img src={Logo} alt="Logo" className="w-56 m-auto animate-pulse mt-20 " />
      <div className="text-2xl text-center font-bold  ">
        <div className="flex justify-center gap-4">
          <p className="animate-charcter">Please Wait</p>
          <div class="container2">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Loading;
