import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";

import { AiOutlineEye } from "react-icons/ai";
import { getFeedbackAction } from "../../Action/lezhnaAction";
import Logo from "../../Image/Logo.jpg";
import ViewAllInfoPatient from "../../Modal/ViewAllInfoPatient";
import SendModalAllBranch from "../../Modal/SendModalAllBrnach/SendModalAllBranch";
import ReactPaginate from "react-paginate";
import { RxCross2 } from "react-icons/rx";
import { CiFilter } from "react-icons/ci";
import Loading from "../Loading/Loading";
const Feedback = () => {
  const location_id = localStorage.getItem("locationId");
  const dispatch = useDispatch();
  const getFeedback = useSelector((state) => state.getFeedback);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const { feedback, total, loading } = getFeedback;
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    phone: "",
    code: "",
    age: "",
    gender: "",
  });
  const { fullname, phone, code, age, gender } = searchParams;
  const [isOpenViewAllInfoModal, setIsOpenViewAllInfoModal] = useState(false);
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  useEffect(() => {
    dispatch(
      getFeedbackAction(
        location_id,
        code,
        fullname,
        gender,
        age,
        phone,
        currentPage,
        perPage
      )
    );
  }, [perPage]);

  const [lezhnaId, setLezhnaId] = useState(null);
  const [patientId, setPatientId] = useState(null);

  const [isOpenFilter, setIsOpentFilter] = useState(false);

  const [index, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };
  const handleSearch = (columnToClear) => {
    const { code, fullname, phone, gender, age } = searchParams;

    const updatedSearchParams = {
      code: columnToClear === "code" ? "" : code,
      fullname: columnToClear === "fullname" ? "" : fullname,
      phone: columnToClear === "phone" ? "" : phone,
      gender: columnToClear === "gender" ? "" : gender,
      age: columnToClear === "age" ? "" : age,
    };

    setIsOpentFilter(false);

    dispatch(
      getFeedbackAction(
        location_id,
        updatedSearchParams.code,
        updatedSearchParams.fullname,
        updatedSearchParams.gender,
        updatedSearchParams.age,
        updatedSearchParams.phone,
        currentPage,
        perPage
      )
    );
  };

  const regex = /^(\d{4})(\d{3})(\d{4})$/;

  return (
    <>
      {loading || !feedback ? (
        <Loading />
      ) : (
        <div className="  w-[98%] m-auto  ">
          <div className="flex gap-2 items-center bg-gray-100 px-4 border-b py-4 rounded-md shadow-xl  ">
            <p className="text-xl text-teal-600">
              {/* <HiOutlineUserGroup /> */}
            </p>
            <div>
              <h1 className="text-xl">Affairs Of Surgery</h1>
            </div>
          </div>

          <div className="bg-white w-full h-[75vh] ">
            <div className="overflow-x-auto h-[90%] m-auto">
              <table className="w-full text-sm  overflow-y-auto m-auto   text-left text-gray-900">
                <thead className="sticky top-0  bg-gray-100 ">
                  <tr className="w-full">
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Patient ID</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(1)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 1
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Code"
                          value={searchParams.code || ""}
                          name="code"
                          onChange={(e) => {
                            setSearchParams({
                              ...searchParams,
                              code: e.target.value,
                            });
                          }}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("code");
                              setSearchParams({
                                ...searchParams,
                                code: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Full Name</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(2)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 2
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Full Name"
                          value={searchParams.fullname}
                          name="fullname"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              fullname: e.target.value,
                            })
                          }
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("fullname");
                              setSearchParams({
                                ...searchParams,
                                fullname: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Phone</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(3)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 3
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Phone"
                          value={searchParams.phone}
                          name="phone"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              phone: e.target.value,
                            })
                          }
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch  "
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("phone");
                              setSearchParams({
                                ...searchParams,
                                phone: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative  ">
                      <div className="flex   justify-between items-center">
                        <p className="pl-1">Gender</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(4)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 4
                            ? "parentInputSearch bg-white"
                            : "hidden"
                        }
                      >
                        <div>
                          <div className="w-full">
                            <select
                              onChange={(e) =>
                                setSearchParams({
                                  ...searchParams,
                                  gender: e.target.value,
                                })
                              }
                              value={searchParams.gender}
                              name="gender"
                              type="text"
                              placeholder="Gender"
                              className={
                                "inputSearch p-1.5 shadow-xl rounded  "
                              }
                            >
                              <option value="">ڕەگەز هەڵبژێرە</option>
                              <option value="Male">Male </option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("gender");
                              setSearchParams({
                                ...searchParams,
                                gender: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Birth Date</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(5)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 5
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Birth Date"
                          value={searchParams.age}
                          name="age"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              age: e.target.value,
                            })
                          }
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              handleSearch("age");
                              setSearchParams({
                                ...searchParams,
                                age: "",
                              });
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>Date</th>
                    <th className="">
                      <p className="pl-1">Visits</p>
                    </th>
                    <th className="">Send</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feedback?.map((item, index) => {
                    return (
                      <tr key={index} className="tableRow py-6">
                        <td className="py-2">{item.code}</td>
                        <td>{item.Pname}</td>
                        <td>{item.phone.replace(regex, "$1-$2-$3")}</td>
                        <td>{item.gender}</td>
                        <td>{item.birthDate}</td>

                        <td>{item?.created_at.toString().slice(0, 10)}</td>

                        <td>
                          <p className="text-red-400 text-center text-xl underline font-bold">
                            {item.visits_with_reject_count}
                          </p>
                        </td>
                        <td
                          className=" group z-50"
                          onClick={() => {
                            setIsOpenSendModal(true);
                            // console.log(item.id);
                            setPatientId(item.id);
                            item?.commites?.map((commite) => {
                              if (commite.lezhnas) {
                                setLezhnaId(commite.lezhnas.id);
                              }
                            });
                          }}
                        >
                          <p className="text-indigo-500 text-xl">
                            <RiSendPlaneFill />
                          </p>
                        </td>
                        <td
                          className=" group"
                          onClick={() => {
                            setIsOpenViewAllInfoModal(true);
                            item.commites.map((commite) => {
                              setPatientId(commite.PatientId);
                              setLezhnaId(commite.lezhnas.id);
                            });
                          }}
                        >
                          <p className="text-teal-500 text-xl">
                            <AiOutlineEye />
                          </p>
                        </td>
                      </tr>
                    );
                  })}

                  {/* <tr></tr> */}
                </tbody>
              </table>
            </div>
            <div className="gap-3 px-3 flex  items-center justify-end py-2 m-auto">
              <div className="w-20">
                <select
                  name=""
                  className=" bg-white border  w-full focus:ring-2   focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  value={perPage}
                  id=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="flex justify-center  ">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      getFeedbackAction(
                        location_id,
                        "",
                        "",
                        "",
                        "",
                        "",
                        currentPage,
                        perPage
                      )
                    );
                    // console.log(currentPage);
                    setCurrentPage(event.selected);
                  }}
                  // activeClassName="bg-red-900 text-white"
                  // style={currentPage==1?"bg-black":""}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenViewAllInfoModal && (
        <ViewAllInfoPatient
          lezhnaId={lezhnaId}
          closeModal={setIsOpenViewAllInfoModal}
          patientId={patientId}
        />
      )}
      {isOpenSendModal && (
        <SendModalAllBranch
          lezhna_id={lezhnaId}
          patient_id={patientId}
          closeModal={setIsOpenSendModal}
        />
      )}

      <div className="pb-28"></div>
    </>
  );
};

export default Feedback;
