import {
  CREATE_MEDICAL_SUPLIES_TYPES_FAIL,
  CREATE_MEDICAL_SUPLIES_TYPES_REQUEST,
  CREATE_MEDICAL_SUPLIES_TYPES_SUCCESS,
  DELETE_MEDICAL_SUPLIES_TYPES_FAIL,
  DELETE_MEDICAL_SUPLIES_TYPES_REQUEST,
  DELETE_MEDICAL_SUPLIES_TYPES_SUCCESS,
  FETCH_MEDICAL_SUPLIES_TYPES_FAIL,
  FETCH_MEDICAL_SUPLIES_TYPES_REQUEST,
  FETCH_MEDICAL_SUPLIES_TYPES_SUCCESS,
} from "../../ActionType/MedicalSupliesTypes/MedicalSupliesTypes";



export const getMedicalSupliesTypesReducer = (
  state = { loading: true, typeMedicals: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_MEDICAL_SUPLIES_TYPES_REQUEST:
      return {
        loading: true,
      };
    case FETCH_MEDICAL_SUPLIES_TYPES_SUCCESS:
      return {
        loading: false,
        typeMedicals: action.payload,
        error: "",
      };
    case FETCH_MEDICAL_SUPLIES_TYPES_FAIL:
      return {
        loading: false,
        typeMedicals: [],
        error: "Please Check",
      };
    default:
      return state;
  }
};

export const createMedicalSupliesTypesReducer = (
  state = { loading: true, typeMedicals: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_MEDICAL_SUPLIES_TYPES_REQUEST:
      return {
        loading: true,
      };
    case CREATE_MEDICAL_SUPLIES_TYPES_SUCCESS:
      return {
        loading: false,
        typeMedicals: action.payload,
        error: "",
      };
    case CREATE_MEDICAL_SUPLIES_TYPES_FAIL:
      return {
        loading: false,
        typeMedicals: [],
        error: "Please Check",
      };
    default:
      return state;
  }
};

export const deleteMedicalSupliesTypesReducer = (
  state = { loading: true, typeMedicals: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_MEDICAL_SUPLIES_TYPES_REQUEST:
      return {
        loading: true,
      };
    case DELETE_MEDICAL_SUPLIES_TYPES_SUCCESS:
      return {
        loading: false,
        typeMedicals: action.payload,
        error: "",
      };
    case DELETE_MEDICAL_SUPLIES_TYPES_FAIL:
      return {
        loading: false,
        typeMedicals: [],
        error: "Please Check",
      };
    default:
      return state;
  }
};