import Swal from "sweetalert2";
import {
  FETCH_INFO_WHATS_APP_BY_VISIT_FAIL,
  FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST,
  FETCH_INFO_WHATS_APP_BY_VISIT_SUCCESS,
  FETCH_INFO_WHATS_APP_EXCEL_FAIL,
  FETCH_INFO_WHATS_APP_EXCEL_REQUEST,
  FETCH_INFO_WHATS_APP_EXCEL_SUCCESS,
  FETCH_INFO_WHATS_APP_REQUEST,
  FETCH_INFO_WHATS_APP_SUCCESS,
  SEND_INFO_WHATS_APP_FAIL,
  SEND_INFO_WHATS_APP_REQUEST,
} from "../../ActionType/WhatsApp/WhatsApp";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const sendWhatsAppAction =
  (
    id,
    visit_id,
    branch_id,
    fullname,
    organ_level,
    doctor,
    hospital,
    total_of_surgery,
    total_of_hospital,
    date,
    total_of_patient,
    zad,
    helpDr
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: SEND_INFO_WHATS_APP_REQUEST,
    });

    api
      .post(
        "whatsApp/create",
        {
          id,
          visit_id,
          branch_id,
          fullname,
          organ_level,
          doctor,
          hospital,
          total_of_hospital,
          total_of_surgery,
          date,
          total_of_patient,
          zad,
          helpDr,
        },
        config
      )
      .then((response) => {
        const { status, data } = response;

        dispatch({
          type: SEND_INFO_WHATS_APP_REQUEST,
        });
        if (status == 200) {
          Swal.fire({
            icon: "success",
            title: "سەرکەوتووبو",
            text: "زانیاریەکە بە سەرکەوتووی نێردرا",
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const getWhatsUpInfoAction =
  (
    perPage,
    page,
    fullname,
    organ_level,
    doctor,
    hospital,
    totalOfPatient,
    totalOfSurgery,
    total_of_hospital,
    zad,
    date,
    location_id,
    excel,
    helpDr,
    from,
    to
  ) =>
  (dispatch) => {
    // console.log(hospital)
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      params: {
        page,
        perPage,
        fullname,
        organ_level,
        doctor,
        hospital,
        totalOfPatient,
        totalOfSurgery,
        total_of_hospital,
        zad,
        date,
        location_id,
        excel,
        helpDr,
        from,
        to,
      },
    };
    api
      .get("whatsApp", config)
      .then((response) => {
        dispatch({
          type: FETCH_INFO_WHATS_APP_REQUEST,
        });
        const { status, data } = response;

        if (status == 200) {
          dispatch({
            type: FETCH_INFO_WHATS_APP_SUCCESS,
            payload: {
              data: data.data,
              total: data.total,
            },
            error: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const getWhatsAppInfoExcelAction = () => (dispatch) => {
  // dispatch({
  //   type: FETCH_INFO_WHATS_APP_REQUEST,
  // });
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  };
  api
    .get("whatsApp/excel", config)
    .then((response) => {
      dispatch({
        type: FETCH_INFO_WHATS_APP_EXCEL_REQUEST,
      });
      const { status, data } = response;

      if (status == 200) {
        dispatch({
          type: FETCH_INFO_WHATS_APP_EXCEL_SUCCESS,
          payload: data,
          error: "",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_INFO_WHATS_APP_EXCEL_FAIL,
        payload: [],
        error: "Please Check Your Request",
      });
      console.log(error);
    });
};

export const getWhatsAppInfoByVisitAction = (visit_id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    params: {
      visit_id,
    },
  };
  dispatch({
    type: FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST,
  });
  api
    .get("whatsApp/showByVisit", config)
    .then((response) => {
      dispatch({
        type: FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST,
      });
      const { status, data } = response;
      // console.log(data);

      if (status == 200) {
        dispatch({
          type: FETCH_INFO_WHATS_APP_BY_VISIT_SUCCESS,
          payload: data,
          error: "",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_INFO_WHATS_APP_BY_VISIT_FAIL,
        data: "",
        error: "Please Check your Request ",
      });
      // console.log(error);
    });
};
