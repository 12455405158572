export const CREATE_SENT_FILES_ADMINSTRATOR_REQUEST =
  "CREATE_SENT_FILES_ADMINSTRATOR_REQUEST";
export const CREATE_SENT_FILES_ADMINSTRATOR_SUCCESS =
  "CREATE_SENT_FILES_ADMINSTRATOR_SUCCESS";
export const CREATE_SENT_FILES_ADMINSTRATOR_FAIL =
  "CREATE_SENT_FILES_ADMINSTRATOR_FAIL";

export const FETCH_SENT_FILES_ADMINSTRATOR_REQUEST =
  "FETCH_SENT_FILES_ADMINSTRATOR_REQUEST";
export const FETCH_SENT_FILES_ADMINSTRATOR_SUCCESS =
  "FETCH_SENT_FILES_ADMINSTRATOR_SUCCESS";
export const FETCH_SENT_FILES_ADMINSTRATOR_FAIL =
  "FETCH_SENT_FILES_ADMINSTRATOR_FAIL";

export const DELETE_SENT_FILES_ADMINSTRATOR_REQUEST =
  "DELETE_SENT_FILES_ADMINSTRATOR_REQUEST";
export const DELETE_SENT_FILES_ADMINSTRATOR_SUCCESS =
  "DELETE_SENT_FILES_ADMINSTRATOR_SUCCESS";
export const DELETE_SENT_FILES_ADMINSTRATOR_FAIL =
  "DELETE_SENT_FILES_ADMINSTRATOR_FAIL";

export const UPDATE_SENT_FILES_ADMINSTRATOR_REQUEST =
  "UPDATE_SENT_FILES_ADMINSTRATOR_REQUEST";
export const UPDATE_SENT_FILES_ADMINSTRATOR_SUCCESS =
  "UPDATE_SENT_FILES_ADMINSTRATOR_SUCCESS";
export const UPDATE_SENT_FILES_ADMINSTRATOR_FAIL =
  "UPDATE_SENT_FILES_ADMINSTRATOR_FAIL";
