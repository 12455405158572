import React, { useEffect, useState } from "react";
import { FaFilter, FaSuitcaseMedical } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  getTypeMedicalAction,
  getTypeMedicalExcelAction,
} from "../../../Action/TypeMedicalAction/typeMedicalAction";
import Logo from "../../../Image/Logo.jpg";
import ReactPaginate from "react-paginate";
import { BsCardImage } from "react-icons/bs";
import TypeMedicalModal from "../../../Modal/TypeMedicalModal/TypeMedicalModal";

import { RiFileExcel2Fill } from "react-icons/ri";
import { getToken } from "../../../Action/login";
import { api } from "../../../Api/api";
import { CiFilter } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { getMedicalSupliesTypesAction } from "../../../Action/MedicalSupliesTypesAction/MedicalSupliesTypesAction";
import Loading from "../../Loading/Loading";

const MedicalSuplies = () => {
  const dispatch = useDispatch();
  const getTypeMedical = useSelector((state) => state.getTypeMedical);
  const [currentPage, setCurrentPage] = useState(1);
  const locationId = localStorage.getItem("locationId");
  const [perPage, setPerPage] = useState(20);
  const [patientId, setPatientId] = useState(null);
  const [typeMedcaliId, setTypeMedicalId] = useState(null);
  const { data, total, loading } = getTypeMedical;
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    type_of_help: "",
    place: "",
    hospital: "",
    type_of_test: "",
    totalOf: "",
    date: "",
  });
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const getMedicalSupliesTypes = useSelector(
    (state) => state.getMedicalSupliesTypes
  );
  const { typeMedicals } = getMedicalSupliesTypes;
  useEffect(() => {
    dispatch(getMedicalSupliesTypesAction());
  }, []);
  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    // console.log(value)
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const {
    fullname,
    type_of_help,
    place,
    hospital,
    type_of_test,
    totalOf,
    date,
  } = searchParams;
  useEffect(() => {
    dispatch(
      getTypeMedicalAction(
        locationId,
        currentPage,
        perPage,
        fullname,
        type_of_help,
        place,
        hospital,
        type_of_test,
        totalOf,
        date,
        from,
        to
      )
    );
  }, [from, to, type_of_help]);

  const [isOpenFilter, setIsOpentFilter] = useState(false);

  const [index, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };
  // const [excel, setExcel] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [loadingExcle, setLoadingExcle] = useState(false);
  const exportToExcel = async () => {
    setLoadingExcle(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // Authorization: `Bearer ${getToken()}`,
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
        locationId,
      },
      responseType: "blob",
    };

    try {
      const response = await api.get("type_medical/excel", config);

      const blob = new Blob([response.data], { type: "text/csv" });
      if (response.status == 200) {
        setLoadingExcle(false);
      }
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = "پێداویستی پزیشکی.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const handleSearch = (columnToClear) => {
    const {
      fullname,
      totalOf,
      type_of_help,
      type_of_test,
      hospital,
      place,
      date,
    } = searchParams;
    // console.log(hospital);
    const updatedSearchParams = {
      hospital: columnToClear === "hospital" ? "" : hospital,
      fullname: columnToClear === "fullname" ? "" : fullname,

      totalOf: columnToClear === "totalOf" ? "" : totalOf,
      type_of_help: columnToClear === "type_of_help" ? "" : type_of_help,
      place: columnToClear === "place" ? "" : place,
      type_of_test: columnToClear === "type_of_test" ? "" : type_of_test,
      date: columnToClear === "date" ? "" : date,
    };

    setIsOpentFilter(false);
    dispatch(
      getTypeMedicalAction(
        locationId,
        currentPage,
        perPage,
        updatedSearchParams.fullname,
        updatedSearchParams.type_of_help,
        updatedSearchParams.place,
        updatedSearchParams.hospital,
        updatedSearchParams.type_of_test,
        updatedSearchParams.totalOf,
        updatedSearchParams.date,
        from,
        to
      )
    );
  };
  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClearSearchChange = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };
  return (
    <>
      {loading || loadingExcle ? (
        <Loading/>
      ) : (
        <>
          <div className="flex py-1 gap-2">
            <button onClick={exportToExcel}>
              <div className="flex items-center justify-between duration-500 text-white gap-2 ml-5 bg-green-500 bg-opacity-75 hover:bg-opacity-90 rounded-md px-4 py-2 shadow-md cursor-pointer">
                <p>Export </p>
                <span>
                  <RiFileExcel2Fill />
                </span>
              </div>
            </button>
            <div className="flex gap-2 items-center ">
              <input
                type="date"
                onChange={(e) => setFrom(e.target.value)}
                className="inputUser w-full"
              />
              <div>
                <label htmlFor="">To</label>
              </div>
              <div>
                <input
                  type="date"
                  onChange={(e) => setTo(e.target.value)}
                  className="inputUser w-full"
                />
              </div>
            </div>
          </div>
          <div className="    w-[98%] m-auto  sm:rounded-lg  mt-1">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center px-4 gap-3">
                <p className="text-teal-500">
                  <FaSuitcaseMedical />
                </p>
                <h1>Medical Supplies</h1>
              </div>
              <div className="xl:w-1/12 mt-1 mr-2">
                <select
                  name="type_of_help"
                  onChange={handleSearchParams}
                  value={searchParams.type_of_help}
                  className="select"
                  id=""
                >
                  <option value="" selected>
                    تکایە جۆرەکە هەڵبژێرە
                  </option>
                  {typeMedicals?.map((type, index) => {
                    return (
                      <option value={type.name} key={index}>
                        {type.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <hr />
            <div className="bg-white h-[75vh] ">
              <div className="overflow-y-auto w-full h-[90%]">
                <table className="w-full">
                  <thead className="sticky top-0 ">
                    <tr>
                      <th className=" pl-5 border">#</th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1"> Full Name</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(1)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 1
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder=" Full Name"
                            value={searchParams.fullname}
                            name="fullname"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("fullname");
                                handleClearSearchChange("fullname");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1"> Type Of Help</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(2)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 2
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder="Type Of Help"
                            value={searchParams.type_of_help}
                            name="type_of_help"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("type_of_help");
                                handleClearSearchChange("type_of_help");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1">Place</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(3)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 3
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder="Place"
                            value={searchParams.place}
                            name="place"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("place");
                                handleClearSearchChange("place");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1">Hospital</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(4)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 4
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder="Hospital"
                            value={searchParams.hospital}
                            name="hospital"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("hospital");
                                handleClearSearchChange("hospital");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1">Type Of Test</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(5)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 5
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder="Type Of Test"
                            value={searchParams.type_of_test}
                            name="type_of_test"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("type_of_test");
                                handleClearSearchChange("type_of_test");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1"> Total</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(6)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 6
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder=" Total"
                            value={searchParams.totalOf}
                            name="totalOf"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("totalOf");
                                handleClearSearchChange("totalOf");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className=" relative ">
                        <div className="flex justify-between items-center">
                          <p className="pl-1"> Date</p>
                          <div
                            className="filterIcon"
                            onClick={() => handleOpenFilter(7)}
                          >
                            <CiFilter />
                          </div>
                        </div>

                        <div
                          className={
                            isOpenFilter && index == 7
                              ? "parentInputSearch"
                              : "hidden"
                          }
                        >
                          <input
                            type="text"
                            className="inputSearch"
                            placeholder=" Date"
                            value={searchParams.date}
                            name="date"
                            onChange={handleSearchChange}
                          />
                          <div className="flex  gap-2 mt-2">
                            <button
                              onClick={handleSearch}
                              className="btnIndigoSearch "
                            >
                              <FaCheck />
                            </button>

                            <button
                              onClick={() => {
                                handleSearch("date");
                                handleClearSearchChange("date");
                              }}
                              className="btnRedSearch"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </th>

                      <th className="thead">Action</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {data.map((data, index) => {
                      return (
                        <tr className="tableRow" key={index}>
                          <td className="py-2">{index + 1}</td>
                          <td>{data.patients.Pname}</td>
                          <td>{data.type_of_help}</td>
                          <td>{data.place}</td>
                          <td>{data.hospital}</td>
                          <td>{data.type_of_test}</td>
                          <td>{data.total}</td>
                          <td>{data.date}</td>
                          <td
                            onClick={() => {
                              setOpenModal(true);
                              setPatientId(data.patients.id);
                              setTypeMedicalId(data.id);
                            }}
                            className="bg-blue-500 text-white m-auto "
                          >
                            <p className="text-center ml-10 ">
                              <BsCardImage />
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex w-full pr-2 justify-end">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  // activeClassName=''
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      // getPatient(locationID, currentPage, perPage, "", "", "", "", ""
                      getTypeMedicalAction(
                        locationId,
                        currentPage,
                        perPage,
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        ""
                      )
                    );
                    setCurrentPage(currentPage);
                  }}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {openModal && (
        <TypeMedicalModal
          patientId={patientId}
          typeMedcaliId={typeMedcaliId}
          closeModal={setOpenModal}
        />
      )}

      <div className="pb-20"></div>
    </>
  );
};

export default MedicalSuplies;
