import React, { useEffect, useState } from "react";
import { FaUserGear } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { HiDocumentArrowUp } from "react-icons/hi2";
import Logo from "../../../Image/Logo.jpg";
import ReactPaginate from "react-paginate";
import { FaFilter, FaTrash } from "react-icons/fa";
import { MdOutlineChangeCircle, MdSend } from "react-icons/md";
import { HiOutlineDocumentDownload } from "react-icons/hi";

import AdminstratorModal from "../../../Modal/AdminstratorModal/AdminstratorModal";
import {
  deleteSentFilesAdminstratorAction,
  getSentFileAdminstratorAction,
  updateSentFilesAdminstratorAction,
} from "../../../Action/AdminstratorAction/SentFilesAction";
import TransferSentFileAdminstratorModal from "../../../Modal/AdminstratorModal/TransferSentFileAdminstratorModal";
import { getUserAuth } from "../../../Action/userRegister";
const SentFile = () => {
  const [isOpenAdminstrator, setIsOpenAdminstrator] = useState(false);
  const getSentFilesAdminstrator = useSelector(
    (state) => state.getSentFilesAdminstrator
  );

  const locationId = localStorage.getItem("locationId");
  const [fileId, setFileId] = useState(null);
  const { datas, loading, total } = getSentFilesAdminstrator;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [location, setLocation] = useState(null);
  const [number, setNumber] = useState(null);
  const [nameFile, setNameFile] = useState(null);
  const [oldNameFile, setOldNameFile] = useState(null);
  const [date, setDate] = useState(null);
  const [document, setDocument] = useState(null);
  const [searchParams, setSearchParams] = useState({
    number: "",
    location: "",
    document: "",
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [indexx, setIndexx] = useState(null);
  const handleDelete = (id, name_file, extension) => {
    dispatch(deleteSentFilesAdminstratorAction(id, name_file, extension));
    // console.log(name_file)
    dispatch(
      getSentFileAdminstratorAction(
        currentPage,
        perPage,
        searchParams.location,
        searchParams.number,
        searchParams.document,
        users.location_id
      )
    );
    dispatch(
      getSentFileAdminstratorAction(
        currentPage,
        perPage,
        searchParams.location,
        searchParams.number,
        searchParams.document,
        users.location_id
      )
    );
  };
  useEffect(() => {
    dispatch(getUserAuth());
  }, []);
  const getUsers = useSelector((state) => state.getUser);
  const { users } = getUsers;

  useEffect(() => {
    dispatch(
      getSentFileAdminstratorAction(
        currentPage,
        perPage,
        searchParams.location,
        searchParams.number,
        searchParams.document,
        locationId
      )
    );
  }, [currentPage, perPage, searchParams]);
  const handleUpdate = (id, extention) => {
    if (document == null) {
      return;
    }

    dispatch(
      updateSentFilesAdminstratorAction(
        id,
        nameFile,
        oldNameFile,
        date,
        number,
        location,
        document,
        extention
      )
    );
    dispatch(
      getSentFileAdminstratorAction(
        currentPage,
        perPage,
        searchParams.location,
        searchParams.number,
        searchParams.document,
        locationId
      )
    );
    dispatch(
      getSentFileAdminstratorAction(
        currentPage,
        perPage,
        searchParams.location,
        searchParams.number,
        searchParams.document,
        locationId
      )
    );
    setIndexx(null);
  };

  const [isOpenTransferSentFileModal, setIsOpenTransferSentFileModal] =
    useState(false);

  return (
    <>
      {loading || datas == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentPrimary">
          <div className="parentHeadPrimary">
            <div className="flex gap-2">
              <p className="text-blue-500 text-xl">
                <FaUserGear />
              </p>
              <div>
                <p className="font-bold">Administrator</p>
              </div>
            </div>

            <div>
              <button
                onClick={() => setIsOpenAdminstrator(true)}
                className="bg-blue-700 bg-opacity-70 hover:bg-opacity-95  px-3 py-1 rounded-md shadow-sm text-white"
              >
                Administrator
              </button>
            </div>
          </div>
          <div className="parentTablePrimary">
            <table className="tablePrimary">
              <thead>
                <tr>
                  <th className="text-left">#</th>
                  <th className="text-left w-[20%] px-1">
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center">
                        <p className="">Location</p>
                        <div
                          onClick={() => setIsOpenFilter(!isOpenFilter)}
                          className="filterIcon bg-blue-400 hover:bg-blue-500"
                        >
                          <p className="">
                            <FaFilter />
                          </p>
                        </div>
                      </div>
                      <div>
                        {isOpenFilter && (
                          <p>
                            <input
                              type="text"
                              className="inputUser p-1 w-full  mt-0.5 "
                              placeholder="Location"
                              onBlur={(e) =>
                                setSearchParams({
                                  ...searchParams,
                                  location: e.target.value,
                                })
                              }
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </th>
                  <th className="text-left w-[20%] px-1">
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center">
                        <p className="">Number</p>
                        <div
                          onClick={() => setIsOpenFilter(!isOpenFilter)}
                          className="filterIcon bg-blue-400 hover:bg-blue-500"
                        >
                          <p className="">
                            <FaFilter />
                          </p>
                        </div>
                      </div>
                      <div>
                        {isOpenFilter && (
                          <p>
                            <input
                              type="text"
                              className="inputUser p-1 w-full  mt-0.5 "
                              placeholder="Number"
                              onBlur={(e) =>
                                setSearchParams({
                                  ...searchParams,
                                  number: e.target.value,
                                })
                              }
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </th>
                  <th className="text-left w-[20%] px-1">
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center">
                        <p className="">Date</p>
                        <div
                          onClick={() => setIsOpenFilter(!isOpenFilter)}
                          className="filterIcon bg-blue-400 hover:bg-blue-500"
                        >
                          <p className="">
                            <FaFilter />
                          </p>
                        </div>
                      </div>
                      <div>
                        {isOpenFilter && (
                          <p>
                            <input
                              type="date"
                              className="inputUser p-1 w-full  mt-0.5 "
                              placeholder="Date"
                              onBlur={(e) =>
                                setSearchParams({
                                  ...searchParams,
                                  location: e.target.value,
                                })
                              }
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </th>
                  <th className="text-left w-[20%] px-1">
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center">
                        <p className="">Documents</p>
                        <div
                          onClick={() => setIsOpenFilter(!isOpenFilter)}
                          className="filterIcon bg-blue-400 hover:bg-blue-500"
                        >
                          <p className="">
                            <FaFilter />
                          </p>
                        </div>
                      </div>
                      <div>
                        {isOpenFilter && (
                          <p>
                            <input
                              type="text"
                              className="inputUser p-1 w-full  mt-0.5 "
                              placeholder="Documents"
                              onBlur={(e) =>
                                setSearchParams({
                                  ...searchParams,
                                  document: e.target.value,
                                })
                              }
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </th>
                  <th className="text-left w-[20%]">Action</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, index) => {
                  return (
                    <tr key={index} className="tableRow">
                      <td>{index + 1}</td>
                      <td>
                        {indexx == index ? (
                          <input
                            type="text"
                            className="inputUser p-1"
                            placeholder="Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                        ) : (
                          data.location
                        )}
                      </td>
                      <td>
                        {indexx == index ? (
                          <input
                            type="text"
                            className="inputUser p-1"
                            placeholder="Number"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                          />
                        ) : (
                          data.number
                        )}
                      </td>
                      <td>
                        {indexx == index ? (
                          <input
                            type="date"
                            className="inputUser p-1"
                            placeholder="Date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                          />
                        ) : (
                          data.date
                        )}
                      </td>
                      <td className="text-xl  text-blue-500">
                        {indexx == index ? (
                          <div className="flex gap-2 w-full items-center">
                            <div>
                              <input
                                type="text"
                                placeholder="ناوی فایل"
                                className="inputUser  p-1"
                                value={nameFile}
                                onChange={(e) => setNameFile(e.target.value)}
                              />
                            </div>
                            <div className="flex flex-col w-4/12">
                              <div className=" mt-1 w-full flex  items-center">
                                <label
                                  id="large_size"
                                  className="p-2 flex w-full flex-col justify-center m-auto items-center gap-2  bg-teal-100 text-teal-500 rounded-lg hover:bg-teal-200 cursor-pointer"
                                >
                                  <p>
                                    {data.name_file}
                                    <HiOutlineDocumentDownload className="w-4 h-4" />
                                  </p>

                                  <input
                                    onChange={(e) => {
                                      setDocument(e.target.files[0]);
                                    }}
                                    id="large_size"
                                    accept=".doc,.docx,.pdf,.pptx,.xlsx,.xls"
                                    type="file"
                                    className="hidden"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <Link
                            to={`${data.document}`}
                            target="_blank"
                            className="flex items-center gap-2"
                          >
                            <p>{data.name_file}</p>
                            <HiDocumentArrowUp />{" "}
                          </Link>
                        )}
                      </td>

                      <td>
                        <div className="flex gap-2 items-center justify-center">
                          {indexx == index ? (
                            <button
                              onClick={() => {
                                handleUpdate(
                                  data.id,
                                  data.document.split(".").pop()
                                );
                              }}
                              title="Update"
                              className="bg-blue-500 hover:bg-blue-600 rounded-md shadow-md px-3 py-2 duration-500 text-white"
                            >
                              <MdOutlineChangeCircle />
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setIndexx(index);
                                setLocation(data.location);
                                setNumber(data.number);
                                setNameFile(data.name_file);
                                setOldNameFile(data.name_file);
                                setDate(data.date);
                              }}
                              title="Update"
                              className="bg-blue-500 hover:bg-blue-600 rounded-md shadow-md px-3 py-2 duration-500 text-white"
                            >
                              <MdOutlineChangeCircle />
                            </button>
                          )}

                          <button
                            title="Delete"
                            onClick={() => {
                              handleDelete(
                                data.id,
                                data.name_file,
                                data.document.split(".").pop()
                              );
                            }}
                            className="bg-red-500 hover:bg-red-600 rounded-md shadow-md px-3 py-2 duration-500 text-white "
                          >
                            <FaTrash />
                          </button>

                          <button
                            title="Send"
                            onClick={() => {
                              setFileId(data.id);
                              setIsOpenTransferSentFileModal(true);
                            }}
                            className="bg-violet-500 hover:bg-violet-600 rounded-md shadow-md px-3 py-2 duration-500 text-white "
                          >
                            <MdSend />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isOpenAdminstrator && (
            <AdminstratorModal closeModal={setIsOpenAdminstrator} />
          )}
          {isOpenTransferSentFileModal && (
            <TransferSentFileAdminstratorModal
              id={fileId}
              closeModal={setIsOpenTransferSentFileModal}
            />
          )}
          <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
            <div className="flex items-center gap-4 ">
              <p className="text-xl ">Total: </p>
              <div
                className={
                  "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                }
              >
                {total}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  value={perPage}
                  id=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center   mt-1">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  // activeClassName=''
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      getSentFileAdminstratorAction(
                        currentPage,
                        perPage,
                        location,
                        number,
                        document,
                        locationId
                      )
                    );
                    setCurrentPage(currentPage);
                  }}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SentFile;
