import Swal from "sweetalert2";
import {
  CREATE_FEEDBACK_FAIL,
  CREATE_FEEDBACK_REQUEST,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_SAVE_FEEDBACK_FAIL,
  CREATE_SAVE_FEEDBACK_REQUEST,
  CREATE_SAVE_FEEDBACK_SUCCESS,
  FETCH_EDIT_FEEDBACK_FAIL,
  FETCH_EDIT_FEEDBACK_REQUEST,
  FETCH_EDIT_FEEDBACK_SUCCESS,
  FETCH_FEEDBACK_FAIL,
  FETCH_FEEDBACK_IMAGE_FAIL,
  FETCH_FEEDBACK_IMAGE_REQUEST,
  FETCH_FEEDBACK_IMAGE_SUCCESS,
  FETCH_FEEDBACK_REQUEST,
  FETCH_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_FAIL,
  UPDATE_FEEDBACK_REQUEST,
  UPDATE_FEEDBACK_SUCCESS,
} from "../ActionType/lezhnaActionType";
import { api } from "../Api/api";
import { getToken } from "./login";

export const addfeedback =
  (
    status,
    visit_id,
    lezhna_id,
    commite_id,
    amountofLoan,
    amountOfRent,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    economicStatus,
    typeofVehicle,
    typeofLandEstimatedPrice,
    amountofSalary,
    resonOfDebt,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    phoneNumber,
    note,
    numberOfland,
    nowDate,
    numberOfHouse,
    alive,
    salaryEmploye,
    poverty,
    house,
    teanant,
    Vehicle,
    land,
    debt,
    monthlyIncome,
    mercy,
    typeOfDeasise,

    // relativesHelp,
    child,
    moreThan40,
    smoke,
    masqoud,
    helpingNear,
    businessman,

    typeOfOccupation,
    placeOfOccupation,
    totalSalary,
    WHBATFUP,
    phoneMemeber,
    nameMember,
    smoker,
    workOfSons,
    parentOrBortherJob,
    supervisor,
    typeOfBussnis,
    incomeOfBussnisse,
    compareHouseHoldExpenses,
    secound_work,
    type_of_house
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    Swal.fire({
      title: 'Please Wait',
      html: 'Please wait Until Data Was Send.', // You can customize this text
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
    api
      .post(
        "lezhna/create",
        {
          status,
          lezhna_id,
          numberOfHouse,
          visit_id: visit_id,
          commite_id: commite_id,
          metters: metters,
          NeighborhoodTypeselect: NeighborhoodTypeselect,
          amountOfRent,
          govOrPriveteEmp: govOrPriveteEmp,
          economicStatus,
          typeofVehicle,
          typeofLandEstimatedPrice,

          // monthlyIncome,
          amountofSalary,
          amountofLoan,
          haveOtherDiseases,
          Typeofdisease,
          numberofChildren,
          numberofStudent,
          monthlyIncomeFamily,
          PovertyRates,
          HMCabilitToSurgery,
          PMediaWork,
          relationship,
          Name,
          resonOfDebt,
          phoneNumber,
          note,
          numberOfLand: numberOfland,
          date: nowDate,
          alive,
          salaryEmploye,
          poverty,
          house,
          teanant,
          Vehicle,
          land,
          debt,
          monthlyIncome,
          mercy,
          typeOfDeasise,

          // relativesHelp,
          child,
          more_than_40: moreThan40,
          smoke,
          masqoud,
          helping_near: helpingNear,
          businessman,

          type_of_occupation: typeOfOccupation,
          place_of_occupation: placeOfOccupation,
          total_salary: totalSalary,
          WHBATFUP,
          phone_memeber: phoneMemeber,
          name_member: nameMember,
          smoker,
          work_of_sons: workOfSons,
          parent_or_brother_job: parentOrBortherJob,
          supervisor,
          type_of_bussniss: typeOfBussnis,
          income_of_bussniss: incomeOfBussnisse,
          compare_house_hold_expenses: compareHouseHoldExpenses,
          secound_work,
          type_of_house
        },
        config
      )
      .then((response) => {
        // const { status } = response;
        dispatch({ type: CREATE_FEEDBACK_REQUEST });
        if (response.status == 200) {
          const data = response.data.update;

          dispatch({ type: CREATE_FEEDBACK_SUCCESS, payload: data });
          Swal.fire({
            icon: "success",
            title: "Good Job ",
            text: "Feedback Was Saved",
            timer: 1500,
          });
        } else if (response.data.status == 404) {
          console.log(response.data);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Some thing Went wrong",
          });
        }
      })
      .catch((error) => {
        dispatch({ type: CREATE_FEEDBACK_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getFeedbackAction =
  (locationId, code, fullname, gender, birthDate, phone, page, perPage) =>
  async (dispatch) => {
    dispatch({ type: FETCH_FEEDBACK_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        code,
        fullname,
        gender,
        birthDate,
        phone,
        page,
        perPage,
        branch_id:locationId
      },
    };

   await api
      .get(`lezhna`, config)
      .then((resposne) => {
        dispatch({ type: FETCH_FEEDBACK_REQUEST });
        const { data, status } = resposne;
        console.log("data : ",data)
        if (status == 200) {
          dispatch({
            type: FETCH_FEEDBACK_SUCCESS,
            payload: data.data,
            total: data.total,
          });
        }
      })
      .catch(() => {
        dispatch({ type: FETCH_FEEDBACK_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const saveFeebackAction =
  (
    status,
    visit_id,
    lezhna_id,
    commite_id,
    amountofLoan,
    amountOfRent,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    economicStatus,
    typeofVehicle,
    typeofLandEstimatedPrice,
    amountofSalary,
    resonOfDebt,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    phoneNumber,
    note,
    numberOfland,
    nowDate,
    numberOfHouse,
    alive,
    salaryEmploye,
    poverty,
    house,
    teanant,
    Vehicle,
    land,
    debt,
    monthlyIncome,
    mercy,
    typeOfDeasise,

    child,
    moreThan40,
    smoke,
    masqoud,
    helpingNear,
    businessman,

    typeOfOccupation,
    placeOfOccupation,
    totalSalary,
    WHBATFUP,
    phoneMemeber,
    nameMember,
    smoker,
    workOfSons,
    parentOrBortherJob,
    supervisor,
    typeOfBussnis,
    incomeOfBussnisse,
    compareHouseHoldExpenses,
    secound_work,
    type_of_house
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    Swal.fire({
      title: 'Please Wait',
      html: 'Please wait Until Data Was Send.', // You can customize this text
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
    
    
    api
      .post(
        "lezhna/save",
        {
          status,
          lezhna_id,
          numberOfHouse,
          visit_id: visit_id,
          commite_id: commite_id,
          metters: metters,
          NeighborhoodTypeselect: NeighborhoodTypeselect,
          amountOfRent,
          govOrPriveteEmp: govOrPriveteEmp,
          economicStatus,
          typeofVehicle,
          typeofLandEstimatedPrice,

          // monthlyIncome,
          amountofSalary,
          amountofLoan,
          haveOtherDiseases,
          Typeofdisease,
          numberofChildren,
          numberofStudent,
          monthlyIncomeFamily,
          PovertyRates,
          HMCabilitToSurgery,
          PMediaWork,
          relationship,
          Name,
          resonOfDebt,
          phoneNumber,
          note,
          numberOfLand: numberOfland,
          date: nowDate,
          alive,
          salaryEmploye,
          poverty,
          house,
          teanant,
          Vehicle,
          land,
          debt,
          monthlyIncome,
          mercy,
          typeOfDeasise,

          child,
          more_than_40: moreThan40,
          smoke,
          masqoud,
          helping_near: helpingNear,
          businessman,

          type_of_occupation: typeOfOccupation,
          place_of_occupation: placeOfOccupation,
          total_salary: totalSalary,
          WHBATFUP,
          phone_memeber: phoneMemeber,
          name_member: nameMember,
          smoker,
          work_of_sons: workOfSons,
          parent_or_brother_job: parentOrBortherJob,
          supervisor,
          type_of_bussniss: typeOfBussnis,
          income_of_bussniss: incomeOfBussnisse,
          compare_house_hold_expenses: compareHouseHoldExpenses,
          secound_work,
          type_of_house
        },
        config
      )
      .then((response) => {
        dispatch({ type: CREATE_SAVE_FEEDBACK_REQUEST });
        // console.log(status);
        if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: "Feedback Was Saved",
            timer: 1500,
          });
          const { feedback } = response.data;
          dispatch({ type: CREATE_SAVE_FEEDBACK_SUCCESS, payload: feedback });
        } else if (response.status == 500) {
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: "Please Fill All Gaps",
          // });
        }
      })
      .catch((error) => {
        dispatch({ type: CREATE_SAVE_FEEDBACK_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const editFeedbackAction = (id, visit_id) => (dispatch) => {


  dispatch({ type: FETCH_EDIT_FEEDBACK_REQUEST });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      visit_id,
    },
  };
  dispatch({ type: FETCH_EDIT_FEEDBACK_REQUEST });
  api
    .get(`lezhna/edit/${id}`, config)
    .then((resposne) => {
      

      const { status, data } = resposne;
      console.log("zz  : ",resposne)

      if (status == 200) {
        dispatch({ type: FETCH_EDIT_FEEDBACK_SUCCESS, payload: data });
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch({ type: FETCH_EDIT_FEEDBACK_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const updateFeedbackAction =
  (
    color,
    lezhnaId,
    patientId,
    amountOfRent,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    economicStatus,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountOfSalary,
    amountofLoan,
    resonOfDebt,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    phoneNumber,
    note,
    alive,
    salaryEmploye,
    house,
    teanant,
    Vehicle,
    land,
    debt,
    currentDate,
    numberOfLand,
    poverty
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    api
      .post(
        "lezhna/update",
        {
          color,
          lezhnaId,
          patientId,
          amountOfRent,
          govOrPriveteEmp,
          metters,
          NeighborhoodTypeselect,
          economicStatus,
          typeofVehicle,
          typeofLandEstimatedPrice,
          monthlyIncome,
          amountOfSalary,
          amountofLoan,
          resonOfDebt,
          haveOtherDiseases,
          Typeofdisease,
          numberofChildren,
          numberofStudent,
          monthlyIncomeFamily,
          PovertyRates,
          HMCabilitToSurgery,
          PMediaWork,
          relationship,
          Name,
          phoneNumber,
          note,
          alive,
          salaryEmploye,
          house,
          teanant,
          Vehicle,
          land,
          debt,
          date: currentDate,
          numberOfLand,
          poverty,
        },
        config
      )
      .then((response) => {
        const { status } = response.data;
        if (status === 200) {
          dispatch({ type: UPDATE_FEEDBACK_REQUEST });
          Swal.fire("Good job!", "Feedback Was Saved", "success");
          const { data } = response.data;
          console.log(response.data);
          dispatch({ type: UPDATE_FEEDBACK_SUCCESS, paylaod: data });
        } else if (status == 404) {
          console.log(response.data);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Some thing Went wrong",
          });
        }
      })
      .catch(() => {
        dispatch({ type: UPDATE_FEEDBACK_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };
export const getFeedbackImageAction = (patient_id) => (dispatch) => {
  dispatch({
    type: FETCH_FEEDBACK_IMAGE_REQUEST,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`lezhna/showImage/${patient_id}`, config)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: FETCH_FEEDBACK_IMAGE_SUCCESS,
          payload: data,
          error: "",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FEEDBACK_IMAGE_FAIL,
        payload: [],
        error: "",
      });
      console.log(error);
    });
};
