import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../Image/Logo.jpg";
import {
  getWhatsAppInfoByVisitAction,
  
  sendWhatsAppAction,
} from "../../../Action/WhatsAppAction/whatsAppAction";
import { getOrganLevelAction } from "../../../Action/SurgeryTypeAction/surgery";
import { getDoctorAction } from "../../../Action/Doctor/doctorAction";
import Swal from "sweetalert2";
import { AiOutlineSend } from "react-icons/ai";
import { updateColorCommiteAction } from "../../../Action/commiteAction";
const SurgeryWahtsApp = ({ data, visit_id, location_id, commiteId }) => {
  const dispatch = useDispatch();
  const getWhatsAppInByVisit = useSelector(
    (state) => state.getWhatsAppInByVisit
  );
  const { whatsAppInfo, loading } = getWhatsAppInByVisit;
  const [selectOptsOrgan, setSelectOptsOrgan] = useState([]);
  const [openOrganLevel, setOpenOrganLevel] = useState(false);
  const option3 = [];

  const [inputValue, setInputValue] = useState({
    whatsAppId: "",
    fulllname: "",
    helpingDoctor: "",
    doctor: "",
    zad: "",
    totalOfSurgery: "",
    hospital: "",
    date: "",
    totalOfHospital: "",
    totalOfPatient: "",
    surgery: "بەڵێ",
  });
  useEffect(() => {
    dispatch(getOrganLevelAction(0));
    dispatch(getDoctorAction());
  }, []);

  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    dispatch(getWhatsAppInfoByVisitAction(visit_id));
  }, [visit_id]);

  useEffect(() => {
    if (whatsAppInfo == undefined) {
      return;
    }
    // console.log(whatsAppInfo);
    whatsAppInfo.map((info) => {
      return setInputValue({
        whatsAppId: info.id,
        doctor: info.doctor,
        hospital: info.hospital,
        totalOfSurgery: info.total_of_surgery,
        zad: info.zad,
        totalOfPatient: info.total_of_patient,
        totalOfHospital: info.total_of_hospital,
        date: info.date,
        helpingDoctor: info.helpDr,
        surgery:"بەڵێ"
      });
    });
  }, [visit_id, whatsAppInfo]);
  // const fetchDoctorArray = [];

  let doctorArray = [];

  doctors.map((doctor) => {
    doctorArray.push({
      value: doctor.dr_name,
      label: doctor.dr_name,
    });
  });
  const [doctor, setDoctor] = useState([]);
  const [opens, setOpens] = useState(false);
  const toggleOptionDoctor = (value) => {
    if (doctor.includes(value)) {
      setDoctor(doctor.filter((opt) => opt !== value));
    } else {
      setDoctor([...doctor, value]);
    }
  };
  useEffect(() => {
    if (data == undefined || doctors == undefined) {
      return;
    }

    data.map((data) => {
      data.commites
        .filter((filter) => filter.visit_id == visit_id)
        .map((commite) => {
          if (selectOptsOrgan.length < commite.memberLevel.length) {
            setSelectOptsOrgan(...selectOptsOrgan, commite.memberLevel);
          }

          if (doctor.length < commite.Doctor.length) {
            setDoctor(...doctor, commite.Doctor);
          }
          setInputValue({
            ...inputValue,
            fulllname: data.Pname,
            doctor: commite.Doctor,
          });
        });
    });
  }, [data, visit_id]);

  function formatNumberWithCommas(number) {
    // console.log(number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, " : value : ", value);
    const input = value.replace(/[^0-9]/g, "");
    const number = parseInt(input, 10);
    const formattedAmount = formatNumberWithCommas(number);

    setInputValue({
      ...inputValue,
      [name]: formattedAmount,
    });
  };

  const {
    fulllname,
    hospital,
    zad,
    totalOfHospital,
    totalOfPatient,
    totalOfSurgery,
    date,
    helpingDoctor,
  } = inputValue;
  const [error, setError] = useState({
    total_of_surgery: "",
    doctor: "",
    zad: "",
    organLevel: "",
    hospital: "",
    date: "",
    totalOfHospital: "",
    totalOfPatient: "",
    helpingDoctor: "",
  });
  const handleSend = (event) => {
    event.preventDefault();

    if (location_id == null) {
      Swal.fire({
        icon: "error",
        text: "تکایە لقێک هەڵبژێرە بۆ ناردنی زانیاری",
        timer: 2000,
      });
      return;
    }
 
    if (doctor == "" || doctor == null) {
      setError({
        ...error,
        total_of_surgery: "",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
        doctor: "تکایە خانەکە پڕکەرەوە",
      });
      return;
    }
    if (selectOptsOrgan == "" || selectOptsOrgan.length == 0) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "تکایە خانەکە پڕکەرەوە",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
      });
      return;
    }
    if (inputValue.zad == "" || inputValue.zad == null) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "تکایە خانەکە پڕکەرەوە",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
      });
      return;
    }
    if (totalOfSurgery == "" || totalOfSurgery == null) {
      setError({
        ...error,
        total_of_surgery: "تکایە خانەکە پڕکەرەوە",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
      });
      return;
    }

    if (hospital == "" || hospital == null) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "",
        hospital: "تکایە خانەکە پڕکەرەوە",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
      });
      return;
    }

    if (date == "" || date == null) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "تکایە خانەکە پڕکەرەوە",
        totalOfHospital: "",
        totalOfPatient: "",
      });
      return;
    }
    if (totalOfHospital == "" || totalOfHospital == null) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "تکایە خانەکە پڕکەرەوە",
        totalOfPatient: "",
      });
      return;
    }
    if (totalOfPatient == "" || totalOfPatient == null) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "تکایە خانەکە پڕکەرەوە",
      });
      return;
    }
    if (helpingDoctor == "" || helpingDoctor == null) {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
        helpingDoctor: "تکایە خانەکە پڕکەرەوە",
      });
      return;
    } else {
      setError({
        ...error,
        total_of_surgery: "",
        doctor: "",
        zad: "",
        organLevel: "",
        hospital: "",
        date: "",
        totalOfHospital: "",
        totalOfPatient: "",
        doctor: "",
      });

      dispatch(
        sendWhatsAppAction(
          inputValue.whatsAppId,
          visit_id,
          location_id,
          fulllname,
          selectOptsOrgan,
          doctor,
          hospital,
          totalOfSurgery,
          totalOfHospital,
          date,
          totalOfPatient,
          zad,
          helpingDoctor
        )
      );
      
      dispatch(getWhatsAppInfoByVisitAction(visit_id));
      dispatch(getWhatsAppInfoByVisitAction(visit_id));
      dispatch(updateColorCommiteAction(commiteId, inputValue.surgery,"",inputValue.date));
    }
  };

  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;

  organLevel.map((organ) => {
    option3.push(organ.organ_level_name);
  });

  const toggleOptionTypeOfHelp = (value) => {
    if (selectOptsOrgan.includes(value)) {
      setSelectOptsOrgan(selectOptsOrgan.filter((opt) => opt !== value));
    } else {
      setSelectOptsOrgan([...selectOptsOrgan, value]);
    }
  };

  const handleChange = () => {
    if (
      inputValue.helpingDoctor == undefined ||
      inputValue.zad == undefined ||
      inputValue.totalOfHospital == undefined ||
      inputValue.totalOfPatient == undefined
    ) {
      return;
    }
    const inputValueWithoutCommas = inputValue.zad.replace(/,/g, "");
    const helpDoctor = inputValue.helpingDoctor.replace(/,/g, "");
    const totalOfHospital = inputValue.totalOfHospital.replace(/,/g, "");
    const totalOfPatient = inputValue.totalOfPatient.replace(/,/g, "");
    const total =
      parseInt(inputValueWithoutCommas, 10) +
      parseInt(helpDoctor, 10) +
      parseInt(totalOfHospital, 10) +
      parseInt(totalOfPatient, 10);

    setInputValue({
      ...inputValue,
      totalOfSurgery: formatNumberWithCommas(total),
    });
  };
  return (
    <>
      {whatsAppInfo == undefined || loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse my-5 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="title">
            <h1 className="text-center">ناردنی زانیاری نەشتەرگەری</h1>
          </div>

          <form action="" className=" text-right mt-4">
            <div className="flex gap-2 w-full mt-2">
              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * ناوی نەخۆش{" "}
                </label>
                <input
                  type="text"
                  value={inputValue.fulllname}
                  onChange={handleInputChange}
                  className="inputUser text-right"
                  placeholder="ناو نەخۆش"
                  name="fullname"
                />
              </div>
              <div className="w-full text-right  relative">
                <div className="relative mt-2">
                  <label htmlFor="" className="mb-2">
                    * (جۆری نەشتەرگەری) لەسەر ئاستی ئەندام{" "}
                  </label>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenOrganLevel(!openOrganLevel);
                    }} // Updated variable name
                    className="  border-2 shadow-md rounded-md bg-white   py-1 w-full px-4 text-left"
                  >
                    {selectOptsOrgan.length > 0
                      ? selectOptsOrgan.join(", ")
                      : "Select options..."}
                  </button>
                  {openOrganLevel && (
                    <div className="absolute z-10 bg-white h-72 overflow-y-auto w-64 border border-gray-300  mt-2">
                      {option3.map((opt) => (
                        <label
                          key={opt}
                          className="flex text-right items-center px-4 py-2"
                        >
                          <input
                            type="checkbox"
                            value={opt}
                            checked={selectOptsOrgan.includes(opt)}
                            onChange={() => toggleOptionTypeOfHelp(opt)}
                            className="mr-2"
                          />
                          {opt}
                        </label>
                      ))}
                    </div>
                  )}
                  <div className="text-red-500 animate-pulse">
                    {error.organLevel}
                  </div>
                </div>
              </div>
              <div className="w-full ">
                <div className="flex justify-end ">
                  <label htmlFor="doctorSend" className="lblDesign">
                    {" "}
                    * دکتۆر
                  </label>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpens(!opens);
                  }} // Updated variable name
                  className="  border-2 shadow-md rounded-md bg-white  py-1 w-full px-4 text-left"
                >
                  {doctor.length > 0 ? doctor.join(" , ") : "Select options..."}
                </button>

                {opens && (
                  <div className="optionMultiple h-72 overflow-y-auto">
                    {doctorArray.map((opt) => (
                      <label
                        key={opt.label}
                        className="flex items-center px-4 py-2"
                      >
                        <input
                          type="checkbox"
                          value={opt.value}
                          checked={doctor.includes(opt.label)}
                          onChange={() => {
                            toggleOptionDoctor(opt.label);
                            // toggleOption(opt.label);
                            // setTypeSurgeryId(opt.value);
                          }}
                          className="mr-2"
                        />
                        {opt.label}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* secound section */}

            <div className="flex gap-2 w-full mt-2">
              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * زاد{" "}
                </label>
                <input
                  type="text"
                  className="inputUser text-right"
                  placeholder="زاد"
                  name="zad"
                  onBlur={handleChange}
                  onChange={handleInputChange}
                  value={inputValue.zad}
                />

                <div className="text-red-500 animate-pulse ">{error.zad}</div>
              </div>

              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * نەخۆشخانە{" "}
                </label>
                <input
                  type="text"
                  className="inputUser text-right"
                  placeholder="نەخۆشخانە"
                  name="totalOfHospital"
                  onChange={handleInputChange}
                  value={inputValue.totalOfHospital}
                  onBlur={handleChange}
                />
                <div className="text-red-500 animate-pulse">
                  {error.totalOfHospital}
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * نەخۆش{" "}
                </label>
                <input
                  type="text"
                  className="inputUser text-right"
                  placeholder="نەخۆش"
                  name="totalOfPatient"
                  onChange={handleInputChange}
                  value={inputValue.totalOfPatient}
                  onBlur={handleChange}
                />
                <div className="text-red-500 animate-pulse">
                  {error.totalOfPatient}
                </div>
              </div>
            </div>

            <div className="flex gap-2 w-full mt-2">
              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * ناوی نەخۆشخانە{" "}
                </label>
                <input
                  type="text"
                  className="inputUser text-right "
                  placeholder="ناوی نەخۆشخانە"
                  onChange={(e) => {
                    setInputValue({ ...inputValue, hospital: e.target.value });
                  }}
                  value={inputValue.hospital}
                  name="hospital"
                />
                <div className="text-red-500 animate-pulse">
                  {error.hospital}
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * کۆی گشتی نەشتەرگەری{" "}
                </label>
                <input
                  type="text"
                  className="inputUser text-right"
                  placeholder="کۆی گشتی نەشتەرگەری"
                  name="totalOfSurgery"
                  disabled
                  // onChange={handleInputChange}
                  value={inputValue.totalOfSurgery}
                />
                <div className="text-red-500 animate-pulse text-base mr-1">
                  {error.total_of_surgery}
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * هاوکاری دکتۆر
                </label>
                <input
                  type="text"
                  value={inputValue.helpingDoctor}
                  onBlur={handleChange}
                  onChange={handleInputChange}
                  className="inputUser text-right"
                  placeholder="هاوکاری دکتۆر"
                  name="helpingDoctor"
                />
              </div>
            </div>
            <div className="flex gap-2 w-full mt-2">
              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * نەشتەرگەری بۆ کراوە
                </label>
                <select
                  name="surgery"
                  onChange={(e) => {
                    setInputValue({
                      ...inputValue,
                      surgery: e.target.value,
                    });
                  }}
                  value={inputValue.surgery}
                  id=""
                >
                  <option value="">تکایە زانیاریەکە هەڵبژێرە</option>
                  <option value="بەڵێ" selected> بەڵێ</option>
                  <option value="نەخێر">نەخێر</option>
                </select>

                <div className="text-red-500 animate-pulse text-base mr-1">
                  {error.surgery}
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="mr-2" htmlFor="full">
                  * بەرواری ئەنجامدان{" "}
                </label>
                <input
                  type="date"
                  className="inputUser text-right"
                  placeholder="بەرواری ئەنجامدان"
                  name="date"
                  onChange={(e) => {
                    setInputValue({
                      ...inputValue,
                      date: e.target.value,
                    });
                  }}
                  value={inputValue.date}
                />
                <div className="text-red-500 animate-pulse">{error.date}</div>
              </div>
            </div>

            <button onClick={handleSend} className={"btn-success mt-2 "}>
              <AiOutlineSend />
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default SurgeryWahtsApp;
