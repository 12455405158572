// import React, { useEffect } from "react";
import "./print.css";
import Logo from "../Image/Logo.jpg";

// import Header from "../Image/header.png";
// import Footer from "../Image/footer.png";
import Header1 from "../Image/Header1.png";

import Footer1 from "../Image/Footer1.png";
import { useEffect, useState } from "react";

const FeedbackPrint = ({ patients }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    phone:"",
    code:"",
    birthDate:"",
    province: "",
    nation: "",
    country: "",
    occuption: "",
    married: "",
    noFamily: "",
    district: "",
    street: "",
    closetHome: "",
    secoundHome: "",
    card: "",
    smoke: "",
    timeAllote: "",
    SITD: "",
    member: "",
    memberLevel: "",
    type_of_help: "",
    TYP: "",
    indiction: "",
    HEP: "",
    finalDiagnosis: "",
    earlyPatient: "",
    earlySurgery: "",
    Doctor: "",
    ZODoctor: "",
    medicalProvided: "",
    Typemedicalsupplies: "",
    supplyLocation: "",
    helpingZO: "",
    literate: "",
    had_surgery: "",
    note: "",
  });
  useEffect(() => {
    patients.map((patient) => {
      patient.commites.map((commite) => {
        setInputValue({
          name:patient.Pname,
          phone:patient.phone,
          birthDate:patient.birthDate,
          code:patient.code,
          province: commite.province,
          nation: commite.nation,
          country: commite.country,
          occuption: commite.occuption,
          married: commite.married,
          noFamily: commite.noFamily,
          district: commite.district,
          street: commite.street,
          closetHome: commite.closetHome,
          secoundHome: commite.secoundHome,
          card: commite.card,
          smoke: commite.smoke,
          timeAllote: commite.timeAllote,
          SITD: commite.SITD,
          member: commite.member,
          memberLevel: commite.memberLevel,
          type_of_help: commite.type_of_help,
          TYP: commite.TYP,
          indiction: commite.indiction,
          HEP: commite.HEP,
          finalDiagnosis: commite.finalDiagnosis,
          earlyPatient: commite.earlyPatient,
          earlySurgery: commite.earlySurgery,
          Doctor: commite.Doctor,
          ZODoctor: commite.ZODoctor,
          medicalProvided: commite.medicalProvided,
          Typemedicalsupplies: commite.Typemedicalsupplies,
          supplyLocation: commite.supplyLocation,
          helpingZO: commite.helpingZO,
          literate: commite.literate,
          had_surgery: commite.had_surgery,
          note: commite.note,
        });
      });
    });
  }, [patients]);
  return (
    <div className="table-print w-[98%] ">
      <div className="  w-[97%] m-auto    rounded-md shadow-md">
        <img src={Header1} className="z-50 w-full absolute -top-2" alt="" />
      </div>
      <div className="mt-[7.5rem] w-[97%] m-auto">
        <div className="greenTitle">
          <p className=" lg:text-xl">فۆرمی زانیاری نەخۆش</p>
        </div>
        <div className="grid grid-cols-4 gap-2">
          {" "}
          <div className="w-full mt-4">
            <div className="flex justify-end ">
              <label htmlFor="province" className="lblDesing">
                * Patient ID{" "}
              </label>
            </div>
            <input
              type="text"
              name="province"
              value={inputValue.code}
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder=" پارێزگا"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-2">
            <div className="w-full flex justify-end ">
              <label htmlFor="nation" className="lblDesign font-normal">
                {" "}
                *لە دایکبوون
              </label>
            </div>
            <input
              type="text"
              name="nation"
              id="nation"
              value={inputValue.birthDate}
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="نەتەوە"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-2">
            <div className="w-full flex justify-end ">
              <label className="lblDesign font-normal" htmlFor="country">
                {" "}
                * ژ.مۆبایل{" "}
              </label>
            </div>
            <input
              type="text"
              name="country"
              id="country"
              value={inputValue.phone}
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="مۆبایل"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-2">
            <div className="w-full flex justify-end ">
              <label htmlFor="name" className="lblDesign font-normal">
                {" "}
                *ناوی سیانی
              </label>
            </div>
            <input
              type="text"
              name="occupation"
              defaultValue={inputValue.name}
              id="name"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="ناو"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2">
          {" "}
          <div className="w-full mt-4">
            <div className="flex justify-end ">
              <label htmlFor="province" className="lblDesing">
                * پارێزگا{" "}
              </label>
            </div>
            <input
              type="text"
              name="province"
              value={inputValue.province}
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder=" پارێزگا"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-2">
            <div className="w-full flex justify-end ">
              <label htmlFor="nation" className="lblDesign font-normal">
                {" "}
                *نەتەوە
              </label>
            </div>
            <input
              type="text"
              name="nation"
              id="nation"
              value={inputValue.nation}
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="نەتەوە"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-2">
            <div className="w-full flex justify-end ">
              <label className="lblDesign font-normal" htmlFor="country">
                {" "}
                * وڵات{" "}
              </label>
            </div>
            <input
              type="text"
              name="country"
              id="country"
              value={inputValue.country}
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="وڵات"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-2">
            <div className="w-full flex justify-end ">
              <label htmlFor="name" className="lblDesign font-normal">
                {" "}
                *پیشە
              </label>
            </div>
            <input
              type="text"
              name="occupation"
              defaultValue={inputValue.occuption}
              id="name"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="پیشە"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2">
          <div></div>
          <div className="w-full mt-2">
            <div className="flex justify-end ">
              <label htmlFor="street" className="lblDesign font-normal">
                *شەقام گەڕەک{" "}
              </label>
            </div>
            <input
              type="text"
              name="street"
              value={inputValue.street}
              id="street"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="شەقام گەڕەک"
            />
          </div>
          {/* end parent Input & label */}

          <div className="w-full mt-2">
            <div className="flex justify-end ">
              <label htmlFor="closetHome" className="lblDesign font-normal">
                *نزیکترین شوێن
              </label>
            </div>
            <input
              type="text"
              name="closetHome"
              value={inputValue.closetHome}
              id="closetHome"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="نزیکترین شوێن لە ماڵیانەوە"
            />
          </div>
          {/* end parent Input & label */}

          <div className="w-full mt-2">
            <div className="flex justify-end ">
              <label htmlFor="disc" className="lblDesign font-normal">
                * ناحیە/قەزا{" "}
              </label>
            </div>
            <input
              type="text"
              name=" ناحیە/قەزا"
              value={inputValue.district}
              id="disc"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="District"
            />
          </div>
          {/* end parent Input & label */}
        </div>
        <div className="grid grid-cols-4 gap-2">
          {/* end parent Input & label */}

          <div className="w-full mt-2">
            <div className="w-full justify-end flex">
              <label htmlFor="scoundPhoneNo" className="lblDesign font-normal">
                *ژ.مۆبایلی دووەم
              </label>
            </div>
            <input
              type="text"
              name="secoundPhoneNo"
              value={inputValue.secoundHome}
              id="scoundPhoneNo"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="ژ.مۆبایلی دووەم"
            />
          </div>

          {/* end parent Input & label */}

          <div className="w-full mt-2">
            <div className="flex justify-end ">
              <label htmlFor="number" className="lblDesign font-normal">
                * ژ.کارتی نیشتمانی
              </label>
            </div>
            <input
              type="text"
              name="card"
              value={inputValue.card}
              id="number"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="ژ.کارتی نیشتمانی یاخود ناسنامەی"
            />
          </div>
          <div className="w-full mt-2">
            <div className="flex justify-end ">
              <label htmlFor="noFamily" className="lblDesign font-normal">
                *ژمارەی خێزان
              </label>
            </div>
            <input
              type="text"
              name="NoFamily"
              // onChange={handelInputChange}
              defaultValue={inputValue.noFamily}
              id="noFamily"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="ژمارەی خێزان"
            />
          </div>

          {/* end parent Input & label */}

          <div className="w-full mt-2">
            <div className="flex justify-end ">
              <label htmlFor="noFamily" className="lblDesign font-normal">
                * خێزاندارە
              </label>
            </div>
            <input
              type="text"
              name="NoFamily"
              // onChange={handelInputChange}
              defaultValue={inputValue.married}
              id="noFamily"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="خێزاندارە"
            />
          </div>

          {/* end parent Input & label */}
        </div>
        <div className="grid grid-cols-4 gap-2 ">
          {/* end parent Input & label */}
          <div></div>
          <div className="w-full mt-4">
            <div className="flex justify-end ">
              <label htmlFor="literate" className="lblDesign font-normal">
                * خوێندەوارە{" "}
              </label>
            </div>
            <select
              type="text"
              name="literate"
              // onChange={handelInputChange}
              value={inputValue.literate}
              id="literate"
              placeholder="خوێندەوارە"
              className="inputUserPrint w-full "
            >
              <option value="" selected>
                {" "}
                خوێندەوارە
              </option>
              <option value="بەڵێ"> بەڵێ</option>
              <option value="نەخێر"> نەخێر</option>
            </select>
          </div>
          {/* end parent Input & label */}
          <div className="w-full mt-4">
            <div className="flex justify-end ">
              <label htmlFor="literate" className="lblDesign font-normal">
                * جگەرە کێشە{" "}
              </label>
            </div>
            <select
              type="text"
              name="literate"
              // onChange={handelInputChange}
              value={inputValue.smoke}
              id="literate"
              placeholder="خوێندەوارە"
              className="inputUserPrint w-full "
            >
              <option value="" selected>
                {" "}
                جگەرە کێشە
              </option>
              <option value="بەڵێ"> بەڵێ</option>
              <option value="نەخێر"> نەخێر</option>
            </select>
          </div>
          <div className="w-full mt-4">
            <div className="flex justify-end  w-full">
              <label htmlFor="timeAllot" className="lblDesign font-normal">
                * کاتی دیاریکراو{" "}
              </label>
            </div>
            <input
              type="text"
              name="timeAllot"
              // onChange={handelInputChange}
              value={inputValue.timeAllote}
              id="timeAllot"
              placeholder="کاتی دیاریکراو بۆ هەڵسەنگاندن"
              className="inputUserPrint p-0 px-1 text-right w-full "
            />
          </div>
          {/* end parent Input & label */}
        </div>
        <div className="greenTitle bg-yellow-500">
          <p className="lg:text-lg">زانیاری پزیشکی</p>
        </div>
        <div className="grid grid-cols-4 gap-2">
          <div className=" mt-3">
            <label htmlFor="" className="lblDesign font-normal">
              TYP *
            </label>
            <input
              type="text"
              value={inputValue.TYP}
              placeholder="TYP"
              className="inputUserPrint p-0 px-1 w-full "
            />
          </div>
          <div className="relative mt-3 text-right">
            <label htmlFor="" className="text-right lblDesign font-normal">
              * لەسەر ئاستی ئەندام
            </label>
            <input
              type="text"
              value={inputValue.memberLevel}
              placeholder="لەسەر ئاستی ئەندام"
              className="inputUserPrint p-0 px-1 w-full text-right "
            />
          </div>

          <div className="relative mt-3 text-right">
            <label htmlFor="" className="lblDesign font-normal text-right">
              * ئەندام
            </label>
            <input
              type="text"
              value={inputValue.member}
              placeholder="ئەندام"
              className="inputUserPrint p-0 px-1 w-full "
            />
          </div>

          <div className="relative mt-3 text-right w-full">
            <label htmlFor="spi" className="text-right lblDesign font-normal">
              * پسپۆری جۆری نەخۆشی
            </label>
            <input
              type="text"
              value={inputValue.SITD}
              placeholder="پسپۆری جۆری نەخۆشی"
              className="inputUserPrint p-0 px-1 w-full "
            />
          </div>

          {/* end parent Input & label */}

          {/* end parent Input & label */}
        </div>
        <div className="grid grid-cols-4 gap-2 mt-2">
          <div className="w-full ">
            <label htmlFor="Indiction" className="lblDesign font-normal">
              Indiction *
            </label>
            <input
              type="text"
              name="indiction"
              // onChange={handelInputChange}
              defaultValue={inputValue.indiction}
              id="Indiction"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="Indiction"
            />
          </div>
          {/* end parent Input & label */}

          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="earlySurg" className="lblDesign font-normal">
                * نەشتەرگەری پێشوەخت{" "}
              </label>
            </div>
            <input
              type="text"
              name="earlySurgery"
              // onChange={handelInputChange}
              defaultValue={inputValue.earlySurgery}
              id="earlySurg"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="نەشتەرگەری پێشوەخت"
            />
          </div>
          {/* end parent Input & label */}

          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="ea" className="lblDesign font-normal">
                * نەخۆشی پێش وەخت
              </label>
            </div>
            <input
              type="text"
              name="earlyPatient"
              // onChange={handelInputChange}
              defaultValue={inputValue.earlyPatient}
              id="ea"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="نەخۆشی پێش وەخت"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="ea" className="lblDesign font-normal">
                * نەشتەرگەری بۆ کراوە
              </label>
            </div>
            <select
              name="hadSurgery"
              // onChange={handelInputChange}
              value={inputValue.had_surgery}
              className="inputUserPrint w-full"
              id=""
            >
              <option value="">تکایە زانیاریەکە هەڵبژێرە</option>
              <option value="بەڵێ">بەڵێ</option>
              <option value="نەخێر">نەخێر</option>
            </select>
            {/* <input
                                type="text"
                                name="earlyPatient"
                                onChange={handelInputChange}
                                defaultValue={inputValue.earlyPatient}
                                id="ea"
                                className="inputUserPrint p-0 px-1 text-right w-full "
                                placeholder="نەشتەرگەری بۆ کراوە"
                              /> */}
          </div>
          {/* end parent Input & label */}
        </div>
        <div className="grid grid-cols-4 gap-2">
          <div className="w-full ">
            <label htmlFor="H.P.E" className="lblDesign font-normal">
              H.P.E *
            </label>
            <input
              type="text"
              name="HPE"
              // onChange={handelInputChange}
              defaultValue={inputValue.HEP}
              id="H.P.E"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="H.P.E "
            />
          </div>

          {/* end parent Input & label */}

          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="doctorSend" className="lblDesign font-normal">
                {" "}
                * پزیشکی نێردراو بۆ
              </label>
            </div>
            <input
              type="text"
              className="inputUserPrint p-0 px-1 w-full text-right"
              value={inputValue.Doctor}
              placeholder="Doctor"
            />
          </div>

          {/* end parent Input & label */}

          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="ZODoctor" className="lblDesign font-normal">
                {" "}
                *پزیشکی هاوکارە
              </label>
            </div>

            <select
              name="ZODoctor"
              value={inputValue.ZODoctor || ""}
              className=" inputUserPrint w-full "
              // onChange={handelInputChange}
              id="ZODoctor"
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ</option>
              <option value="نەخێر">نەخێر</option>
            </select>
          </div>
          {/* end parent Input & label */}

          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="helpingZO" className="lblDesign font-normal">
                * هاوکاری رێکخراوی زادە
              </label>
            </div>

            <select
              name="helpingZO"
              id="helpingZO"
              value={inputValue.helpingZO || ""}
              className="inputUserPrint w-full "
              // onChange={handelInputChange}
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ</option>
              <option value="نەخێر">نەخێر</option>
            </select>
          </div>
          {/* end parent Input & label */}
          <div className="w-full ">
            <label htmlFor="Final" className="lblDesign font-normal">
              Final Diagnosis *
            </label>
            <input
              type="text"
              // onChange={handelInputChange}
              defaultValue={inputValue.finalDiagnosis}
              name="finalDiagnos"
              id="Final"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="Final Diagnosis"
            />
          </div>
          {/* end parent Input & label */}

          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="typeMedical" className="lblDesign font-normal">
                * بۆ چی
              </label>
            </div>
            <input
              type="text"
              name="typeMedical"
              // onChange={handelInputChange}
              defaultValue={inputValue.Typemedicalsupplies}
              id="typeMedical"
              className="inputUserPrint p-0 px-1 text-right w-full "
              placeholder="بۆ چی"
            />
          </div>
          {/* end parent Input & label */}
          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="supplyLocation" className="lblDesign font-normal">
                *شوێنی دابینکردن
              </label>
            </div>
            <input
              type="text"
              name="supplyLocation"
              // onChange={handelInputChange}
              defaultValue={inputValue.supplyLocation}
              id="supplyLocation"
              className="inputUserPrint p-0 px-1 text-right w-full"
              placeholder="شوێنی دابینکردنی پێداویستی"
            />
          </div>
          <div className="w-full ">
            <div className="flex justify-end ">
              <label htmlFor="medicalProvid" className="lblDesign font-normal">
                * پێداویستی بۆ دابینکراوە{" "}
              </label>
            </div>

            <select
              name="medicalProvid"
              id="medicalProvid"
              value={inputValue.medicalProvided || ""}
              className=" inputUserPrint w-full "
              // onChange={handelInputChange}
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ</option>
              <option value="نەخێر">نەخێر</option>
            </select>
          </div>
          {/* end parent Input & label */}
        </div>

     
      </div>
      <div className="  w-full  absolute bottom-0">
        <img src={Footer1} className="w-full" alt="" />
      </div>
    </div>
  );
};

export default FeedbackPrint;
