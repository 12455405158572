import {
  CREATE_COMMITE_FAIL,
  CREATE_COMMITE_REQUEST,
  CREATE_COMMITE_SUCCESS,
  FETCH_COMMITE_FAIL,
  FETCH_COMMITE_INFO_REQUEST,
  FETCH_COMMITE_INFO_SUCCESS,
  FETCH_COMMITE_INFo_FAIL,
  FETCH_COMMITE_REQUEST,
  FETCH_COMMITE_SUCCESS,
  FETCH_COMMITE_WITH_VISIT_FAIL,
  FETCH_COMMITE_WITH_VISIT_REQUEST,
  FETCH_COMMITE_WITH_VISIT_SUCCESS,
} from "../ActionType/commiteType";
// import { FETCH_PATIENT_FAIL, FETCH_PATIENT_REQUEST, FETCH_PATIENT_SUCCESS } from "../ActionType/patientType"

export const createCommiteReducer = (
  state = { loading: true, suliCommite: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_COMMITE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_COMMITE_SUCCESS:
      return {
        loading: false,
        suliCommites: action.payload,
        error: "",
      };
    case CREATE_COMMITE_FAIL:
      return {
        loading: false,
        suliCommite: [],
        error: "Please Check Your request",
      };
    default:
      return state;
  }
};


export const updateColorCommiteReducer = (
  state = { loading: true, suliCommite: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_COMMITE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_COMMITE_SUCCESS:
      return {
        loading: false,
        suliCommites: action.payload,
        error: "",
      };
    case CREATE_COMMITE_FAIL:
      return {
        loading: false,
        suliCommite: [],
        error: "Please Check Your request",
      };
    default:
      return state;
  }
};

export const getCommiteInfoReducer = (
  state = { loading: true, commiteInfo: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_COMMITE_INFO_REQUEST:
      return {
        loading: true,
      };
    case FETCH_COMMITE_INFO_SUCCESS:
      return {
        loading: false,
        commiteInfo: action.payload,

        erorr: "",
      };
    case FETCH_COMMITE_INFo_FAIL:
      return {
        loading: false,
        commiteInfo: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
export const getCommiteReducer = (
  state = { loading: true, commites: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_COMMITE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_COMMITE_SUCCESS:
      return {
        loading: false,
        commites: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_COMMITE_FAIL:
      return {
        loading: false,
        commites: [],
        error: "Please Check Your request",
      };
    default:
      return state;
  }
};

export const getCommiteWithVisitReducer = (
  state = { loading: true, patients: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_COMMITE_WITH_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_COMMITE_WITH_VISIT_SUCCESS:
      return {
        loading: false,
        patients: action.payload,

        erorr: "",
      };
    case FETCH_COMMITE_WITH_VISIT_FAIL:
      return {
        loading: false,
        patients: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
