import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Pages/LoginForm/Login";
import { useDispatch, useSelector } from "react-redux";
import RejectPatient from "./components/Pages/Reject/RejectPatient";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import "./App.css";
import Navbar from "./components/Pages/Bars/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import Logo from "./components/Image/Logo.jpg";

import NewInvoice from "./components/Pages/Branchs/NewInvoice";
import Commite from "./components/Pages/Commite/Commite";
import Feedback from "./components/Pages/CommiteFeedback/Feedback";
import { getUserAuth } from "./components/Action/userRegister";
import Receive from "./components/Pages/Received/Receive";
import ShowReceive from "./components/Pages/Media/Receive/ShowReceive";

import ShowAllInfo from "./components/Pages/Media/Receive/ShowAllInfo";

import NotFound404 from "./components/Pages/404NotFound/NotFound404";
import EditProfile from "./components/Pages/user_profile/EditProfile";

import Statistics from "./components/Pages/Statistics/Statistics";
import Doctor from "./components/Pages/Doctor/Doctor";
import Surgery from "./components/Pages/Surgery/Surgery";
import SortingMediaWork from "./components/Pages/Media/SortingMediaWork/SortingMediaWork";
import MediaFormInfo from "./components/Pages/Media/MediaFormInfo";
import TabVisitCommite from "./components/Pages/Visits/TabVisitCommite";
import WhatsUpSurgery from "./components/Pages/WhatsUp/WhatsUpSurgery";
import MedicalSuplies from "./components/Pages/WhatsUp/MedicalSupplies/MedicalSuplies";
import ReportMonthly from "./components/Pages/Branchs/ReportMonthly";
import Administrator from "./components/Pages/Administrator/Administrator";
import SentFile from "./components/Pages/Administrator/SentFile/SentFile";
import ReciveFilesAdminstrator from "./components/Pages/ReciveFilesAdminstrator/ReciveFilesAdminstrator";
import Patient from "./components/Pages/Administrator/AddPatient/Patient";
import Roles from "./components/Pages/Roles/Roles";
import EditRoles from "./components/Pages/Roles/EditRoles/EditRoles";
import UserProfile from "./components/Pages/user_profile/UserProfile";
import AddUser from "./components/Pages/user_profile/AddUser";
import EditUser from "./components/Pages/user_profile/EditUser";


const App = () => {
  const dispatch = useDispatch();
  const getUsers = useSelector((state) => state.getUser);
  const { users, loading } = getUsers;

  useEffect(() => {
    dispatch(getUserAuth());
  }, []);
  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     reciveMessageAction(event,"http://localhost:3001")
  //   };
  //   window.addEventListener("message", handleMessage);
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <BrowserRouter>
          <div className="flex h-screen">
            <div className="w-full    ">
              <div className={"fixed w-full z-10 "}>
                <Navbar users={users} />
              </div>
              {/* end navbar */}
              <div className="w-full  pt-1   mt-20">
                <Routes>
                  {/* <Route path="feedbackPrint" element={<FeedbackPrint />} /> */}

                  <Route element={<ProtectedRoute />}>
                    {/* medical Route */}

                    
                      <>
                        <Route
                          path="/tabsVisitCommite/:id"
                          element={<TabVisitCommite />}
                        />
                        <Route
                          path="/whatsAppInfo"
                          element={<WhatsUpSurgery />}
                        />

                        <Route
                          path="/MedicalSupplies"
                          element={<MedicalSuplies />}
                        />
                        <Route path="/invoice" element={<NewInvoice />} />
                        <Route path="/feedback" element={<Feedback />} />
                        <Route path="/receive" element={<Receive />} />
                        <Route path="/reject" element={<RejectPatient />} />
                        <Route path="/doctor" element={<Doctor />} />
                        <Route path="/surgery" element={<Surgery />} />
                        <Route path="/commite" element={<Commite />} />
                        <Route path="/roles" element={<Roles />} />
                        <Route path="/roles/edit/:id" element={<EditRoles />} />
                        <Route
                          path="/ReportMonthly"
                          element={<ReportMonthly />}
                        />
                      </>
                    

                    {/* end Medical Route */}

                    <Route
                      path="/reciveFileAdminstrator"
                      element={<ReciveFilesAdminstrator />}
                    />

                    <Route path="*" element={<NotFound404 />} />

                    {/* <Route path="/user" element={""} /> */}

                    <Route path="/show_receive" element={<ShowReceive />} />
                    <Route path="/showAllInfoMedia" element={<ShowAllInfo />} />
                    <Route path="/works" element={<SortingMediaWork />} />
                    <Route path="/mediaForm" element={<MediaFormInfo />} />

                    {/* <Route
                      path="/show_economic"
                      element={<ShowEconomicReceive />}
                    /> */}

                    <Route path="/statistics" element={<Statistics />} />
                    <Route
                      path="Administrator/sentFile"
                      element={<SentFile />}
                    />
                    <Route path="Administrator/patient" element={<Patient />} />
                    <Route path="/Administrator" element={<Administrator />} />
                    <Route path="/user" element={<UserProfile />} />
                    <Route path="/AddUser" element={<AddUser />} />
                    <Route path="/edit/user/:id" element={<EditUser />} />

                    <Route path="/editProfile" element={<EditProfile />} />

                    <Route path="/" element={<Dashboard />} />
                  </Route>

                  {/* </Route> */}

                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<NotFound404 />} />
                </Routes>
              </div>

              {/* <Footer /> */}
            </div>
          </div>
        </BrowserRouter>
      )}
      {/* <div className="pb-10"></div> */}
    </>
  );
};

export default App;
