import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationAction,
  getReciveAction,
  seenNotificationAction,
} from "../../Action/receiveAction";
import ReactPaginate from "react-paginate";
import { FaCheck } from "react-icons/fa6";
import { AiFillEye } from "react-icons/ai";
import ViewReciveModal from "../../Modal/ViewModal/ViewReciveModal";
import SendModalAllBranch from "../../Modal/SendModalAllBrnach/SendModalAllBranch";
import { getDoctorAction } from "../../Action/Doctor/doctorAction";
import { CiFilter } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import Loading from "../Loading/Loading";

const Receive = () => {
  const locationID = localStorage.getItem("locationId");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState();
  const dispatch = useDispatch();
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;

  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  const [searchParams, setSearchParams] = useState({
    patientFrom: "",
    fullname: "",
    code: "",
    phone: "",
    gender: "",
    doctor: "",
  });
  const { patientFrom, fullname, code, phone, gender } = searchParams;
  
  const handleSearchParams = (e) => {
    const { name, value } = e.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const [isOpenViewRecive, setIsOpenViewRecive] = useState(false);
  const [reciveId, setReciveId] = useState(null);
  const handelSeen = (id) => {
    dispatch(seenNotificationAction(id));
    dispatch(seenNotificationAction(id));

    dispatch(getNotificationAction(locationID));
    dispatch(getNotificationAction(locationID));

    dispatch(
      getReciveAction(locationID, "", "", "", "", "", "", currentPage, perPage)
    );
    dispatch(
      getReciveAction(locationID, "", "", "", "", "", "", currentPage, perPage)
    );
  };
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const getReceive = useSelector((state) => state.getReceive);
  const { receive, total, loading } = getReceive;
  const [lezhnaId, setLezhnaId] = useState(null);
  useEffect(() => {
    dispatch(
      getReciveAction(
        locationID,
        patientFrom,
        code,
        fullname,
        gender,
        phone,
        "",
        currentPage,
        perPage,
        searchParams.doctor
      )
    );
  }, [currentPage, perPage]);

  const [isOpenFilter, setIsOpentFilter] = useState(false);

  const [index, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };

  const handleSearch = (columnToClear) => {
    const {
      code,
      fullname,
      phone,
      gender,
      age,
      patientFrom,
      doctor
    } = searchParams;
    // console.log(hospital);
    const updatedSearchParams = {
      patientFrom: columnToClear === "patientFrom" ? "" : patientFrom,
      fullname: columnToClear === "fullname" ? "" : fullname,
      phone: columnToClear === "phone" ? "" : phone,
      gender: columnToClear === "gender" ? "" : gender,
      age: columnToClear === "age" ? "" : age,
      doctor: columnToClear === "doctor" ? "" : doctor,
      code: columnToClear === "code" ? "" : code,
    };

    setIsOpentFilter(false);
    dispatch(
      getReciveAction(
        locationID,
        updatedSearchParams.patientFrom,
        updatedSearchParams.code,
        updatedSearchParams.fullname,
        updatedSearchParams.gender,
        updatedSearchParams.phone,
        "",
        currentPage,
        perPage,
        updatedSearchParams.doctor
      )
    );
  };
  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClearSearchChange = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };
  return (
    <div>
      {loading ? (
        <Loading/>
      ) : (
        <div className="bg-white h-[75vh]   w-[98%] m-auto  ">
          <div className="overflow-y-auto w-full h-[90%]">
            <table className="w-full">
              <thead className="sticky top-0 ">
                <tr>
                  <th className="  border">#</th>

                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Patient From</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(2)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 2
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <input
                        type="text"
                        className="inputSearch"
                        placeholder="Patient From"
                        value={searchParams.patientFrom}
                        name="patientFrom"
                        onChange={handleSearchChange}
                      />
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("patientFrom");
                            handleClearSearchChange("patientFrom");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>

                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Doctor</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(3)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 3
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <select
                        onChange={handleSearchParams}
                        type="text"
                        name="doctor"
                        className="inputSearch "
                        placeholder="Doctor"
                      >
                        <option value="">Select Doctor ...</option>
                        {doctors.map((doctor, index) => {
                          return (
                            <option key={index} value={doctor.dr_name}>
                              {doctor.dr_name}
                            </option>
                          );
                        })}
                      </select>
                      
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("doctor");
                            handleClearSearchChange("doctor");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>

                  
                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Code</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(4)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 4
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <input
                        type="text"
                        className="inputSearch"
                        placeholder="Code"
                        value={searchParams.code}
                        name="code"
                        onChange={handleSearchChange}
                      />
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("code");
                            handleClearSearchChange("code");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>
                  
                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Full Name</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(5)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 5
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <input
                        type="text"
                        className="inputSearch"
                        placeholder="Full Name"
                        value={searchParams.fullname}
                        name="fullname"
                        onChange={handleSearchChange}
                      />
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("fullname");
                            handleClearSearchChange("fullname");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>
                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Phone</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(6)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 6
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <input
                        type="text"
                        className="inputSearch"
                        placeholder="Phone"
                        value={searchParams.phone}
                        name="phone"
                        onChange={handleSearchChange}
                      />
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("phone");
                            handleClearSearchChange("phone");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>

                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Gender</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(7)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 7
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <input
                        type="text"
                        className="inputSearch"
                        placeholder="Gender"
                        value={searchParams.gender}
                        name="gender"
                        onChange={handleSearchChange}
                      />
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("gender");
                            handleClearSearchChange("gender");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>

                  <th className=" relative ">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Note</p>
                      <div
                        className="filterIcon"
                        onClick={() => handleOpenFilter(8)}
                      >
                        <CiFilter />
                      </div>
                    </div>

                    <div
                      className={
                        isOpenFilter && index == 8
                          ? "parentInputSearch mt-1"
                          : "hidden"
                      }
                    >
                      <input
                        type="text"
                        className="inputSearch"
                        placeholder="Note"
                        value={searchParams.note}
                        name="note"
                        onChange={handleSearchChange}
                      />
                      <div className="flex  gap-2 mt-2">
                        <button
                          onClick={handleSearch}
                          className="btnIndigoSearch "
                        >
                          <FaCheck />
                        </button>

                        <button
                          onClick={(e) => {
                            handleSearch("note");
                            handleClearSearchChange("note");
                          }}
                          className="btnRedSearch"
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </div>
                  </th>

                  
                  <th>Action</th>
                  {/* <th  className="">Send</th> */}
                </tr>
              </thead>
              <tbody className="">
                {receive.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={item.seen == 2 ? "tableRowSave" : "tableRow"}
                    >
                      <td className="py-2.5">{index + 1}</td>
                      <td>{item.branch_name}</td>
                      <td>{item.lezhna.commite.Doctor}</td>
                      <td>{item.lezhna.commite.patients.code}</td>
                      <td>{item.lezhna.commite.patients.Pname}</td>
                      <td>{item.lezhna.commite.patients.phone}</td>
                      <td>{item.lezhna.commite.patients.gender}</td>

                      <td className="w-72">{item.note}</td>
                      <td className=" ">
                        <div className="flex gap-3 justify-center">
                          <button
                            disabled={item.seen == 2 ? true : false}
                            className={
                              item.seen == 2
                                ? "btnCoustmDesignPurple cursor-not-allowed"
                                : "btnCoustmDesignPurple"
                            }
                            onClick={(e) => {
                              e.preventDefault();

                              handelSeen(item.id);
                            }}
                          >
                            {item.seen == 2 ? "seen" : "see"}
                          </button>
                          <button
                            title="View"
                            onClick={() => {
                              setIsOpenViewRecive(true);
                              setReciveId(item.id);
                            }}
                            className="btnCoustmDesignGreen"
                          >
                            {" "}
                            <AiFillEye />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="gap-3 px-3 flex justify-end items-center pt-2  pb-3">
            <div>
              <select
                name=""
                className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                onChange={(e) => setPerPage(e.target.value)}
                id=""
              >
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="flex justify-center  mt-1">
              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center  -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getReciveAction(
                      locationID,
                      patientFrom,
                      code,
                      fullname,
                      gender,
                      phone,
                      "",
                      currentPage,
                      perPage
                    )
                  );
                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}

      <div className="pb-28"></div>

      {isOpenViewRecive && (
        <ViewReciveModal
          recive_id={reciveId}
          closeModal={setIsOpenViewRecive}
        />
      )}
      {isOpenSendModal && (
        <SendModalAllBranch
          lezhna_id={lezhnaId}
          closeModal={setIsOpenSendModal}
        />
      )}
    </div>
  );
};

export default Receive;
