export const FETCH_MEDICAL_SUPLIES_TYPES_REQUEST =
  "FETCH_MEDICAL_SUPLIES_TYPES_REQUEST";
export const FETCH_MEDICAL_SUPLIES_TYPES_SUCCESS =
  "FETCH_MEDICAL_SUPLIES_TYPES_SUCCESS";
export const FETCH_MEDICAL_SUPLIES_TYPES_FAIL =
  "FETCH_MEDICAL_SUPLIES_TYPES_FAIL";

export const CREATE_MEDICAL_SUPLIES_TYPES_REQUEST =
  "CREATE_MEDICAL_SUPLIES_TYPES_REQUEST";
export const CREATE_MEDICAL_SUPLIES_TYPES_SUCCESS =
  "CREATE_MEDICAL_SUPLIES_TYPES_SUCCESS";
export const CREATE_MEDICAL_SUPLIES_TYPES_FAIL =
  "CREATE_MEDICAL_SUPLIES_TYPES_FAIL";

export const DELETE_MEDICAL_SUPLIES_TYPES_REQUEST =
  "DELETE_MEDICAL_SUPLIES_TYPES_REQUEST";
export const DELETE_MEDICAL_SUPLIES_TYPES_SUCCESS =
  "DELETE_MEDICAL_SUPLIES_TYPES_SUCCESS";
export const DELETE_MEDICAL_SUPLIES_TYPES_FAIL =
  "DELETE_MEDICAL_SUPLIES_TYPES_FAIL";

export const UPDATE_MEDICAL_SUPLIES_TYPES_REQUEST =
  "UPDATE_MEDICAL_SUPLIES_TYPES_REQUEST";
export const UPDATE_MEDICAL_SUPLIES_TYPES_SUCCESS =
  "UPDATE_MEDICAL_SUPLIES_TYPES_SUCCESS";
export const UPDATE_MEDICAL_SUPLIES_TYPES_FAIL =
  "UPDATE_MEDICAL_SUPLIES_TYPES_FAIL";
