import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RiFileExcel2Fill } from "react-icons/ri";
import {

  getWhatsUpInfoAction,
} from "../../Action/WhatsAppAction/whatsAppAction";

import ReactPaginate from "react-paginate";
import "./index.css";
import { FaCheck } from "react-icons/fa";
import { getToken } from "../../Action/login";
import { api } from "../../Api/api";
import { CiFilter } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import Loading from "../Loading/Loading";

const WhatsUpSurgery = () => {
  const getWhatsUpInfo = useSelector((state) => state.getWhatsUpInfo);

  const { data, loading, total } = getWhatsUpInfo;

  // const [openFilter, setOpenFilter] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    organ_level: "",
    doctor: "",
    hospital: "",
    totalOfSurgery: "",
    totalOfPatient: "",
    total_of_hospital: "",
    helpDr: "",
    zad: "",
    date: "",
  });
  const {
    fullname,
    organ_level,
    doctor,
    hospital,
    totalOfPatient,
    totalOfSurgery,
    total_of_hospital,
    zad,
    date,
  } = searchParams;

  const [loadingExcle, setLoadingExcle] = useState(false);
  const exportToExcel = async () => {
    setLoadingExcle(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
        branch_id: locationId,
      },
      responseType: "blob",
    };
    try {
      const response = await api.get("whatsApp/excel", config);

      const blob = new Blob([response.data], { type: "text/csv" });
      if (response.status == 200) {
        setLoadingExcle(false);
      }
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = "واتسئاپ.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const locationId = localStorage.getItem("locationId");
  const [perPage, setPerPage] = useState(20);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getWhatsUpInfoAction(
        perPage,
        currentPage,
        fullname,
        organ_level,
        doctor,
        hospital,
        totalOfPatient,
        totalOfSurgery,
        total_of_hospital,
        zad,
        date,
        locationId,
        "",
        searchParams.helpDr,
        from,
        to
      )
    );
  }, [perPage, from, to]);

  const [isOpenFilter, setIsOpentFilter] = useState(false);

  const [index, setIndex] = useState(null);
  const handleOpenFilter = (index) => {
    setIndex(index);
    setIsOpentFilter(!isOpenFilter);
  };

  const handleSearch = (columnToClear) => {
    const {
      code,
      fullname,
      phone,
      gender,
      age,
      doctor,
      organ,
      organLevel,
      type_of_help,
      type,
      hospital,
      helpDr,
    } = searchParams;
    // console.log(hospital);
    const updatedSearchParams = {
      hospital: columnToClear === "hospital" ? "" : hospital,
      fullname: columnToClear === "fullname" ? "" : fullname,
      phone: columnToClear === "phone" ? "" : phone,
      gender: columnToClear === "gender" ? "" : gender,
      age: columnToClear === "age" ? "" : age,

      doctor: columnToClear === "doctor" ? "" : doctor,
      organ: columnToClear === "organ" ? "" : organ,
      organLevel: columnToClear === "organLevel" ? "" : organLevel,
      totalOfSurgery: columnToClear === "totalOfSurgery" ? "" : totalOfSurgery,
      totalOfPatient: columnToClear === "totalOfPatient" ? "" : totalOfPatient,
      total_of_hospital:
        columnToClear === "total_of_hospital" ? "" : total_of_hospital,
      helpDr: columnToClear === "helpDr" ? "" : helpDr,
      zad: columnToClear === "zad" ? "" : zad,
      date: columnToClear === "date" ? "" : date,
    };

    setIsOpentFilter(false);

    dispatch(
      getWhatsUpInfoAction(
        perPage,
        currentPage,
        updatedSearchParams.fullname,
        updatedSearchParams.organLevel,
        updatedSearchParams.doctor,
        updatedSearchParams.hospital,
        updatedSearchParams.totalOfPatient,
        updatedSearchParams.totalOfSurgery,
        updatedSearchParams.total_of_hospital,
        updatedSearchParams.zad,
        updatedSearchParams.date,
        locationId,
        "",
        updatedSearchParams.helpDr,
        from,
        to
      )
    );
  };
  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClearSearchChange = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };
  return (
    <>
      {loading || loadingExcle ? (
        <Loading />
      ) : (
        <> 
          <div className="flex items-center py-2 gap-2">
            <button onClick={exportToExcel}>
              <div className="flex items-center justify-between duration-500 text-white gap-2 ml-5 bg-green-500 bg-opacity-75 hover:bg-opacity-90 rounded-md px-4 py-2 shadow-md cursor-pointer">
                <p>Export </p>
                <span>
                  <RiFileExcel2Fill />
                </span>
              </div>
            </button>
            <div className="flex gap-2">
              <input
                type="date"
                onChange={(e) => setFrom(e.target.value)}
                className="inputUser w-full"
              />
              <div className="mt-2 mr-1">
                <label htmlFor="">To</label>
              </div>
              <div>
                <input
                  type="date"
                  className="w-full inputUser"
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className=" bg-white   h-screen    w-[98%] m-auto    ">
            <div className="overflow-y-auto w-full h-[75%] xl:h-[90%]">
              <table className="w-[130%] overflow-x-auto">
                <thead className="sticky top-0 ">
                  <tr>
                    <th className=" border">#</th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Full Name</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(2)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 2
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Full Name"
                          value={searchParams.fullname}
                          name="fullname"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={(e) => {
                              handleSearch("fullname");
                              handleClearSearchChange("fullname");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Organ Level</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(3)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 3
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Organ Level"
                          value={searchParams.organ_level}
                          name="organ_level"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("organ_level");
                              handleClearSearchChange("organ_level");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Doctor</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(4)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 4
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Doctor"
                          value={searchParams.doctor}
                          name="doctor"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("doctor");
                              handleClearSearchChange("doctor");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Hospital</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(5)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 5
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Hospital"
                          value={searchParams.hospital}
                          name="hospital"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("hospital");
                              handleClearSearchChange("hospital");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Total Of Surgery</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(6)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 6
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="Total Of Surgery"
                          value={searchParams.totalOfSurgery}
                          name="totalOfSurgery"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("totalOfSurgery");
                              handleClearSearchChange("totalOfSurgery");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Total Of Patient</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(7)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 7
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder=" Total Of Patient"
                          value={searchParams.totalOfPatient}
                          name="totalOfPatient"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("totalOfPatient");
                              handleClearSearchChange("totalOfPatient");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Total Of Hospital</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(8)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 8
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="  Total Of Hospital"
                          value={searchParams.total_of_hospital}
                          name="total_of_hospital"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("total_of_hospital");
                              handleClearSearchChange("total_of_hospital");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Helping Of Doctor</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(9)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 9
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="  Helping Of Doctor"
                          value={searchParams.helpDr}
                          name="helpDr"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("helpDr");
                              handleClearSearchChange("helpDr");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1"> Zad</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(10)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 10
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="  Zad"
                          value={searchParams.zad}
                          name="zad"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("zad");
                              handleClearSearchChange("zad");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>

                    <th className=" relative ">
                      <div className="flex justify-between items-center">
                        <p className="pl-1">Date</p>
                        <div
                          className="filterIcon"
                          onClick={() => handleOpenFilter(11)}
                        >
                          <CiFilter />
                        </div>
                      </div>

                      <div
                        className={
                          isOpenFilter && index == 11
                            ? "parentInputSearch"
                            : "hidden"
                        }
                      >
                        <input
                          type="text"
                          className="inputSearch"
                          placeholder="  Date"
                          value={searchParams.date}
                          name="date"
                          onChange={handleSearchChange}
                        />
                        <div className="flex  gap-2 mt-2">
                          <button
                            onClick={handleSearch}
                            className="btnIndigoSearch "
                          >
                            <FaCheck />
                          </button>

                          <button
                            onClick={() => {
                              handleSearch("date");
                              handleClearSearchChange("date");
                            }}
                            className="btnRedSearch"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {data.map((item, index) => {
                    return (
                      <tr key={index} className={"tableRow"}>
                        <td className="py-2">{index + 1}</td>
                        <td>{item.fullname}</td>
                        <td className="flex">
                          {item.organ_level.map((organ, index) => {
                            return <div key={index}> {organ + " , "} </div>;
                          })}
                        </td>

                        <td>{item.doctor}</td>
                        <td>{item.hospital}</td>
                        <td>{item.total_of_surgery}</td>
                        <td>{item.total_of_patient}</td>
                        <td>{item.total_of_hospital}</td>
                        <td>{item.helpDr}</td>

                        <td className="">{item.zad}</td>
                        <td>{item.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="gap-3 px-3 flex justify-end items-center pt-2  pb-3">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  id=""
                >
                  <option value="20">20</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center  mt-1">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      getWhatsUpInfoAction(
                        perPage,
                        currentPage,
                        fullname,
                        organ_level,
                        doctor,
                        hospital,
                        totalOfPatient,
                        totalOfSurgery,
                        total_of_hospital,
                        zad,
                        date,
                        locationId,
                        exportToExcel
                      )
                    );
                    setCurrentPage(currentPage);
                  }}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="pb-20"></div>

    </>
  );
};

export default WhatsUpSurgery;
