import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { userLoginReducer } from "../Reducer/userLoginReducer";
import thunk from "redux-thunk";
import { getBranchReducer } from "../Reducer/BrnachReducer";
import {
  getAllUserReducer,
  getUserReducer,
  showUserAdminstratorReducer,
  showUserReducer,
  updateUserInformationReducer,
  updateUserReducer,
  userCreateReducer,
} from "../Reducer/userCreateReducer";
import { getSectionReducer } from "../Reducer/sectionReducer";
import {
  allInfoPatientReducer,
  createPatientAdminstratorReducer,
  editPatientReducer,
  getPatientReducer,
  patientCreateReducer,
  rejectPatientReducer,
  showReportMonthlyReducer,
  updatePatientReducer,
} from "../Reducer/patientReducer";
import {
  createCommiteReducer,
  getCommiteInfoReducer,
  getCommiteReducer,
  getCommiteWithVisitReducer,
  updateColorCommiteReducer,
} from "../Reducer/commiteReducer";
import {
  addFeedbackReducer,
  editFeedbackReducer,
  getFeedbackImageReducer,
  getFeedbackReducer,
  saveFeedbackReducer,
} from "../Reducer/lezhnaReducer";
import {
  createReceivedReducer,
  getNotificationReucer,
  getReceiveReducer,
  seeNotificationReducer,
  showReceiveReducer,
} from "../Reducer/receivedReducer";
import {
  approvedMediaWorkReducer,
  getAllInfoMediaReducer,
  getAllInfoPatientMedaReducer,
  getCoustmMediaInfoReducer,
  getInfoFromLezhnaTbaleReducer,
  getReciveMediaByVisitReducer,
  getSortingWorkMediaReducer,
  receivePatientInfoMeidaReducer,
  rejectReciveMediaReducer,
  seenReciveMediaReducer,
  sendInfoPatientReducer,
  storeAllInfoPatientReducer,
  updateSortingWorkMediaReducer,
} from "../Reducer/Media/mediaReducer";
import {
  getRejectPatientReducer,
  rejectPatientDisapprovedReducer,
  rejectPatientVisitReducer,
  rejectPatientWithVisitReducer,
  showRejectPatientVisitReducer,
} from "../Reducer/rejectReducer";
import {
  createReceiveEconomicReducer,
  getReceiveEconmicReducer,
} from "../Reducer/EconomicReducer/economicReducer";
import {
  createImageReducer,
  getImageReducer,
} from "../Reducer/ImageReducer/imageReducer";
import {
  createOrganLevelReducer,
  createSurgeryReducer,
  getAllTypeSurgeryReducer,
  getOrganLevelReducer,
  getSurgeryReducer,
  getTypeSurgeryReducer,
} from "../Reducer/Surgery/surgeryReducer";
import { exportStatisticsReducer, getStatisticsReducer } from "../Reducer/Statistics/StatisticsReducer";
import {
  createDoctorReducer,
  getDoctorReducer,
  updateDoctorReducer,
} from "../Reducer/DoctorReducer/DoctorReducer";
import { getVisitByPatientIdReducer, updateVisitReducer } from "../Reducer/visitReducer/visitReducer";
import {
  getWhatsAppInByVisitReducer,
  getWhatsAppInfoExcelReducer,
  getWhatsUpInfoReducer,
  sendWhatsAppReducer,
} from "../Reducer/WhatsAppReducer/whatsApp";
import {
  createTypeMedicalReducer,
  getImageTypeMedicalReducer,
  getTypeMedicalByVisitIdReducer,
  getTypeMedicalExcelReducer,
  getTypeMedicalReducer,
} from "../Reducer/TypeOfMedicalReducer/typemedicalReducer";
import {
  createAdminstratorReducer,
  deleteAdminstratorReducer,
  getAdminstratorReducer,
  updateAdminstratorReducer,
} from "../Reducer/AdminstratorReducer/adminstratorReducer";
import {
  createSentFilesAdminstratorReducer,
  deleteSentFilesAdminstratorReducer,
  getSentFilesAdminstratorReducer,
  updateSentFilesAdminstratorReducer,
} from "../Reducer/AdminstratorReducer/SentFIlesReducer";
import {
  createReciveFilesAdminstrator,
  createReciveFilesAdminstratorReducer,
  getReciveFilesAdminstratorReducer,
  showReciveFilesAdminstratorReducer,
  updateReciveFilesAdminstratorReducer,
} from "../Reducer/ReciveFilesAdminstratorReducer/ReciveFilesAdminstratorReducer";
import {
  createRolesReducer,
  getRolesReducer,
  showRolesReducer,
} from "../Reducer/RolesReducer/RolesReducer";
import {
  createPermissionPageReducer,
  getPageReducer,
  showPageReducer,
  showPermissionPageReducer,
} from "../Reducer/PageReducer/PageReducer";
import { createMedicalSupliesTypesReducer, getMedicalSupliesTypesReducer } from "../Reducer/MedicalSupliesTypesReducer/MedicalSupliesTypesReducer";

const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  userCreate: userCreateReducer,
  getUser: getUserReducer,
  getAllUser: getAllUserReducer,
  updateUser: updateUserReducer,
  showUser:showUserReducer,
  updateUserInformation:updateUserInformationReducer,

  getPage: getPageReducer,
  showPage: showPageReducer,
  createPermissionPage: createPermissionPageReducer,
  showPermissionPage: showPermissionPageReducer,

  showUserAdminstrator: showUserAdminstratorReducer,

  getBranch: getBranchReducer,
  getSection: getSectionReducer,

  getPatients: getPatientReducer,
  createPatientAdminstrator: createPatientAdminstratorReducer,
  createPatient: patientCreateReducer,
  rejectPatients: rejectPatientReducer,

  rejectPatientWithVisit: rejectPatientWithVisitReducer,
  rejectPatientVisit: rejectPatientVisitReducer,
  showRejectPatientVisit: showRejectPatientVisitReducer,

  allInfoPatient: allInfoPatientReducer,
  editPatient: editPatientReducer,
  updatePatient: updatePatientReducer,
  rejectPatientDisapproved: rejectPatientDisapprovedReducer,
  showReportMonthly: showReportMonthlyReducer,

  createImage: createImageReducer,
  getImage: getImageReducer,

  createCommite: createCommiteReducer,
  getCommite: getCommiteReducer,
  getCommiteInfo: getCommiteInfoReducer,
  getCommiteWithVisit: getCommiteWithVisitReducer,
  updateColorCommite:updateColorCommiteReducer,

  addFeedback: addFeedbackReducer,
  getFeedback: getFeedbackReducer,
  saveFeedback: saveFeedbackReducer,
  editFeedback: editFeedbackReducer,
  getFeedbackImage: getFeedbackImageReducer,

  createReceive: createReceivedReducer,
  getReceive: getReceiveReducer,
  showReceive: showReceiveReducer,
  getNotification: getNotificationReucer,
  seenNotification: seeNotificationReducer,

  rejectPatient: rejectPatientReducer,
  getRejectPatient: getRejectPatientReducer,

  receivePatientInfoMeida: receivePatientInfoMeidaReducer,
  sendInfoPatient: sendInfoPatientReducer,
  storeAllInfoPatient: storeAllInfoPatientReducer,
  // media
  getCoustmMediaInfo: getCoustmMediaInfoReducer,
  allInfoPatientMeda: getAllInfoPatientMedaReducer,
  getSortingMediaWork: getSortingWorkMediaReducer,
  updateSortingWorkMedia: updateSortingWorkMediaReducer,
  approveMediaWork: approvedMediaWorkReducer,
  getInfoFromLezhnaTbale: getInfoFromLezhnaTbaleReducer,
  seenReciveMedia: seenReciveMediaReducer,
  getReciveMediaByVisit: getReciveMediaByVisitReducer,
  rejectReciveMedia: rejectReciveMediaReducer,
  getAllInfoMedia: getAllInfoMediaReducer,
  // Economic
  getReceiveEconomic: getReceiveEconmicReducer,
  createReceiveEconomic: createReceiveEconomicReducer,

  // surgery
  getSurgery: getSurgeryReducer,
  getTypeSurgery: getTypeSurgeryReducer,
  getAllTypeSurgery: getAllTypeSurgeryReducer,
  createSurgery: createSurgeryReducer,
  createOrganLevel: createOrganLevelReducer,
  getOrganLevel: getOrganLevelReducer,

  createDoctor: createDoctorReducer,
  getDoctor: getDoctorReducer,
  updateDoctor: updateDoctorReducer,

  getStatistics: getStatisticsReducer,
  exportStatistics:exportStatisticsReducer,

  getVisitByPatientId:getVisitByPatientIdReducer,
  udpateVisit: updateVisitReducer,

  sendWhatsApp: sendWhatsAppReducer,
  getWhatsUpInfo: getWhatsUpInfoReducer,
  getWhatsAppInByVisit: getWhatsAppInByVisitReducer,
  getWhatsAppInfoExcel: getWhatsAppInfoExcelReducer,

  createTypeMedical: createTypeMedicalReducer,
  getTypeMedical: getTypeMedicalReducer,
  getImageTypeMedical: getImageTypeMedicalReducer,
  getTypeMedicalExcel: getTypeMedicalExcelReducer,
  getTypeMedicalByVisit: getTypeMedicalByVisitIdReducer,
  // Adminstrator
  getAdminstrator: getAdminstratorReducer,
  createAdminstrator: createAdminstratorReducer,
  deleteAdminstrator: deleteAdminstratorReducer,
  updateAdminstrator: updateAdminstratorReducer,
  // SentFiles Adminstrator
  getSentFilesAdminstrator: getSentFilesAdminstratorReducer,
  createSentFilesAdminstrator: createSentFilesAdminstratorReducer,
  deleteSentFilesAdminstrator: deleteSentFilesAdminstratorReducer,
  updateSentFilesAdminstrator: updateSentFilesAdminstratorReducer,
  // ReciveFilesAdminstrator
  createReciveFilesAdminstrator: createReciveFilesAdminstratorReducer,
  showReciveFilesAdminstrator: showReciveFilesAdminstratorReducer,
  updateReciveFilesAdminstrator: updateReciveFilesAdminstratorReducer,
  getReciveFilesAdminstrator: getReciveFilesAdminstratorReducer,
  //  Roles Reducer
  createRoles: createRolesReducer,
  getRoles: getRolesReducer,
  showRoles: showRolesReducer,

  getMedicalSupliesTypes:getMedicalSupliesTypesReducer,
  createMedicalSupliesTypes:createMedicalSupliesTypesReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
