import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_SENT_FILES_ADMINSTRATOR_FAIL,
  CREATE_SENT_FILES_ADMINSTRATOR_REQUEST,
  CREATE_SENT_FILES_ADMINSTRATOR_SUCCESS,
  DELETE_SENT_FILES_ADMINSTRATOR_FAIL,
  DELETE_SENT_FILES_ADMINSTRATOR_REQUEST,
  DELETE_SENT_FILES_ADMINSTRATOR_SUCCESS,
  FETCH_SENT_FILES_ADMINSTRATOR_FAIL,
  FETCH_SENT_FILES_ADMINSTRATOR_REQUEST,
  FETCH_SENT_FILES_ADMINSTRATOR_SUCCESS,
  UPDATE_SENT_FILES_ADMINSTRATOR_FAIL,
  UPDATE_SENT_FILES_ADMINSTRATOR_REQUEST,
  UPDATE_SENT_FILES_ADMINSTRATOR_SUCCESS,
} from "../../ActionType/AdminstratorType/SentFilesType";

export const getSentFileAdminstratorAction =
  (page, perPage, month, number, document, location_id) => (dispatch) => {
    let branch_id = location_id;
    const locationId = localStorage.getItem("locationId");
    if (location_id == 1000) {
      branch_id = locationId;
    }
    
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        //   Accept: "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        month:month.slice(5,7),
        number,
        document,
        branch_id,
      },
    };
    if (page == 1) {
      dispatch({
        type: FETCH_SENT_FILES_ADMINSTRATOR_REQUEST,
      });
    }

    api
      .get("sentFiles", config)
      .then((response) => {
        dispatch({
          type: FETCH_SENT_FILES_ADMINSTRATOR_REQUEST,
        });
        const { status, data } = response;
        
        
        if (status == 200) {
          dispatch({
            type: FETCH_SENT_FILES_ADMINSTRATOR_SUCCESS,
            payload: { data: data.data, total: data.total },
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Request",
          text: "Check Your Request",
          timer: 1500,
        });
        dispatch({
          type: FETCH_SENT_FILES_ADMINSTRATOR_FAIL,
          payload: [],
        });
      });
  };

export const createSentFilesAdminstratorAction =
  (document, number, location, date, nameFile) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        //   Accept: "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    const locationId = localStorage.getItem("locationId");
    // console.log(document)
    const formData = new FormData();
    formData.append("document", document);
    formData.append("number", number);
    formData.append("location", location);
    formData.append("date", date);
    formData.append("nameFile", nameFile);
    formData.append("branch_id", locationId);
    // console.log(formData)
    dispatch({
      type: CREATE_SENT_FILES_ADMINSTRATOR_REQUEST,
    });
    api
      .post("sentFiles/create", formData, config)
      .then((response) => {
        // console.log(response);
        const { status, data } = response;
        if (status == 200) {
          dispatch(
            getSentFileAdminstratorAction(1, 10, "", "", "", locationId)
          );
          dispatch({
            type: CREATE_SENT_FILES_ADMINSTRATOR_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            title: "سەرکەوتوو بوو",
            text: "زانیاریەکە داخل کرا",
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Request",
          text: "Check Your Request",
          timer: 1500,
        });
        dispatch({
          type: CREATE_SENT_FILES_ADMINSTRATOR_FAIL,
        });
      });
  };

export const deleteSentFilesAdminstratorAction =
  (id, name_file,extension) => (dispatch) => {
    // console.log(extension)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        name_file,
        extension
      },
    };

    dispatch({
      type: DELETE_SENT_FILES_ADMINSTRATOR_REQUEST,
    });
    api
      .delete(`sentFiles/delete/${id}`, config)
      .then((response) => {
        // console.log(response)
        const { status, data } = response;
        console.log(response)
        if (status == 200) {
          dispatch({
            type: DELETE_SENT_FILES_ADMINSTRATOR_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            title: "سەرکەوتوو بوو",
            text: "زانیاریەکە سڕایەوە",
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SENT_FILES_ADMINSTRATOR_FAIL,
          payload: [],
        });
        Swal.fire({
          icon: "error",
          title: "Request",
          text: "Check Your Request",
          timer: 1500,
        });
        console.log(error);
      });
  };

export const updateSentFilesAdminstratorAction =
  (id, nameFile, oldNameFile, date, number, location, document,extension) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_SENT_FILES_ADMINSTRATOR_REQUEST,
    });

    const formData = new FormData();
    formData.append("document", document);
    formData.append("number", number);

    formData.append("location", location);
    formData.append("oldNameFile", oldNameFile);

    formData.append("date", date);
    formData.append("nameFile", nameFile);
    formData.append("extension", extension);
    
    api
      .post(`sentFiles/update/${id}`, formData, config)
      .then((response) => {
        const { status, data } = response;
        console.log(response);
        if (status == 200) {
          dispatch({
            type: UPDATE_SENT_FILES_ADMINSTRATOR_SUCCESS,

            payload: data,
          });
          Swal.fire({
            icon: "success",
            title: "سەرکەوتوو بوو",
            text: "گۆرانکارییەکە سەرکەوتوو بوو",
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SENT_FILES_ADMINSTRATOR_FAIL,
          payload: [],
        });
        Swal.fire({
          icon: "error",
          title: "Request",
          text: "Check Your Request",
          timer: 1500,
        });
        console.log(error);
      });
  };

export const showAdminstratorAction = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get(`adminstrator/show/${id}`, config)
    .then((response) => {
      const { status, data } = response;
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Request",
        text: "Check Your Request",
        timer: 1500,
      });

      // console.log(error);
    });
};
