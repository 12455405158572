import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
// import { createPatient, getPatient } from '../Action/patient';
import { useDispatch, useSelector } from "react-redux";
// import { getInfoCommite } from "../Action/commiteAction";
import { MdOutlinePersonalInjury } from "react-icons/md";
import Logo from "../Image/Logo.jpg";
import "../Pages/Branchs/css/invoice.css";
import { editPatientAction } from "../Action/patient";

const ViewModal = ({ closeModal, commiteId, patientId }) => {
  const dispatch = useDispatch();
  const getEditPatient = useSelector((state) => state.editPatient);
  const { patients, loading } = getEditPatient;
  const [visitId, setVisitId] = useState(null);
  useEffect(() => {
    dispatch(editPatientAction(patientId, visitId));
    
  }, [visitId]);
  return (
    <div
      className={
        closeModal ? "modalContainerAssign w-full  " : "closeModalContainer"
      }
    >
      <div className="overlay" onClick={() => closeModal(false)}></div>
      <div className="childModal ">
        <div className="flex justify-between ">
          {patients == undefined || loading ? (
            ""
          ) : (
            <div className="flex items-center gap-3 flex-row-reverse">
              {patients?.map((patient) => {
                return (
                  <select
                    name=""
                    onChange={(e) => setVisitId(e.target.value)}
                    value={visitId}
                    className="select"
                    id=""
                  >
                    <option value="-1">Select Visitation</option>
                    {patient.visit.map((visit, index) => {
                      return (
                        <option value={visit.id} key={index}>
                          {visit.created_at.toString().slice(0, 10)}
                        </option>
                      );
                    })}
                  </select>
                );
              })}
            </div>
          )}

          <div
            className="text-3xl cursor-pointer text-red-600"
            onClick={() => closeModal(false)}
          >
            x
          </div>
        </div>
        <div className="text-center bg-teal-500 mt-4  py-2 rounded-md shadow-md text-white font-bold w-10/12 md:w-2/12 m-auto">
          <p className="lg:text-lg">فۆرمی زانیاری نەخۆش</p>
        </div>
        {/* form */}
        {loading || patients == undefined ? (
          <img src={Logo} className="w-44 m-auto mt-5 animate-bounce " alt="" />
        ) : (
          <>
            {visitId == null ? (
              ""
            ) : (
              <form action="">
                {patients.map((patient, index) => {
                  return (
                    <div key={index}>
                      <div className="parentInputGrid">
                        {/* end parent Input & label */}

                        <div className="w-full mt-2">
                          <div className="w-full flex justify-end">
                            <label className="lblDesign" htmlFor="phoneNumber">
                              {" "}
                              *رەقەم مۆبایل
                            </label>
                          </div>
                          <input
                            type="text"
                            name="closetHome"
                            readOnly
                            defaultValue={patient.phone}
                            id="phoneNumber"
                            className="inputUser w-full text-right"
                            placeholder="رەقەم مۆبایلرەقەم مۆبایل"
                          />
                        </div>
                        {/* end parent Input & label */}
                        <div className="w-full ">
                          <div className="w-full  ">
                            <div className="relative w-full ">
                              <div className="child ">
                                <div className="w-full flex justify-end">
                                  <label className="lblDesign" htmlFor="gender">
                                    *ڕەگەز{" "}
                                  </label>
                                </div>
                                <div className="coustmSelect ">
                                  <p>
                                    {patient.gender == "" ||
                                    patient.gender == null
                                      ? ".. ڕەگەز "
                                      : patient.gender}
                                  </p>
                                  <div className={"parentCaretDown"}>
                                    <AiFillCaretDown />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end parent Input & label */}

                        <div className="w-full mt-2">
                          <div className="w-full flex justify-end">
                            <label className="lblDesign" htmlFor="birth">
                              * بەرواری لە دایکبوون
                            </label>
                          </div>
                          <input
                            type="text"
                            name="card"
                            readOnly
                            value={patient.birthDate}
                            id="birth"
                            className="inputUser w-full text-right"
                            placeholder="بەرواری لە دایکبوون"
                          />
                        </div>
                        {/* end of label and inpt */}

                        <div className="w-full mt-2">
                          <div className="w-full flex justify-end">
                            <label className="lblDesign" htmlFor="name">
                              *ناوی نەخۆش
                            </label>
                          </div>
                          <input
                            type="text"
                            name="name"
                            readOnly
                            value={patient.Pname}
                            id="number"
                            className="inputUser w-full text-right"
                            placeholder="ناوی نەخۆش"
                          />
                        </div>
                        {/* end parent Input & label */}
                      </div>

                      {/* maping commite */}
                      {patient.commites
                        .filter((filter) => filter.visit_id == visitId)
                        .map((infoCommite, index) => {
                          return (
                            <>
                              <div className="parentInputGrid" key={index}>
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="province"
                                    >
                                      * پارێزگا{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="province"
                                    readOnly
                                    id="province"
                                    value={infoCommite.province}
                                    className="inputUser w-full text-right"
                                    placeholder="پارێزگا"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="w-full flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="nation"
                                    >
                                      {" "}
                                      *نەتەوە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="nation"
                                    readOnly
                                    defaultValue={infoCommite.nation}
                                    id="nation"
                                    className="inputUser w-full text-right"
                                    placeholder="نەتەوە"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="w-full flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="country"
                                    >
                                      {" "}
                                      * وڵات{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="country"
                                    readOnly
                                    value={infoCommite.country}
                                    id="country"
                                    className="inputUser w-full text-right"
                                    placeholder="وڵات"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="w-full flex justify-end ">
                                    <label className="lblDesign" htmlFor="occ">
                                      {" "}
                                      *پیشە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="occupation"
                                    readOnly
                                    value={infoCommite.occuption}
                                    id="occ"
                                    className="inputUser w-full  text-right"
                                    placeholder="پیشە"
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>
                              <div className="parentInputGrid">
                                {/* end parent Input & label */}

                                <div></div>
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="disc">
                                      * ناحیە/قەزا{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="district"
                                    readOnly
                                    id="disc"
                                    value={infoCommite.district}
                                    className="inputUser w-full  text-right"
                                    placeholder="ناحیە/قەزا"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="str">
                                      *شەقام گەڕەک{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="street"
                                    id="str"
                                    readOnly
                                    value={infoCommite.street}
                                    className="inputUser w-full text-right"
                                    placeholder="شەقام گەڕەک"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="closetHome"
                                    >
                                      *نزیکترین شوێن لە ماڵیانەوە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="closetHome"
                                    readOnly
                                    defaultValue={infoCommite.closetHome}
                                    id="closetHome"
                                    className="inputUser w-full text-right"
                                    placeholder="نزیکترین شوێن لە ماڵیانەوە"
                                  />
                                </div>

                                <div className="w-full mt-2">
                                  <div className="w-full justify-end flex">
                                    <label
                                      className="lblDesign"
                                      htmlFor="scoundPhoneNo"
                                    >
                                      *ژ.مۆبایلی دووەم
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="scoundPhoneNo"
                                    readOnly
                                    value={infoCommite.secoundHome}
                                    id="scoundPhoneNo"
                                    className="inputUser w-full  text-right"
                                    placeholder="ژ.مۆبایلی دووەم"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="card">
                                      ژ.کارتی نیشتمانی یاخود ناسنامەی
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="card"
                                    id="card"
                                    readOnly
                                    value={infoCommite.card}
                                    className="inputUser w-full text-right"
                                    placeholder="ژ.کارتی نیشتمانی یاخود ناسنامەی "
                                  />
                                </div>

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="noFamily"
                                    >
                                      *ژمارەی خێزان
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="NoFamily"
                                    readOnly
                                    value={infoCommite.noFamily}
                                    id="number"
                                    className="inputUser w-full text-right"
                                    placeholder="ژمارەی خێزان"
                                  />
                                </div>
                                {/* end label and input */}
                                <div className="w-full ">
                                  <div className="relative w-full ">
                                    <div className="child ">
                                      <div className="flex justify-end ">
                                        <label
                                          className="lblDesign"
                                          htmlFor="gender"
                                        >
                                          {" "}
                                          * خێزاندارە{" "}
                                        </label>
                                      </div>
                                      <div className="coustmSelect ">
                                        <p>{infoCommite.married || "خێزاندارە ؟"}</p>
                                        <div className={"parentCaretDown"}>
                                          <AiFillCaretDown />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div></div>
                                <div className="w-full  ">
                                  <div className="relative w-full ">
                                    <div className="child ">
                                      <div className="w-full flex justify-end ">
                                        <label
                                          className="lblDesign"
                                          htmlFor="name"
                                        >
                                          {" "}
                                          * خوێندەوارە
                                        </label>
                                      </div>
                                      <div className="coustmSelect">
                                        <p>{infoCommite.literate || " خوێندەوارە"}</p>
                                        <div className={"parentCaretDown"}>
                                          <AiFillCaretDown />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full  ">
                                  <div className="relative w-full ">
                                    <div className="child ">
                                      <div className="w-full flex justify-end ">
                                        <label
                                          className="lblDesign"
                                          htmlFor="name"
                                        >
                                          {" "}
                                          *جگەرە کێشە
                                        </label>
                                      </div>
                                      <div className="coustmSelect">
                                        <p>{infoCommite.smoke || "جگەرە کێشە"}</p>
                                        <div className={"parentCaretDown"}>
                                          <AiFillCaretDown />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="w-full justify-end flex">
                                    <label
                                      className="lblDesign"
                                      htmlFor="timeAllot"
                                    >
                                      * کاتی دیاریکراو بۆ هەڵسەنگاندن{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="timeAllot"
                                    id="timeAllot"
                                    readOnly
                                    value={infoCommite.timeAllote}
                                    className="inputUser w-full text-right"
                                    placeholder="کاتی دیاریکراو بۆ هەڵسەنگاندن"
                                  />
                                </div>
                              </div>
                              <div className="text-center bg-teal-500 mt-4  py-2 rounded-md shadow-md text-white font-bold w-10/12 md:w-2/12 m-auto">
                                <p className="lg:text-lg">زانیاری پزیشکی</p>
                              </div>
                              {/* end grid col */}
                              <div className="parentInputGrid">
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="noMember"
                                    >
                                      * لەسەر ئاستی ئەندام
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="noMember"
                                    readOnly
                                    id="noMember"
                                    value={infoCommite.memberLevel}
                                    className="inputUser w-full text-right"
                                    placeholder="لەسەر ئاستی ئەندام"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="member"
                                    >
                                      * ئەندام
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="member"
                                    readOnly
                                    id="member"
                                    value={infoCommite.member}
                                    className="inputUser w-full text-right"
                                    placeholder="ئەندام"
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <label className="lblDesign" htmlFor="name">
                                    TYP *
                                  </label>
                                  <input
                                    type="text"
                                    name="typ"
                                    id="name"
                                    readOnly
                                    value={infoCommite.TYP}
                                    className="inputUser w-full  text-right"
                                    placeholder="TYP"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="spid">
                                      * پسپۆڕی جۆری نەخۆشی{" "}
                                    </label>
                                  </div>

                                  <input
                                    type="text"
                                    name="SPID"
                                    readOnly
                                    id="spid"
                                    value={infoCommite.SITD}
                                    className="inputUser w-full text-right"
                                    placeholder="پسپۆڕی جۆری نەخۆشی"
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>

                              <div className="parentInputGrid">
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="ea">
                                      * نەخۆشی پێش وەخت
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="earlyPatient"
                                    value={infoCommite.earlyPatient}
                                    id="ea"
                                    className="inputUser w-full  text-right"
                                    placeholder="نەخۆشی پێش وەخت"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <label
                                    className="lblDesign"
                                    htmlFor="indication"
                                  >
                                    Indiction *
                                  </label>
                                  <input
                                    type="text"
                                    readOnly
                                    name="indiction"
                                    value={infoCommite.indiction}
                                    id="indication"
                                    className="inputUser w-full  text-right"
                                    placeholder="Indiction"
                                  />
                                </div>

                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <label className="lblDesign" htmlFor="hpe">
                                    H.P.E *
                                  </label>
                                  <input
                                    type="text"
                                    name="HPE"
                                    readOnly
                                    value={infoCommite.HEP}
                                    id="hpe"
                                    className="inputUser w-full text-right"
                                    placeholder="H.P.E "
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <label className="lblDesign" htmlFor="final">
                                    Final Diagnosis *
                                  </label>
                                  <input
                                    type="text"
                                    id="final"
                                    readOnly
                                    name="finalDiagnos"
                                    value={infoCommite.finalDiagnosis}
                                    className="inputUser w-full text-right"
                                    placeholder="Final Diagnosis"
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>

                              <div className="parentInputGrid">
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="dr">
                                      {" "}
                                      * پزیشکی نێردراو بۆ
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="doctor"
                                    id="dr"
                                    value={infoCommite.Doctor}
                                    className="inputUser w-full text-right"
                                    placeholder="پزیشکی نێردراو بۆ"
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="ZODoctor"
                                    >
                                      {" "}
                                      *پزیشکی هاوکاری ڕیکخراوی زادە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="ZODoctor"
                                    value={infoCommite.ZODoctor}
                                    className="inputUser w-full text-right"
                                    placeholder="پزیشکی هاوکاری ڕیکخراوی زادە"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="medicalProvid"
                                    >
                                      * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="medicalProvid"
                                    id="medicalProvid"
                                    value={infoCommite.medicalProvided}
                                    className="inputUser w-full  text-right"
                                    placeholder="پێداویستی پزیشکی بۆ دابینکراوە"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="earlySurgery"
                                    >
                                      * نەشتەرگەری پێشوەخت{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="earlySurgery"
                                    value={infoCommite.earlySurgery}
                                    id="earlySurgery"
                                    className="inputUser w-full  text-right"
                                    placeholder="نەشتەرگەری پێشوەخت "
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>
                              <div className="parentInputGrid">
                                <div></div>
                                <div className="w-full mt-2">
                                  <div className="flex justify-end w-full ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="typeMedical"
                                    >
                                      * جۆری پێداویستی پزیشکی{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="typeMedical"
                                    id="typeMedical"
                                    value={infoCommite.Typemedicalsupplies}
                                    className="inputUser w-full  text-right"
                                    placeholder="جۆری پێداویستی پزیشکی"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="supplyLocation"
                                    >
                                      شوێنی دابینکردنی پێداویستی
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="supplyLocation"
                                    value={infoCommite.supplyLocation}
                                    className="inputUser w-full text-right"
                                    placeholder="شوێنی دابینکردنی پێداویستی"
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="helpingZO"
                                    >
                                      هاوکاری رێکخراوی زادە
                                    </label>
                                  </div>

                                  <input
                                    type="text"
                                    readOnly
                                    name="helpingZO"
                                    id="helpingZO"
                                    value={infoCommite.helpingZO}
                                    className="inputUser w-full text-right"
                                    placeholder="هاوکاری رێکخراوی زادە"
                                  />
                                </div>
                              </div>
                              <div className="w-full mt-2">
                                <textarea
                                  name=""
                                  value={infoCommite.note}
                                  id=""
                                  className="inputUser w-full text-right"
                                  rows="6"
                                ></textarea>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  );
                })}
              </form>
            )}
          </>
        )}
      </div>
      <div className="pb-96"></div>
    </div>
  );
};

export default ViewModal;
