import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approvedMediaWorkAction,
  getSortingMediaWorkAction,
  updateSortingMediaWork,
} from "../../../Action/Media/SortingMediaWorkAction";
import { GrCheckmark } from "react-icons/gr";
import { TbArrowsExchange } from "react-icons/tb";
import Logo from "../../../Image/Logo.jpg";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { getUserAuth } from "../../../Action/userRegister";

const SortingMediaWork = () => {
  const getSortingMediaWork = useSelector((state) => state.getSortingMediaWork);
  const dispatch = useDispatch();
  const getUsers = useSelector((state) => state.getUser);
  const { users } = getUsers;

  useEffect(() => {
    dispatch(getUserAuth());
  }, []);

  const { sortingWork, loading, total } = getSortingMediaWork;
  const [valueOfSorting, setValueOfSorting] = useState(0);
  const [indexx, setIndexx] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  useEffect(() => {
    dispatch(getSortingMediaWorkAction(currentPage, perPage));
  }, [currentPage]);

  const handleSubmit = (event, id) => {
    event.preventDefault();
    if (valueOfSorting > total) {
      Swal.fire({
        icon: "error",
        timer: 2000,
        text: "You do not have this Section ",
      });
    } else {
      dispatch(updateSortingMediaWork(valueOfSorting, id));
      dispatch(getSortingMediaWorkAction(currentPage, perPage));
      dispatch(getSortingMediaWorkAction(currentPage, perPage));
    }
  };
  const handleApproved = (event, id) => {
    event.preventDefault();
    dispatch(approvedMediaWorkAction(id));
    dispatch(getSortingMediaWorkAction(currentPage, perPage));
    dispatch(getSortingMediaWorkAction(currentPage, perPage));
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white relative  md:h-[30rem] xl:h-[36rem] 3xl:h-[47rem] overflow-y-auto w-[95%] m-auto  shadow-md rounded-md py-4 px-6">
          <div className="grid xl:grid-cols-2 gap-4">
            {sortingWork.map((element, index) => {
              return (
                <div
                  key={index}
                  className={
                    "bg-gray-50  duration-1000 rounded-md justify-between items-center shadow-md py-4 px-3 flex"
                  }
                >
                  <div>
                    <h1 className="text-2xl font-bold">{element.title}</h1>
                    <div>
                      <p>{element.desc}</p>
                      <div>
                        <p className=" text-sm text-blue-700">
                          By : {element.sName}
                        </p>
                        <div></div>
                      </div>
                    </div>
                    {users.rule == "admin" ||
                      (users.rule == "super_admin" && (
                        <form action="" className="mt-3">
                          <div className="flex gap-4">
                            <div>
                              <button
                                className="btnCoustmDesignBlue mt-1 "
                                onClick={(e) => {
                                  handleApproved(e, element.id);
                                }}
                              >
                                <p className="text-white">
                                  <GrCheckmark />
                                </p>
                              </button>
                            </div>
                            <div>
                              <button
                                className="btnCoustmDesignGreen mt-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIndexx(index);
                                }}
                              >
                                <TbArrowsExchange />
                              </button>
                            </div>
                            {indexx == index ? (
                              <div className="flex gap-2 items-center">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setValueOfSorting(e.target.value)
                                  }
                                  value={valueOfSorting || ""}
                                  className="inputLogin p-1.5"
                                />
                                <div>
                                  <button
                                    className="btnCoustmDesignBlue p-1"
                                    onClick={(e) => {
                                      handleSubmit(e, element.id);
                                      setIndexx(null);
                                      setValueOfSorting("");
                                    }}
                                  >
                                    Change
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </form>
                      ))}
                  </div>

                  <div className="bg-indigo-700 shadow-md  px-3 py-1 rounded-full bg-opacity-90 text-white">
                    <p>{index + 1}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center absolute mt-4">
            <ReactPaginate
              previousLabel="<"
              nextLabel={">"}
              // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
              breakLabel="..."
              className="flex  items-center  -space-x-px h-8 text-sm"
              previousClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg "
              nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg "
              pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300  "
              breakClassName="w-8 py-2 text-center"
              pageCount={Math.ceil(total / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              activeClassName=""
              onPageChange={(event) => {
                let currentPage = event.selected + 1;
                dispatch(getSortingMediaWorkAction(currentPage, perPage));
                setCurrentPage(currentPage);
              }}
              activeLinkClassName="bg-[#000001] text-white flex  items-center  -space-x-px h-8 text-sm"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SortingMediaWork;
