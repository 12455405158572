import React, { useEffect, useState } from "react";
import { FaUserDoctor } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";

import DoctorModal from "../../Modal/DoctorModal/DoctorModal";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../Image/Logo.jpg";
import {
  getDoctorAction,
  updateDoctorAction,
} from "../../Action/Doctor/doctorAction";
import { MdModeEdit } from "react-icons/md";

import "./css/doctor.css";
import { ImCheckmark, ImCross } from "react-icons/im";
import { UPDATE_DOCTOR_SUCCESS } from "../../ActionType/DoctorType/DoctorType";

const Doctor = () => {
  const initialState = {
    index: null,
    name: "",
    status: "Active",
    expert: "",
    id: "",
  };
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [indexx, setIndexx] = useState(null);
  const updateDoctor = useSelector((state) => state.updateDoctor);
  const { error } = updateDoctor;
  const [inputValue, setInputValue] = useState(initialState);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    dispatch(
      updateDoctorAction(
        inputValue.id,
        inputValue.name,
        inputValue.status,
        inputValue.expert
      )
    );
    setInputValue(initialState);
  };
  const [isOpenDoctorModal, setIsOpenDoctorModal] = useState(false);
  const dispatch = useDispatch();
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors, loading } = getDoctor;
  useEffect(() => {
    dispatch(
      getDoctorAction(
        searchParams.name,
        searchParams.expert,
        searchParams.status
      )
    );
  }, []);

  const handleCloseAlert = () => {
    dispatch({
      type: UPDATE_DOCTOR_SUCCESS,
    });
  };

  const handleOpenUpdate = (name, expert, status, index, id) => {
    setInputValue({
      ...inputValue,
      name: name,
      expert: expert,
      status: status,
      index: index,
      id: id,
    });
  };
  const initialSearch = {
    name: "",
    expert: "",
    status: "",
  };
  const [searchParams, setSearchParams] = useState(initialSearch);
  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleSearch = () => {
    dispatch(
      getDoctorAction(
        searchParams.name,
        searchParams.expert,
        searchParams.status
      )
    );
    setIsOpenFilter(false);
  };
  const handleClearSearch = (name) => {
    const updateSearchParams = { ...searchParams };
    updateSearchParams[name] = "";
    setSearchParams(updateSearchParams);
    dispatch(
      getDoctorAction(
        updateSearchParams.name,
        updateSearchParams.expert,
        updateSearchParams.status
      )
    );
    setIsOpenFilter(false);
  };
  const handleClear = () => {
    setInputValue(initialState);
  };
  const thead = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Expert",
      value: "expert",
    },
    {
      label: "Status",
      value: "status",
    },
  ];
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative bg-white md:h-[18rem] xl:h-[30rem]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-4">
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Error !: </strong>
              <span className="block sm:inline">
                {error.dr_name || error.expert}
              </span>
              <span
                onClick={handleCloseAlert}
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
              >
                <svg
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}

          <div className="flex justify-between bg-gray-100 px-5 py-3">
            <div className="flex gap-2 items-center">
              <p className="text-green-800 text-xl">
                <FaUserDoctor />
              </p>
              <p>Doctors</p>
            </div>
            <div>
              <button
                className="btn-info tracking-wider"
                onClick={() => setIsOpenDoctorModal(true)}
              >
                Doctor +
              </button>
            </div>
          </div>
          <hr />
          <div class="relative w-full overflow-y-auto rounded-md overflow-x-auto md:h-[84%] xl:h-[90%]">
            <table className="w-full">
              <thead className="sticky top-0 border ">
                <tr>
                  <th className="global-th ">#</th>
                  {thead.map((th, index) => {
                    return (
                      <th key={index} className="global-th relative">
                        <div className="flex justify-between items-center ">
                          <p>{th.label}</p>
                          <div
                            className="parent-filter"
                            onClick={() => {
                              setIsOpenFilter(!isOpenFilter);
                              setIndexx(index);
                            }}
                          >
                            <CiFilter />
                          </div>
                        </div>
                        {isOpenFilter && index == indexx && (
                          <div className="content-filter">
                            <input
                              type="text"
                              className="inputUser w-full text-black"
                              placeholder={th.label}
                              name={th.value}
                              value={searchParams[th.value]}
                              onChange={handleSearchChange}
                            />

                            <div className="footer-filter">
                              <button
                                className="btn-plus"
                                onClick={handleSearch}
                              >
                                <ImCheckmark />
                              </button>
                              <button
                                className="btn-danger"
                                onClick={() => handleClearSearch(th.value)}
                              >
                                <ImCross />
                              </button>
                            </div>
                          </div>
                        )}
                      </th>
                    );
                  })}

                  <th className="global-th w-32">Action</th>
                </tr>
              </thead>

              <tbody>
                {doctors?.map((doctor, index) => {
                  return (
                    <tr key={index}>
                      <td className="global-td">{index + 1}</td>
                      <td className="global-td">
                        {/* {inputValue.index} */}
                        {index == inputValue.index ? (
                          <input
                            type="text"
                            className="inputUser "
                            name="name"
                            onChange={handleInputChange}
                            value={inputValue.name}
                          />
                        ) : (
                          doctor.dr_name
                        )}
                      </td>
                      <td className="global-td">
                        {index == inputValue.index ? (
                          <input
                            type="text"
                            className="inputUser w-full"
                            value={inputValue.expert}
                            placeholder="Expert"
                            name="expert"
                            onChange={handleInputChange}
                          />
                        ) : (
                          doctor.expert
                        )}
                      </td>
                      <td className="global-td">
                        {index == inputValue.index ? (
                          <select
                            name="status"
                            onChange={handleInputChange}
                            value={inputValue.status}
                            id=""
                          >
                            <option value="Active">Active</option>
                            <option value="Deactive">Deactive</option>
                          </select>
                        ) : (
                          <p
                            className={
                              doctor.status == "Active"
                                ? "btn-info w-11/12 md:w-5/12  lg:w-4/12 xl:w-2/12"
                                : "btn-danger w-11/12 md:w-5/12  lg:w-4/12 xl:w-2/12"
                            }
                          >
                            {doctor.status}
                          </p>
                        )}
                      </td>
                      <td className="global-td ">
                        {inputValue.index == index && (
                          <div className="flex gap-2 items-center justify-center">
                            <button className="btn-info" onClick={handleUpdate}>
                              <ImCheckmark />
                            </button>
                            <button
                              className="btn-danger"
                              onClick={handleClear}
                            >
                              <ImCross />
                            </button>
                          </div>
                        )}
                        {inputValue.index == null && (
                          <div className="flex items-center justify-center">
                            <button
                              onClick={() => {
                                handleOpenUpdate(
                                  doctor.dr_name,
                                  doctor.expert,
                                  doctor.status,
                                  index,
                                  doctor.id
                                );
                              }}
                              className="btn-primary flex gap-2 items-center rounded-sm"
                            >
                              <p>Edit</p>
                              <div>
                                <MdModeEdit />
                              </div>
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isOpenDoctorModal && (
            <DoctorModal setIsOpenDoctorModal={setIsOpenDoctorModal} />
          )}
        </div>
      )}
    </>
  );
};

export default Doctor;
