import React, { useEffect, useState } from "react";

import { FaFilter } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  getCoustmMediaInfoAction,
  seenMediaReciveAction,
} from "../../../Action/Media/sendMediaAction";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { AiTwotoneEye } from "react-icons/ai";

const ShowReceive = () => {
  const { id } = useParams();
  const locationId = localStorage.getItem("locationId");
  const dispatch = useDispatch();
  const getCoustmMediaInfo = useSelector((state) => state.getCoustmMediaInfo);
  const { getMediaInfo, total, loading } = getCoustmMediaInfo;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [searchParams, setSearchParams] = useState({
    fullname: null,
    doctor: null,
    hospitalName: null,
    typeOfSurgery: null,
    timeOfSurgery: null,
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  useEffect(() => {
    console.log("get media : ",getMediaInfo)
    dispatch(
      getCoustmMediaInfoAction(
        locationId,
        currentPage,
        perPage,
        searchParams.fullname,
        searchParams.doctor,
        searchParams.typeOfSurgery,
        searchParams.hospitalName,
        searchParams.timeOfSurgery
      )
    );
  }, [
    searchParams,
    currentPage,
    perPage,
  ]);
  const handleSeen = (id) => {
    dispatch(seenMediaReciveAction(id));
    dispatch(
      getCoustmMediaInfoAction(
        locationId,
        currentPage,
        perPage,
        searchParams.fullname,
        searchParams.doctor,
        searchParams.typeOfSurgery,
        searchParams.hospitalName,
        searchParams.timeOfSurgery
      )
    );
    dispatch(
      getCoustmMediaInfoAction(
        locationId,
        currentPage,
        perPage,
        searchParams.fullname,
        searchParams.doctor,
        searchParams.typeOfSurgery,
        searchParams.hospitalName,
        searchParams.timeOfSurgery
      )
    );
  };
  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleFilterOpen = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  return (
    <div className=" mt-5 m-auto">
      {/* <MediaTable locationId={id} /> */}
      {loading ? (
        <div className="m-auto">
          <span class="loader"></span>
        </div>
      ) : (
        <div className="bg-white relative w-full  xl:w-[95%] m-auto  h-[27rem] xl:h-[35rem] 3xl:h-[45rem]">
          <div className="h-[90%] overflow-y-auto w-full">
            <table className="overflow-y-auto w-full">
              <thead className="sticky top-0">
                <tr>
                  <th className="">
                    <div className="flex gap-3 items-center justify-between">
                      <p>Full Name</p>
                      <div
                        className="cursor-pointer filterIcon"
                        onClick={handleFilterOpen}
                      >
                        <FaFilter />
                      </div>
                    </div>

                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="fullname"
                        placeholder="Full Name"
                        type="text"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded  "
                            : "opacity-0  absolute duration-500"
                        }
                      />
                    </div>
                  </th>
                  <th>
                    <div className="flex gap-3 items-center justify-between">
                      <p>Doctor Name</p>
                      <div
                        className="cursor-pointer filterIcon"
                        onClick={handleFilterOpen}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="doctor"
                        type="text"
                        placeholder="Doctor"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded "
                            : "opacity-0  absolute duration-500"
                        }
                      />
                    </div>
                  </th>

                  <th>
                    <div className="flex justify-between lg:gap-3 items-center">
                      <p>Type Of Surgery</p>
                      <div
                        className="cursor-pointer filterIcon"
                        onClick={handleFilterOpen}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="typeOfSurgery"
                        placeholder="Surgery"
                        type="text"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded "
                            : "opacity-0 absolute border-b-2 duration-500"
                        }
                      />
                    </div>
                  </th>
                  <th>
                    <div className="flex gap-3 items-center justify-between">
                      <p>Hospital Name</p>
                      <div
                        className="cursor-pointer filterIcon"
                        onClick={handleFilterOpen}
                      >
                        <FaFilter />
                      </div>
                    </div>

                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Hospital"
                        onBlur={handleSearchParams}
                        name="hospitalName"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded "
                            : "opacity-0  absolute duration-500"
                        }
                      />
                    </div>
                  </th>
                  <th>
                    <div className="flex lg:gap-3 items-center">
                      <p>Time Of Surgery</p>
                      <div
                        className="cursor-pointer filterIcon"
                        onClick={handleFilterOpen}
                      >
                        <FaFilter />
                      </div>
                    </div>

                    <div className="w-full">
                      <input
                        type="date"
                        onBlur={handleSearchParams}
                        name="timeOfSurgery"
                        placeholder="Time "
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded "
                            : "opacity-0  absolute duration-500"
                        }
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getMediaInfo.map((item, index) => {
                  return (
                    <tr
                      className={item.seen == 0 ? "tableRow" : "tableRowSave"}
                      key={index}
                    >
                      <td className="py-2.5">{item.pName}</td>
                      <td>{item.dName}</td>
                      <td>{item.typeSurgery}</td>
                      <td>{item.hospName}</td>
                      <td>{item.timeOfSurgery}</td>
                      <td>
                        <button
                          onClick={() => {
                            handleSeen(item.id);
                          }}
                          title="seen"
                          className="btnCoustmDesignBlue"
                        >
                          <AiTwotoneEye />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-end gap-4 absolute bottom-1 right-2">
            <div>
              <select
                name=""
                className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                onChange={(e) => setPerPage(e.target.value)}
                value={perPage}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="flex justify-center   mt-1">
              <ReactPaginate
              previousLabel="<"
              nextLabel={">"}
              // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
              breakLabel="..."
              className="flex  items-center   -space-x-px h-8 text-sm"
              previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
              nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
              pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
              breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getCoustmMediaInfoAction(
                      id,
                      currentPage,
                      perPage,
                      "",
                      "",
                      "",
                      "",
                      ""
                    )
                  );
                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}

      {/* invoce */}
    </div>
  );
};

export default ShowReceive;
