import React, { useEffect, useState } from "react";
import { BiSolidParty } from "react-icons/bi";
import { BsCalendarDateFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import {
  FaBirthdayCake,
  FaChild,
  FaCodeBranch,
  FaLocationArrow,
  FaUserAlt,
  FaUserShield,
} from "react-icons/fa";
import { FaCircleUser, FaLocationCrosshairs, FaPhone } from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";
import { GiRadarCrossSection } from "react-icons/gi";

import { GrPersonalComputer } from "react-icons/gr";
import {
  MdBloodtype,
  MdOutlineEmail,
  MdOutlineSick,
  MdOutlineTransgender,
  MdSensorOccupied,
} from "react-icons/md";
import { TbCertificate } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../../Action/section";
import { getBranch } from "../../Action/branch";
import { getRolesAction } from "../../Action/RolesAction/RolesAction";
import { getUserAuth, userRegister } from "../../Action/userRegister";
import { useNavigate } from "react-router-dom";
const AddUser = () => {
  const getSectionReducer = useSelector((state) => state.getSection);
  const { sections } = getSectionReducer;
  const getBranchReducer = useSelector((state) => state.getBranch);
  const { branchs } = getBranchReducer;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSection());
    dispatch(getBranch());
    dispatch(getUserAuth());
  }, []);

  const getUsers = useSelector((state) => state.getUser);

  const { users } = getUsers;

  const [brnach, setBranch] = useState(null);
  useEffect(() => {
    
    if (users == undefined) {
      return;
    }
    setBranch(users.role.permission[0].section_id);
  }, [users]);
  const getRoles = useSelector((state) => state.getRoles);
  const { roles, loading, total } = getRoles;
  // const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    EnName: "",
    KuName: "",
    birthDate: "",
    gender: "",
    phone: "",
    secPhone: "",
    pnpc: "",
    email: "",
    married: "",
    NumberOfChildern: "",
    bloodGorup: "",
    DateOfCommencement: "",
    ChronicDiseases: "",
    certificate: "",
    certificateField: "",
    OccupationOutside: "",
    Workplace: "",
    jobTitle: "",
    LanguageLevel: "",
    Computerskills: "",
    branch: "",
    section: "",
    role: "",
  });
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRolesAction(1, 100));
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      EnName,
      KuName,
      birthDate,
      gender,
      phone,
      secPhone,
      pnpc,
      email,
      married,
      NumberOfChildern,
      bloodGorup,
      DateOfCommencement,
      ChronicDiseases,
      certificate,
      certificateField,
      OccupationOutside,
      Workplace,
      jobTitle,
      LanguageLevel,
      Computerskills,
      branch,
      section,
      role,
    } = inputValue;
    
    dispatch(
      userRegister(
        EnName,
        KuName,
        birthDate,
        gender,
        phone,
        secPhone,
        pnpc,
        email,
        married,
        NumberOfChildern,
        bloodGorup,
        DateOfCommencement,
        ChronicDiseases,
        certificate,
        certificateField,
        OccupationOutside,
        Workplace,
        jobTitle,
        LanguageLevel,
        Computerskills,
        branch,
        section,
        role,
        image
      )
    );
  };
  return (
    <>
      <div className="parentPrimary ">
        <div className="parentHeadPrimary items-center">
          <div className="flex gap-2 items-center">
            <div>
              <button onClick={() => navigate(-1)} className="btnViolet">
                Back
              </button>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div>
              <p className="text-blue-500 font-bold">
                <FaUserAlt />
              </p>
            </div>
            <p className="font-bold">User</p>
          </div>
        </div>
        <form action=" mt-2">
          <div className="parentInputGrid">
            <div>
              <label htmlFor="en" className="ml-1">
                English Name *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaCircleUser />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="en"
                  type="text"
                  className="inputUser"
                  // value={data.fullname}
                  // onChange={handelInputChange}
                  placeholder="English Name"
                  name="EnName"
                />
              </div>
            </div>{" "}
            <div>
              <label htmlFor="ku" className="ml-1">
                Kurdish Name *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaCircleUser />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="ku"
                  type="text"
                  className="inputUser"
                  // value={data.fullname}
                  // onChange={handelInputChange}
                  placeholder="Kurdish Name"
                  name="KuName"
                />
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="birth" className="ml-1">
                Birth Date *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaBirthdayCake />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="birth"
                  type="date"
                  className="inputUser"
                  // value={data.fullname}
                  // onChange={handelInputChange}
                  placeholder="Kurdish Name"
                  name="birthDate"
                />
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="gender" className="ml-1">
                Gender *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <MdOutlineTransgender />
                  </span>
                </div>
                <select
                  name="gender"
                  onChange={handleInputChange}
                  className="inputUser"
                  id="gender"
                >
                  <option value="">Select Gender ...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="phone1" className="ml-1">
                Phone Number *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaPhone />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="phone1"
                  type="tel"
                  className="inputUser"
                  placeholder="Phone Number"
                  name="phone"
                />
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="phone1" className="ml-1">
                Secound Phone *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaPhone />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="phone1"
                  type="tel"
                  className="inputUser"
                  // value={data.fullname}
                  // onChange={handelInputChange}
                  placeholder="Secound Phone "
                  name="secPhone"
                />
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="pnpc" className="ml-1">
                phone number person close *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaPhone />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="pnpc"
                  type="tel"
                  className="inputUser"
                  // value={data.fullname}
                  // onChange={handelInputChange}
                  placeholder="The phone number person close"
                  name="pnpc"
                />
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="email" className="ml-1">
                Email *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <MdOutlineEmail />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  id="email"
                  type="email"
                  className="inputUser"
                  // value={data.fullname}
                  // onChange={handelInputChange}
                  placeholder="Email"
                  name="email"
                />
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="married" className="ml-1">
                Married *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <BiSolidParty />{" "}
                  </span>
                </div>
                <select
                  onChange={handleInputChange}
                  name="married"
                  className="inputUser"
                  id="married"
                >
                  <option value="">Select Married ...</option>
                  <option value="Married"> Married</option>
                  <option value="Single">Single</option>
                </select>
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="Number Of Childern" className="ml-1">
                Number Of Childern *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaChild />
                  </span>
                </div>
                <select
                  onChange={handleInputChange}
                  name="NumberOfChildern"
                  className="inputUser"
                  id="Number Of Childern"
                >
                  <option value="">Number Of Childern ...</option>
                  <option value="1"> 1</option>
                  <option value="2">2</option>
                  <option value="3">3 </option>
                  <option value="4">4</option>
                  <option value="5"> 5</option>
                  <option value="6">6</option>
                  <option value="7"> 7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>{" "}
            </div>{" "}
            <div>
              <label htmlFor="bloodGorup" className="ml-1">
                Blood Gorup *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <MdBloodtype />{" "}
                  </span>
                </div>
                <select
                  onChange={handleInputChange}
                  name="bloodGorup"
                  className="inputUser"
                  id="bloodGorup"
                >
                  <option value="">Blood Gorup ...</option>
                  <option value="AB+"> AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="A+">A+ </option>
                  <option value="A-">A-</option>
                  <option value="B+"> B+</option>
                  <option value="B-">B-</option>
                  <option value="O+"> O+</option>
                  <option value="O-">O-</option>
                </select>
              </div>
            </div>{" "}
            <div>
              <label htmlFor="DOC" className="ml-1">
                Date Of Commencement *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <BsCalendarDateFill />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  type="date"
                  className="inputUser"
                  id="DOC"
                  placeholder="Date"
                  name="DateOfCommencement"
                />
              </div>
            </div>{" "}
            <div>
              <label htmlFor="CD" className="ml-1">
                Chronic diseases *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <MdOutlineSick />
                  </span>
                </div>
                <select
                  className="inputUser"
                  onChange={handleInputChange}
                  id="CD"
                  name="ChronicDiseases"
                >
                  <option value="">Select Chronic Diseases ...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>{" "}
            <div>
              <label htmlFor="certificate" className="ml-1">
                Certificate *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <TbCertificate />
                  </span>
                </div>
                <select
                  onChange={handleInputChange}
                  className="inputUser"
                  id="certificate"
                  name="certificate"
                >
                  <option value="">Select Certificate ...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>{" "}
            <div>
              <label htmlFor="Certificate field" className="ml-1">
                Certificate field *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <TbCertificate />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="Certificate field"
                  name="certificateField"
                  className="inputUser"
                  placeholder="Certificate Field"
                />
              </div>
            </div>{" "}
            <div>
              <label
                htmlFor="Occupation outside
"
                className="ml-1"
              >
                Occupation outside *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <MdSensorOccupied />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="Occupation outside"
                  name="OccupationOutside"
                  className="inputUser"
                  placeholder="Occupation outside"
                />
              </div>
            </div>{" "}
            <div>
              <label htmlFor="Workplace" className="ml-1">
                Work Place *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaLocationCrosshairs />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="Workplace"
                  name="Workplace"
                  className="inputUser"
                  placeholder="Work Place "
                />
              </div>
            </div>{" "}
            <div>
              <label htmlFor="job Title" className="ml-1">
                Job Tiltle *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaLocationArrow />
                  </span>
                </div>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="job Title"
                  name="jobTitle"
                  className="inputUser"
                  placeholder="Job Title"
                />
              </div>
            </div>{" "}
            <div>
              <label htmlFor="Languagelevel" className="ml-1">
                Language Level*
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <IoLanguage />
                  </span>
                </div>
                <select
                  onChange={handleInputChange}
                  type="text"
                  id="Languagelevel"
                  name="LanguageLevel"
                  className="inputUser"
                  placeholder="Language Level"
                >
                  <option value="">Select Level Languge...</option>
                  <option value="Bad">Bad</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Perfect">Perfect</option>
                </select>
              </div>
            </div>{" "}
            <div>
              <label htmlFor="Computerskills" className="ml-1">
                Computer skills *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <GrPersonalComputer />
                  </span>
                </div>
                <select
                  onChange={handleInputChange}
                  type="text"
                  id="Computerskills"
                  name="Computerskills"
                  className="inputUser"
                  placeholder="Computer Skills"
                >
                  <option value="">Select Computer Skills...</option>
                  <option value="Bad">Bad</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Perfect">Perfect</option>
                </select>
              </div>
            </div>{" "}
            {brnach == 8 || brnach==1 ? (
              <div>
              <label htmlFor="branch" className="ml-1">
                Branch *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaCodeBranch />
                  </span>
                </div>
                <select
                  type="text"
                  id="branch"
                  name="branch"
                  className="inputUser"
                  onChange={handleInputChange}
                >
                  <option value="">Select Branch...</option>
                  {branchs.map((branch, index) => {
                    return (
                      <option key={index} value={branch.id}>
                        {branch.bName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            ) : (
              ""
            )}
            <div>
              <label htmlFor="Section" className="ml-1">
                Section *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <GiRadarCrossSection />
                  </span>
                </div>
                <select
                  type="text"
                  id="Section"
                  name="section"
                  className="inputUser"
                  onChange={handleInputChange}
                >
                  <option value="">Select Section...</option>
                  {sections.map((section, index) => {
                    return (
                      <option value={section.id} key={index}>
                        {section.sName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>{" "}
            <div>
              <label htmlFor="role" className="ml-1">
                Role *
              </label>
              <div className="parentInputEditUser">
                <div className="flex -mr-px">
                  <span className="iconInput">
                    <FaUserShield />
                  </span>
                </div>
                <select
                  type="text"
                  id="role"
                  name="role"
                  className="inputUser"
                  onChange={handleInputChange}
                  // placeholder="Computer Skills"
                >
                  <option value="">Select Role ...</option>
                  {roles.map((role, index) => {
                    return (
                      <option key={index} value={role.id}>
                        {role.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>{" "}
          </div>
          <div className="w-full  ">
            <div
              className=" m-auto  w-full"
              // key={index}
            >
              <label
                id="large_size"
                className="p-4 flex flex-col items-center gap-2  bg-blue-50 text-blue-500 rounded-lg hover:bg-blue-100 cursor-pointer"
              >
                <p>
                  <BsFillCloudArrowUpFill className="w-6 h-6" />
                </p>
                <span></span>
                <input
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  name={`image`}
                  id="large_size"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
          </div>
          <div className="w-full flex justify-end mt-2">
            <button className="btnBlue tracking-wide" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="pb-20"></div>
    </>
  );
};

export default AddUser;
